<template>
  <div
    :class="`alert alert-`+alertType+` alert-dismissible fade show`"
    role="alert"
  >
    {{ alertMessage }}
    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["showAlert"])
  },
  computed: {
    alertMessage() {
      return this.$store.getters.alert.message;
    },
    alertType() {
      return this.$store.getters.alert.type;
    }
  }
};
</script>