<template>
  <div>
    <!-- Header -->
    <div class="row">
      <div class="col-10">
        <h4 class="mt-2">Prescribe Medicines</h4>
      </div>
      <div class="col-2">
        <button
          @click="$emit('close')"
          class="btn btn-sm bg-info-light btn-block"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
    </div>

    <!-- Search medicine -->
    <div class="row">
      <div class="col-12">
        <div class="search-box mt-2 mb-3">
          <div class="form-group search-info mb-2">
            <span class="form-text mb-1">Ex: Glipizide, Lipitor</span>
            <vue-select
              class="form-control form-control-xs"
              v-model="searchMedicine"
              :options="medicineOptions"
              searchable
              clear-on-close
              close-on-select
              clear-on-select
              :loading="loading"
              @search:input="startSearching"
              search-placeholder="Search Medicines"
            ></vue-select>
          </div>
          <button
            @click="addMedicineToList"
            class="btn btn-primary btn-sm btn-block mt-0"
          >
            <i class="fas fa-plus"></i> <span>Add</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Configure medicine -->
    <div class="row mt-2">
      <div class="col-12">
        <div class="accordion" id="medicine-pop-accordion">
          <div class="card" v-for="(medicine, i) in selectedMedicines" :key="i">
            <div
              class="card-header"
              :id="`medicine-pop-accordion-${i}`"
              data-toggle="collapse"
              :data-target="`#medicine-pop-collapse-${i}`"
              aria-expanded="true"
              :aria-controls="`medicine-pop-collapse-${i}`"
            >
              <div class="row">
                <div class="col-9">
                  {{
                    medicine.medicine_name
                      ? medicine.medicine_name
                      : medicine.name
                  }}
                </div>
                <div class="col-3">
                  <button
                    @click="removeMedicineFromList(medicine)"
                    class="btn btn-sm btn-light btn-block"
                    title="Remove Medicine"
                  >
                    <i class="fa fa-trash m-0"></i>
                  </button>
                </div>
              </div>
            </div>

            <div
              :id="`medicine-pop-collapse-${i}`"
              class="collapse"
              :aria-labelledby="`medicine-pop-accordion-${i}`"
              data-parent="#medicine-pop-accordion"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group text-left">
                      <label><strong>Quantity</strong></label>
                      <input
                        type="number"
                        class="form-control form-control-xs"
                        v-model="medicine.quantity"
                        min="1"
                        max="5000"
                        step="any"
                        @change="
                          quantityCorrection(
                            selectedMedicines,
                            medicine.quantity,
                            i
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group text-left">
                      <label><strong>Unit</strong></label>
                      <select
                        class="form-control select form-control-xs"
                        tabindex="-1"
                        aria-hidden="true"
                        v-model="medicine.unit_id"
                        required
                      >
                        <option
                          v-for="(unit, i) in units"
                          :key="i"
                          :value="unit.StandardDispenseUnitTypeID"
                        >
                          {{ unit.Plural }} ({{ unit.Abbreviation }})
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group text-left">
                      <label><strong>Refills Requested</strong></label>
                      <input
                        type="number"
                        class="form-control form-control-xs"
                        v-model="medicine.refills"
                        min="0"
                        max="99"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group text-left">
                      <label><strong>Days Supply</strong></label>
                      <input
                        type="number"
                        class="form-control form-control-xs"
                        v-model="medicine.supply_days"
                        min="1"
                        max="999"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group text-left">
                      <label><strong>Description</strong></label>
                      <textarea
                        class="form-control form-control-xs"
                        v-model="medicine.directions"
                        placeholder="Directions of use"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group text-left">
                      <label><strong>Pharmacy Notes</strong></label>
                      <textarea
                        class="form-control form-control-xs"
                        v-model="medicine.pharmacy_note"
                        placeholder="Notes to Pharmacy"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save  -->
    <div class="row">
      <div class="col-12">
        <button
          @click="saveMedicines"
          class="btn bg-success-light mr-2 btn-block"
          v-show="selectedMedicines.length > 0"
        >
          <i class="fas fa-check"></i> Save Prescription
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import mixin from "../../mixins/common";

export default {
  props: {
    prescriptionDetails: Object,
  },
  mixins: [mixin],
  data() {
    const meds =
      this.prescriptionDetails && this.prescriptionDetails.medicines
        ? this.prescriptionDetails.medicines
        : [];

    return {
      searchMedicine: "",
      medicineOptions: [],
      selectedMedicines: meds,
      loading: false,
      units: [],
    };
  },
  async mounted() {
    const r = await this.getDispenseUnits();
    this.units = r
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    saveMedicines() {
      this.$emit("submit", JSON.stringify(this.selectedMedicines));
    },

    selectMedicines() {
      if (this.selectedMedicines.length === 0) {
        this.showAlert({
          message: "Please select medicines for prescription",
          type: "danger",
          time: 4000,
        });
        return;
      }
      this.$emit("select", JSON.stringify(this.selectedMedicines));
    },

    removeMedicineFromList(medicine) {
      this.selectedMedicines = this.selectedMedicines.filter(
        (a) => a.name != medicine.name
      );
    },

    addMedicineToList() {
      if (this.searchMedicine === "") return;

      const med = this.masterMedicineOptions.find(
        (medicine) => medicine.name === this.searchMedicine
      );

      if (med)
        this.selectedMedicines.push({
          medicine_name: med.name,
          ...med,
          quantity: 0,
          refills: 0,
          directions: "",
        });

      this.searchMedicine = {};
      this.medicineOptions = [];
      this.masterMedicineOptions = [];
    },

    async startSearching(input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;
          const res = await this.searchMedicineByName(input.target.value);

          this.masterMedicineOptions = res;
          if (this.masterMedicineOptions.length > 0) {
            this.medicineOptions = this.masterMedicineOptions.map(
              (a) => a.name
            );
          }
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.showAlert({
          message: e,
          type: "danger",
          time: 4000,
        });
      }
    },

    quantityCorrection(selectedMedicines, quantity, medIndex) {
      let selectedMedicine = selectedMedicines[medIndex];
      // If all zeros in decimal, change the quantity to whole number
      if (quantity % 1 === 0) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity = Math.round(quantity);
      }

      // Populate zero in first place if input is .123
      if (String(quantity).startsWith("0.")) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity = quantity;
      }

      if (String(quantity).length > 10 && String(quantity).indexOf(".") >= 0) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity =
          String(quantity).substr(0, String(quantity).indexOf(".")) +
          String(quantity).substr(String(quantity).indexOf("."), 6);
      }

      // if (quantity > 5000) selectedMedicine.quantity = 5000;
    },
  },
};
</script>

<style scoped>
.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
.cont-btn {
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: 90%;
}

.meds-list {
  position: relative;
  height: 430px;
}

.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 92%;
  width: 92%;
}

.search-info .form-control {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .search-info .form-control {
    width: 110%;
  }
}
</style>
