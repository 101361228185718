<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Payment Settings</h4>
          </div>
          <div class="card-body">
            <!-- Profile Settings Form -->
            <form @submit.prevent="saveProfileDetails">
              <div class="row form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label>PayPal Email Address</label>
                    <input
                      type="email"
                      class="form-control form-control-xs mb-3"
                      v-model="paypalEmail"
                      required
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Prescription Fees (In USD)</label>
                    <input
                      type="number"
                      min="0"
                      class="form-control form-control-xs"
                      v-model="prescriptionFees"
                      required
                    />
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group">
                    <label>Consultation Fees (In USD)</label>
                    <input
                      type="number"
                      min="0"
                      class="form-control form-control-xs"
                      v-model="appointmentFees"
                      required
                    />
                  </div>
                </div> -->
              </div>
              <hr />
              <div class="submit-section text-center">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary submit-btn btn-block"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      prescriptionFees: "",
      appointmentFees: "",
      paypalEmail: "",
    };
  },
  async mounted() {
    this.showLoader(true);
    let data = JSON.parse(JSON.stringify(this.$store.getters.user));
    this.prescriptionFees = data.prescription_fee;
    this.appointmentFees = data.appointment_fee;
    this.paypalEmail = data.paypal_email;
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader", "showAlert"]),

    async saveProfileDetails() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          professional: {
            prescription_fee: this.prescriptionFees,
            appointment_fee: this.appointmentFees,
            paypal_email: this.paypalEmail,
          },
        });

        Swal.fire(
          "Payment Settings Updated",
          "Payment information updated successfully",
          "success"
        );

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>