<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb16></breadcrumb16>
    <!-- Page Content -->
    <div class="content">
      <div class="container">

        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-body">
                <div class="booking-doc-info">
                  <router-link
                    to="/provider/profile"
                    class="booking-doc-img"
                  >
                    <img
                      src="../../assets/img/doctors/doctor-thumb-02.jpg"
                      alt="User Image"
                    >
                  </router-link>
                  <div class="booking-info">
                    <h4>
                      <router-link to="/provider/profile">Dr. Darren Elder</router-link>
                    </h4>
                    <div class="rating mapgridrating1">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star"></i>
                      <span class="d-inline-block average-rating">35</span>
                    </div>
                    <p class="text-muted mb-0"><i class="fas fa-map-marker-alt"></i> Newyork, USA</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-4 col-md-6">
                <h4 class="mb-1">11 November 2019</h4>
                <p class="text-muted">Monday</p>
              </div>
              <div class="col-12 col-sm-8 col-md-6 text-sm-right">
                <div class="bookingrange btn btn-white btn-sm mb-3">
                  <i class="far fa-calendar-alt mr-2"></i>
                  <span></span>
                  <i class="fas fa-chevron-down ml-2"></i>
                </div>
              </div>
            </div>
            <!-- Schedule Widget -->
            <div class="card booking-schedule schedule-widget">

              <!-- Schedule Header -->
              <div class="schedule-header">
                <div class="row">
                  <div class="col-md-12">

                    <!-- Day Slot -->
                    <div class="day-slot">
                      <ul>
                        <li class="left-arrow">
                          <a href="">
                            <i class="fa fa-chevron-left"></i>
                          </a>
                        </li>
                        <li>
                          <span>Mon</span>
                          <span class="slot-date">11 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li>
                          <span>Tue</span>
                          <span class="slot-date">12 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li>
                          <span>Wed</span>
                          <span class="slot-date">13 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li>
                          <span>Thu</span>
                          <span class="slot-date">14 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li>
                          <span>Fri</span>
                          <span class="slot-date">15 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li>
                          <span>Sat</span>
                          <span class="slot-date">16 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li>
                          <span>Sun</span>
                          <span class="slot-date">17 Nov <small class="slot-year">2019</small></span>
                        </li>
                        <li class="right-arrow">
                          <a href="">
                            <i class="fa fa-chevron-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /Day Slot -->

                  </div>
                </div>
              </div>
              <!-- /Schedule Header -->

              <!-- Schedule Content -->
              <div class="schedule-cont">
                <div class="row">
                  <div class="col-md-12">

                    <!-- Time Slot -->
                    <div class="time-slot">
                      <ul class="clearfix">
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing selected"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>9:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>10:00</span> <span>AM</span>
                          </a>
                          <a
                            class="timing"
                            href="#"
                          >
                            <span>11:00</span> <span>AM</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- /Time Slot -->

                  </div>
                </div>
              </div>
              <!-- /Schedule Content -->

            </div>
            <!-- /Schedule Widget -->

            <!-- Submit Section -->
            <div class="submit-section proceed-btn text-right">
              <router-link
                to="/patient/confirmation"
                class="btn btn-primary submit-btn"
              >Proceed to Pay</router-link>
            </div>
            <!-- /Submit Section -->

          </div>
        </div>
      </div>

    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
<script>
export default {
  mounted() {
    if ($(".select").length > 0) {
      $(".select").select2({
        minimumResultsForSearch: -1,
        width: "100%"
      });
    }
    // Date Range Picker
    if ($(".bookingrange").length > 0) {
      var start = moment().subtract(6, "days");
      var end = moment();

      function booking_range(start, end) {
        $(".bookingrange span").html(
          start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
        );
      }

      $(".bookingrange").daterangepicker(
        {
          startDate: start,
          endDate: end,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ]
          }
        },
        booking_range
      );

      booking_range(start, end);
    }
  }
};
</script>