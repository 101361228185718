<template>
  <div class="account-page main-wrapper-adjust-profile">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="mb-4">Payment Settings</h2>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/provider/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-3" class="active-done"
                            >3</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-4" class="active-done"
                            >4</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-5" class="active-done"
                            >5</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active-done">6</a>
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">7</a>
                        </li>
                        <li><a href="javascript:void();">8</a></li>
                        <li><a href="javascript:void();">9</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="profile-pic-col">
                        <div class="form-group text-left">
                          <label>PayPal Email</label>
                          <div class="row">
                            <div class="col-12">
                              <input
                                type="email"
                                name="payment-email"
                                class="form-control"
                                v-model="paypalEmail"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <p>
                          You can modify the PayPal email in the payment
                          settings later.
                        </p>
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <button
                              type="submit"
                              class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              "
                            >
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/provider/step-6">
                              <a
                                href="javascript:void()"
                                class="btn btn-light btn-block"
                              >
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2024. StepAhead Health Inc. </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
        <!-- /Page Content -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      paypalEmail: "",
    };
  },

  async mounted() {
    this.showLoader(true);
    this.paypalEmail = this.$store.getters.user.paypal_email;
    this.showLoader(false);
  },

  methods: {
    ...mapActions(["saveUserDetails", "showLoader", "showAlert"]),

    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          professional: {
            paypal_email: this.paypalEmail,
          },
          current_registration_step: 8,
        });

        this.$router.push("/provider/step-8");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        this.showAlert({
          message: e.response.data.message,
          type: "danger",
          time: 4000,
        });
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
