<template>
  <div class="main-wrapper">
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4>Deactivate Account</h4>
              </div>
              <div class="card-body">
                <h5>We are sad to see you go!</h5>
                <h6>
                  Before you proceed, it is necessary for you to understand,
                  what will happen to your account and data.
                </h6>
                <hr />
                <ol style="font-size: 10px" class="m-2 p-0">
                  <li class="mb-2">
                    Your account will be permanently deactivated. You cannot
                    login or sign up with the same phone & email again.
                  </li>
                  <li class="mb-2">
                    Sensitive data such as payment information, passwords, login
                    devices will be deleted permanently. We do not hold any
                    sensitive information with us.
                  </li>
                  <li class="mb-2">
                    Your personal data such as name, address, other demographics
                    such as height, weight, birth date, phone number, email
                    address and health information will be retained with
                    StepAhead Health for records according to HIPAA compliance.
                  </li>
                  <li class="mb-2">
                    Your medical history, prescription and refill details will
                    be preserved with StepAhead Health to maintain the patient's
                    medical history for records and safety purpose.
                  </li>
                  <li class="mb-2">
                    Your prescribers history, approved prescriptions and refills
                    will be preserved with StepAhead Health for records and
                    safety purpose.
                  </li>
                </ol>
                <hr />
                <form @submit.prevent="deactivateAccount">
                  <p class="font-size: 10px">
                    Please enter the password below to proceed:
                  </p>
                  <div class="form-group text-left">
                    <input
                      type="password"
                      name="password"
                      v-model="password"
                      class="form-control form-control-sm"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn bg-danger-light btn-sm btn-block"
                  >
                    Deactivate Account
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      password: "",
    };
  },
  methods: {
    ...mapActions(["deactivatePatientAccount", "showLoader"]),
    async deactivateAccount() {
      try {
        this.showLoader(true);

        await this.deactivatePatientAccount({
          id: this.$store.getters.user.id,
          password: this.password,
        });

        Swal.fire(
          "Account Deactivated",
          "Your account has been deactivated",
          "success"
        ).then(async () => {
          await this.$store.dispatch("logOut", {
            id: this.$store.getters.user.id,
            deviceToken: this.$store.getters.device
              ? this.$store.getters.device.deviceToken
              : "",
          });

          this.$router.push("/login");
        });

        this.password = "";
        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.password = "";
        this.showLoader(false);
      }
    },
  },
};
</script>