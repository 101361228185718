<template>
  <!-- Breadcrumb -->
  <div class="breadcrumb-bar">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-12 col-12">
          <h2 class="breadcrumb-title">Change Password</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->
</template>