<template>
  <!-- Availabe Features -->
  <section class="section section-features">
    <div class="container">
      <div class="row">
        <div class="col-md-5 features-img responsive">
          <img
            src="../assets/img/home-doctor.png"
            class="img-fluid"
            alt="Feature"
          >
        </div>
        <div class="col-md-7 responsive">
          <div class="section-header">
            <h2 class="mt-5">Are you a provider?</h2>
            <p>We would be happy to serve you. Sign up for your free provider account.
              The health community needs your expertise and practise.</p>
          </div>
          <!-- <div
            class="features-slider slider"
            style="font-size: 10px"
          >
            <div class="feature-item text-center">
              <img
                src="../assets/img/docs.jpg"
                class="img-fluid"
                alt="Feature"
              >
              <p>Simple Documentation</p>
            </div>

            <div class="feature-item text-center">
              <img
                src="../assets/img/registration.jpg"
                class="img-fluid"
                alt="Feature"
              >
              <p>Hazzle Free Registration</p>
            </div>

            <div class="feature-item text-center">
              <img
                src="../assets/img/appointments.jpg"
                class="img-fluid"
                alt="Feature"
              >
              <p>Appointment Management</p>
            </div>

            <div class="feature-item text-center">
              <img
                src="../assets/img/patient-img.jpg"
                class="img-fluid"
                alt="Feature"
              >
              <p>Patient Management</p>
            </div>

            <div class="feature-item text-center">
              <img
                src="../assets/img/finance.jpg"
                class="img-fluid"
                alt="Feature"
              >
              <p>Private Financial Dashboard</p>
            </div>

            <div class="feature-item text-center">
              <img
                src="../assets/img/secure.jpg"
                class="img-fluid"
                alt="Feature"
              >
              <p>Secure Communication</p>
            </div>
          </div> -->

          <div class="responsive">
            <router-link
              to='/provider/register'
              @click="moveUp"
            >
              <button class="btn btn-info">Create Provider's Account</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Availabe Features -->

</template>

<script>
export default {
  mounted() {
    if ($(".features-slider").length > 0) {
      $(".features-slider").slick({
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        speed: 500,
        variableWidth: true,
        arrows: false,
        autoplay: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    }
  },
  methods: {
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 576px) {
  .responsive {
    text-align: center;
  }
}
</style>