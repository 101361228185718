<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <!-- Main Banner -->
    <home-banner @goTo="goTo"></home-banner>

    <!-- Process section -->
    <process id="process"></process>

    <!-- Patient services -->
    <looking id="patient"></looking>

    <!-- Provider Services -->
    <features id="provider"></features>

    <!-- Select Sections-->
    <section class="patient-select">
      <div class="container">
        <div class="text-center mt-2 mb-5">
          <h1 style="color: white">Let's Get Started!</h1>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="select-box">
              <div class="d-flex align-items-center">
                <div class="image-holder">
                  <span>
                    <img src="../assets/img/icons/doct-01.png" alt="" />
                  </span>
                </div>
                <div>
                  <h2>ARE YOU A PROVIDER?</h2>
                  <p>
                    The service allows you to get maximum visibility online and to manage
                    appointments and contacts coming from the site, in a simple and fast
                    way.
                  </p>
                  <router-link to="/provider/register" class="book-now" @click="moveUp">Open account</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-box theme">
              <div class="d-flex align-items-center">
                <div class="image-holder">
                  <span>
                    <img src="../assets/img/icons/doct-02.png" alt="" />
                  </span>
                </div>
                <div>
                  <h2>ARE YOU A PATIENT?</h2>
                  <p>
                    Choosing a specialist has never been so fast! You can filter search
                    results by location and medical specialization, and book medical
                    examination online.
                  </p>
                  <router-link to="/patient/register" class="book-now" @click="moveUp">Sign up for free</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Select Sections-->

    <!-- Faq section -->
    <FaqHome></FaqHome>
    <!-- End of Faq section -->

    <layout-footer></layout-footer>
  </div>
</template>

<script>
export default {
  mounted () {
    if (this.$store.getters.user) {
      switch (this.$store.getters.user.role) {
        case "patient":
          if (!this.$store.getters.user.isRegistrationComplete) {
            this.$router.push(
              `/patient/step-${this.$store.getters.user.current_registration_step}`
            );
          } else this.$router.push("/patient/index");
          break;
      }
    }
  },
  methods: {
    goTo (refName) {
      var element = document.getElementById(refName);
      if (!element) return;
      var top = element.offsetTop;
      window.scrollTo(0, top - 50);
    },
    moveUp () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
