<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <form @submit.prevent="sendWithdrawRequest">
      <div class="card">
        <div class="card-header">
          <h4>Withdrawal Request</h4>
        </div>
        <div class="card-body p-3">
          <div class="row mt-2">
            <div class="col-12">
              <h5>Enter Withdrawal Amount: (Min. $10 USD)</h5>
              <h6>
                Available Balance: <strong>${{ availableAmount }} USD</strong>
              </h6>
            </div>
            <div class="col-12">
              <input type="number" class="form-control" placeholder="10" :min="10" :max="availableAmount"
                v-model="withdrawalAmount" required />
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12">
              <h5>PayPal Email:</h5>
            </div>
            <div class="col-12" style="font-size: 13px">
              {{ $store.getters.user.paypal_email }}
            </div>
          </div>
          <hr />
          <p>
            <small>
              Please verify your PayPal email. In case of correction or change,
              please go to Account Settings -> Payment Settings. All the
              transfers are executed based on the information provided by the
              account holder, hence no refunds will be provided for incorrect
              completed withdrawal requests.
            </small>
          </p>
          <p>
            <small>
              Your withdrawal request will be validated and verified by our
              team. The payment will be released within 24 hours from the
              withdrawal request time. You can cancel the withdrawal request any
              time before 2 hours after the request is sent.
            </small>
          </p>
          <p>
            <small>
              For any queries related to finances and withdrawals, please
              contact our support team or create a support ticket in the help
              desk.</small>
          </p>
        </div>
        <button type="submit" class="btn bg-success-light m-3">
          <i class="fas fa-check"></i> Send Withdrawal Request
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../../constants/config";
import { mapActions } from "vuex";
export default {
  props: {
    availableAmount: Number,
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
  },
  data () {
    return {
      withdrawalAmount: 10,
    };
  },
  methods: {
    ...mapActions(["showLoader"]),
    async sendWithdrawRequest () {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSendWithdrawRequest}${this.user.id}`,
          {
            withdrawalAmount: this.withdrawalAmount,
            paypalEmail: this.user.paypal_email,
          }
        );

        this.showLoader(false);
        this.withdrawalAmount = 10;
        Swal.fire(
          "Request Sent!",
          "Withdrawal request has been sent. Our team will verify your request and release the payout within 24 hours.",
          "success"
        ).then(async () => {
          this.$router.push("/provider/finances");
        });
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });

        this.showLoader(false);
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}

.form-control-xs-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>