<template>
  <div class="main-wrapper list-holder">
    <div class="profile-sidebar" v-if="user">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <a href="javascript:void();" class="booking-doc-img">
            <img :src="profileImage" :alt="user.first_name" />
          </a>
          <div class="profile-det-info">
            <h3>
              {{ formatLongString(user.first_name) }}
              {{ formatLongString(user.last_name) }}
            </h3>
            <div class="patient-details">
              <!-- <h5>License: {{ user.license }}</h5> -->
              <h5 class="mb-0">
                {{ user.description }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-widget">
        <nav class="dashboard-menu">
          <ul>
            <li class="active">
              <a href="javascript:void();">
                <i class="fas fa-key"></i>
                <span>Account ID:
                  <strong>{{ user.id }}-{{ user.dsp_user_id }}</strong></span>
              </a>
            </li>
            <li>
              <router-link to="/provider/profile">
                <i class="fas fa-user-cog"></i>
                <span>Profile Settings</span>
              </router-link>
            </li>
            <li>
              <router-link to="/provider/license-settings">
                <i class="fas fa-file-prescription"></i>
                <span>License & States</span>
              </router-link>
            </li>
            <li>
              <router-link to="/provider/payment-settings">
                <i class="fas fa-receipt"></i>
                <span>Payment Settings</span>
              </router-link>
            </li>
            <li>
              <router-link to="/provider/change-password">
                <i class="fas fa-lock"></i>
                <span>Change Password</span>
              </router-link>
            </li>
            <li @click="openReferModal">
              <a href="javascript:void();">
                <i class="fas fa-user-plus"></i>
                <span>Refer a Patient</span>
              </a>
            </li>
            <li>
              <router-link to="/provider/deactivate">
                <i class="fas fa-trash text-danger"></i>
                <span class="text-danger">Deactivate Account</span>
              </router-link>
            </li>
            <!-- <li class="notaactive" @click="logout">
              <a href="javascript:void();">
                <i class="fas fa-sign-out-alt"></i>
                <span>Logout</span>
              </a>
            </li> -->
          </ul>
        </nav>
      </div>
    </div>

    <!-- Refer Modal -->
    <div class="modal fade" id="refer-modal" style="display: none">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="card-header">
            <h5>Refer a Patient</h5>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <h4 class="mt-2">
                <small>Referral Code:</small>
                {{ this.$store.getters.user.referral_code }}
              </h4>
              <button @click="copyCode" class="btn btn-sm btn-info w-100">
                Copy Code
              </button>
              <hr />
              <form @submit.prevent="sendEmailInvite">
                <div class="form-group">
                  <h4><small>Invite patient by email address</small></h4>
                  <input type="text" placeholder="Enter Email Address" class="form-control" v-model="referalEmail"
                    required />
                </div>
                <button type="submit" class="btn btn-sm btn-primary w-100">
                  Invite by email
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
const images = require.context("../../assets/img", false, /\.png$|\.jpg$/);
import { BASE_URL, HTTP_API } from "../../constants/config";
import axios from "axios";

export default {
  data () {
    return {
      referalEmail: "",
    };
  },
  methods: {
    ...mapActions(["logOut", "showLoader"]),
    copyCode () {
      $("#refer-modal").modal("hide");
      this.$clipboard(this.$store.getters.user.referral_code)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: this.$store.getters.user.referral_code,
            text: `Code copied!`,
            showConfirmButton: false,
            timer: 2000,
          });
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Failed!",
            text: `Cannot copy code`,
            showConfirmButton: false,
            timer: 2000,
          });
        });
    },

    openReferModal () {
      $("#refer-modal").modal("show");
    },
    async sendEmailInvite () {
      try {
        this.showLoader(true);

        await axios.post(`${BASE_URL}${HTTP_API.providerReferByEmail}`, {
          email: this.referalEmail,
          providerId: this.$store.getters.user.id,
        });

        Swal.fire({
          icon: "success",
          title: "Referral Sent!",
          text: `Invitation has been sent to ${this.referalEmail}`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.referalEmail = "";
        });

        this.showLoader(false);
        $("#refer-modal").modal("hide");
      } catch (e) {
        $("#refer-modal").modal("hide");
        Swal.fire("Oops!", e.response.data.message, "error");
        this.referalEmail = "";
        this.showLoader(false);
      }
    },

    formatLongString (str) {
      let s = String(str);
      return s.length > 10 ? s.substring(0, 10) + "..." : s;
    },

    async logout () {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    profileImage () {
      return this.user.profile_image_url
        ? this.user.profile_image_url
        : images("./blank.png").default;
    },
    user () {
      return this.$store.getters.user;
    },
    dob () {
      const dob = this.$store.getters.user.dob.split("/");
      let month = "";
      if (dob[1] == 1) month = "Jan";
      if (dob[1] == 2) month = "Feb";
      if (dob[1] == 3) month = "Mar";
      if (dob[1] == 4) month = "Apr";
      if (dob[1] == 5) month = "May";
      if (dob[1] == 6) month = "Jun";
      if (dob[1] == 7) month = "Jul";
      if (dob[1] == 8) month = "Aug";
      if (dob[1] == 9) month = "Sep";
      if (dob[1] == 10) month = "Oct";
      if (dob[1] == 11) month = "Nov";
      if (dob[1] == 12) month = "Dec";

      const age = new Date().getFullYear() - parseInt(dob[0]);
      // return `${dob[0]} ${month} ${dob[2]}, ${age - 1} Years`;
      return `${dob[0]} ${month} ${dob[2]}`;
    },
    currentPath () {
      return this.$route.name;
    },
  },
};
</script>
<style>
.list-holder {
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-x: hidden;
}
</style>