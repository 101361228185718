<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0 m-0">
          <div class="banner-wrapper">
            <div class="mb-3 text-center">
              <h4>Patient Observations</h4>
            </div>
            <form @submit.prevent="confirmProceed">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group text-left">
                        <label>Current Medications (Optional)</label>
                        <textarea
                          name="past"
                          class="form-control form-control-xs"
                          rows="3"
                          v-model="currentMedications"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group text-left">
                        <label>Known Allergies (Optional)</label>
                        <textarea
                          name="past"
                          class="form-control form-control-xs"
                          rows="3"
                          v-model="knownAllergies"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group text-left">
                        <label>Reason for Medication (Required)</label>
                        <textarea
                          name="past"
                          class="form-control form-control-xs"
                          rows="3"
                          v-model="reasonForMedication"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-sm btn-info btn-block mb-2">
                Confirm & Proceed <i class="fa fa-arrow-right"></i>
              </button>
              <router-link to="/patient/index">
                <button class="btn btn-sm btn-outline-danger btn-block mb-4">
                  <i class="fa fa-close"></i> Cancel
                </button>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      height: "",
      weight: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      reasonForMedication: "",
      currentMedications: "",
      knownAllergies: "",
    };
  },
  async mounted() {
    this.showLoader(true);

    // Personal details
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.gender = this.$store.getters.user.gender;
    this.dob = this.$store.getters.user.dob;
    this.height = this.$store.getters.user.height;
    this.weight = this.$store.getters.user.weight;

    // Address parsing
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;
    this.state = this.$store.getters.user.state;
    this.city = this.$store.getters.user.city;

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    confirmProceed() {
      this.$emit(
        "select",
        JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          gender: this.gender,
          dob: this.dob,
          email: this.$store.getters.user.email,
          mobileNumber: this.$store.getters.user.primary_phone,
          address: {
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            state: this.state,
            city: this.city,
            zipCode: this.zipCode,
          },
          currentMedications: this.currentMedications,
          knownAllergies: this.knownAllergies,
          reasonForMedication: this.reasonForMedication,
        })
      );
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 400px;
  width: 450px;
}
</style>