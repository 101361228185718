<template>
  <div
    class="main-wrapper main-wrapper-adjust-profile"
    v-if="prescriptionDetails"
  >
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-7">
            <h4 class="text-light">Request ID: {{ prescriptionDetails.id }}</h4>
            <span class="text-light" style="font-size: 10px"
              >Request Date:
              {{
                new Date(prescriptionDetails.created_at).toLocaleDateString()
              }}</span
            >
          </div>
          <div class="col-5">
            <div>
              <button
                class="btn btn-sm btn-info btn-block"
                @click="markChatRead"
                v-if="!isChatOpen"
              >
                <i class="fa fa-comment mr-1"></i> Chat
                <span
                  v-if="unreadMessages > 0"
                  class="badge badge-pill bg-danger"
                >
                  {{ unreadMessages }} New</span
                >
              </button>
              <button
                v-else
                class="btn btn-sm btn-warning btn-block"
                @click="closeChat"
              >
                <i class="fa fa-close"></i> Close Chat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="row" v-if="!isChatOpen">
          <div class="col-md-3">
            <div
              class="accordion"
              id="prescription-accordion"
              style="font-size: 12px"
            >
              <!-- PATIENT INFORMATION -->
              <div class="card mb-0" v-if="prescriptionDetails.patient">
                <div class="card-header" id="patient-info-accordion">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target="#patient-info-collapse"
                      aria-controls="patient-info-collapse"
                    >
                      <i class="fas fa-user mr-1"></i> Patient Information
                    </button>
                  </h2>
                </div>
                <div
                  id="patient-info-collapse"
                  class="collapse"
                  aria-labelledby="patient-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      Name: {{ prescriptionDetails.patient.first_name }}
                      {{ prescriptionDetails.patient.last_name }}
                    </p>
                    <p>
                      Date of birth:
                      {{ prescriptionDetails.patient.dob }}
                      <!-- ({{
                        getAge(prescriptionDetails.patient.dob)
                      }}
                      Years) -->
                    </p>
                    <p>
                      Gender:
                      {{ prescriptionDetails.patient.gender }}
                    </p>
                    <p>
                      Height:
                      {{ String(prescriptionDetails.patient.height) }} IN
                    </p>
                    <p>
                      Weight:
                      {{ String(prescriptionDetails.patient.weight) }} LB
                    </p>
                    <p>
                      Mobile:
                      {{ prescriptionDetails.patient.primary_phone }}
                    </p>
                    <p>
                      Address:
                      {{ prescriptionDetails.patient.address1 }},
                      {{ prescriptionDetails.patient.address2 }},
                      {{ prescriptionDetails.patient.city }},
                      {{ prescriptionDetails.patient.state }} -
                      {{ prescriptionDetails.patient.zip_code }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- PHARMACY INFORMATION -->
              <div class="card mb-0" v-if="prescriptionDetails.pharmacy">
                <div class="card-header" id="pharmacy-info-accordion">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#pharmacy-info-collapse"
                      aria-expanded="false"
                      aria-controls="pharmacy-info-collapse"
                    >
                      <i class="fas fa-hospital mr-1"></i> Pharmacy Details
                    </button>
                  </h2>
                </div>
                <div
                  id="pharmacy-info-collapse"
                  class="collapse"
                  aria-labelledby="pharmacy-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    <p>
                      Store ID:
                      {{ prescriptionDetails.pharmacy.pharmacy_id }}
                    </p>
                    <p>
                      Store Name:
                      {{ prescriptionDetails.pharmacy.pharmacy_name }}
                    </p>
                    <p>Phone: {{ prescriptionDetails.pharmacy.phone }}</p>
                    <p>
                      Address:
                      {{ prescriptionDetails.pharmacy.address1 }},
                      {{ prescriptionDetails.pharmacy.address2 }},
                      {{ prescriptionDetails.pharmacy.city }},
                      {{ prescriptionDetails.pharmacy.state }} -
                      {{ prescriptionDetails.pharmacy.zip_code }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- HEALTH & SYMPTOMS INFORMATION -->
              <div class="card mb-0" v-if="prescriptionDetails.observations">
                <div class="card-header" id="symptoms-info-accordion">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#symptoms-info-collapse"
                      aria-expanded="false"
                      aria-controls="symptoms-info-collapse"
                    >
                      <i class="fas fa-notes-medical mr-1"></i> Description /
                      Symptoms
                    </button>
                  </h2>
                </div>
                <div
                  id="symptoms-info-collapse"
                  class="collapse"
                  aria-labelledby="symptoms-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    {{ prescriptionDetails.observations.symptoms }}
                  </div>
                </div>
              </div>

              <!-- ALLERGIES INFORMATION -->
              <div
                class="card mb-0"
                v-if="prescriptionDetails.observations.allergies"
              >
                <div class="card-header" id="allergies-info-accordion">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#allergies-info-collapse"
                      aria-expanded="false"
                      aria-controls="allergies-info-collapse"
                    >
                      <i class="fas fa-allergies mr-1"></i> Allergies
                    </button>
                  </h2>
                </div>
                <div
                  id="allergies-info-collapse"
                  class="collapse"
                  aria-labelledby="allergies-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    {{ prescriptionDetails.observations.allergies }}
                  </div>
                </div>
              </div>

              <!-- HISTORY INFORMATION -->
              <div
                class="card mb-0"
                v-if="prescriptionDetails.observations.history"
              >
                <div class="card-header" id="history-info-accordion">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#history-info-collapse"
                      aria-expanded="false"
                      aria-controls="history-info-collapse"
                    >
                      <i class="fas fa-history mr-1"></i> Medical History
                    </button>
                  </h2>
                </div>
                <div
                  id="history-info-collapse"
                  class="collapse"
                  aria-labelledby="history-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body">
                    {{ prescriptionDetails.observations.history }}
                  </div>
                </div>
              </div>

              <!-- HEALTH INFORMATION -->
              <div class="card" v-if="prescriptionDetails.dosageInfo">
                <div class="card-header" id="health-info-accordion">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#health-info-collapse"
                      aria-expanded="false"
                      aria-controls="health-info-collapse"
                    >
                      <i class="fas fa-heart mr-1"></i> Health Information
                    </button>
                  </h2>
                </div>
                <div
                  id="health-info-collapse"
                  class="collapse"
                  aria-labelledby="health-info-accordion"
                  data-parent="#prescription-accordion"
                >
                  <div class="card-body" v-if="prescriptionDetails.dosageInfo">
                    <p>
                      Current Medications:
                      {{ prescriptionDetails.dosageInfo.currentMedications }}
                    </p>
                    <p>
                      Known Allergies:
                      {{ prescriptionDetails.dosageInfo.knownAllergies }}
                    </p>
                    <p>
                      Reason for Medication:
                      {{ prescriptionDetails.dosageInfo.reasonForMedication }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- MANAGE MEDICINES & RX CHANGES -->
          <div
            class="col-12"
            v-if="
              medicineDetails.length > 0 &&
                prescriptionDetails.pharmacy &&
                prescriptionDetails.status !== 'Cancelled'
            "
          >
            <!-- Title and Header -->
            <div class="card mt-2" style="font-size: 12px">
              <div class="card-header">
                <h4>Medications</h4>
              </div>
            </div>

            <!-- Medicines -->
            <div style="font-size: 12px">
              <div class="accordion" id="medicine-accordion">
                <div
                  class="card mb-0"
                  v-for="(medicine, i) in medicineDetails"
                  :key="i"
                >
                  <div
                    class="card-header"
                    :id="
                      `medicine-accordion-${medicine.id}-${medicine.dsp_prescription_id}`
                    "
                  >
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-sm btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        :data-target="
                          `#medicine-collapse-${medicine.id}-${medicine.dsp_prescription_id}`
                        "
                        aria-expanded="true"
                        :aria-controls="
                          `medicine-collapse-${medicine.id}-${medicine.dsp_prescription_id}`
                        "
                        @click="isEditingRequired(medicine)"
                      >
                        {{ i + 1 }}.
                        {{
                          !medicine.selected_refill
                            ? medicine.medicine_name
                            : medicine.selected_refill.medicine_name
                        }}

                        <div
                          class="text-center"
                          v-if="medicine.dsp_cancel_status == 'Completed'"
                        >
                          <span
                            class="badge bg-danger-light"
                            title="Prescription Cancelled"
                            >Cancelled</span
                          >
                        </div>
                        <div v-else>
                          <div class="row mt-2">
                            <div
                              class="col-6"
                              v-if="
                                !medicine.directions ||
                                  medicine.directions === ''
                              "
                            >
                              <span
                                :id="`status-fail-${medicine.id}`"
                                class="badge bg-warning-light ml-1"
                                title="Incomplete details"
                                >Please fill the details to proceed</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.is_refill_initiated &&
                                  medicine.dsp_status_id === 13
                              "
                            >
                              <span
                                :id="`status-fail-${medicine.id}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy verified details"
                                >Pharmacy Verified</span
                              >
                            </div>
                            <div
                              class="col-12"
                              v-if="
                                !medicine.is_refill_initiated &&
                                  medicine.dsp_status_id === 13 &&
                                  (!medicine.pharmacy_rxchanges ||
                                    medicine.pharmacy_rxchanges.length === 0)
                              "
                            >
                              <span
                                :id="`status-fail-${medicine.id}`"
                                class="badge bg-warning-light ml-1"
                                title="Waiting for Pharmacy Response"
                                >Waiting for Pharmacy Response</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.is_refill_initiated &&
                                  medicine.selected_refill.status ===
                                    'Completed'
                              "
                            >
                              <span
                                :id="`status-refill-approved-${medicine.id}`"
                                class="badge bg-success-light ml-1"
                                title="Refill Completed"
                                >Refill Completed</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="medicine.rxchange_status === 'Completed'"
                            >
                              <span
                                :id="`status-rxchange-${medicine.id}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy RxChange Completed"
                                >RxChange Completed</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.refill_status === 'Ready'
                              "
                            >
                              <span
                                :id="`status-refill-ready-${medicine.id}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy Refill Ready"
                                >Refill Ready</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.refill_status === 'Denied'
                              "
                            >
                              <span
                                :id="`status-refill-ready-${medicine.id}`"
                                class="badge bg-danger-light ml-1"
                                title="Pharmacy Refill Denied"
                                >Refill Denied</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.is_rxchange_requested &&
                                  medicine.selected_rxchange &&
                                  medicine.selected_rxchange.status ===
                                    'Pending'
                              "
                            >
                              <span
                                :id="`status-fail-${medicine.id}`"
                                class="badge bg-danger-light ml-1"
                                title="Rx Change Requested"
                                >Pharmacy RxChange Requested</span
                              >
                            </div>
                            <div class="col-6" v-if="medicine.pin">
                              <span class="m-2" style="font-size: 11px"
                                >PIN:
                                {{
                                  medicine.pin ? medicine.pin : "Not Generated"
                                }}</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.pharmacy_refills &&
                                  prescriptionDetails.status !== 'Cancelled'
                              "
                            >
                              <span class="m-2" style="font-size: 11px"
                                >Total Refills Count:
                                {{ medicine.pharmacy_refills.length }}</span
                              >
                            </div>
                            <div
                              class="col-6"
                              v-if="
                                medicine.selected_refill &&
                                  prescriptionDetails.status !== 'Cancelled'
                              "
                            >
                              <span class="m-2" style="font-size: 11px"
                                >Refill ID:
                                {{ medicine.selected_refill.refill_id }}</span
                              >
                            </div>
                            <div
                              class="col-12"
                              v-if="
                                medicine.selected_refill &&
                                  prescriptionDetails.status !== 'Cancelled'
                              "
                            >
                              <span class="m-2" style="font-size: 11px"
                                >Written Date:
                                {{
                                  new Date(
                                    medicine.selected_refill.requested_date
                                  ).toLocaleString()
                                }}</span
                              >
                            </div>
                            <div
                              class="col-12"
                              v-if="
                                (prescriptionDetails.status == 'Cancelled' ||
                                  prescriptionDetails.status == 'Accepted') &&
                                  medicine.dsp_cancel_message
                              "
                            >
                              <span class="mt-2" style="font-size: 11px"
                                >Pharmacy Message:
                                <strong>{{
                                  medicine.dsp_cancel_message
                                    ? medicine.dsp_cancel_message
                                    : "Unknown"
                                }}</strong></span
                              >
                            </div>
                            <div
                              class="col-12"
                              v-if="
                                medicine.rxChange &&
                                  medicine.is_rxchange_requested
                              "
                            >
                              <span class="mt-2" style="font-size: 11px"
                                >Pharmacy Message:
                                <span class="text-danger">
                                  {{
                                    getRxChangeMessage(
                                      medicine.rxChange.rxChangeType
                                    )
                                  }}</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <br />
                      </button>
                      <button
                        @click="acknowledgePrescription(prescriptionDetails.id)"
                        v-if="
                          !isEditing &&
                            prescriptionDetails.status === 'Accepted' &&
                            String(medicine.dsp_cancel_message).includes(
                              'Sender ID not on file'
                            )
                        "
                        class="btn btn-sm btn-block bg-info-light"
                      >
                        <i class="fas fa-check mr-2"></i> Acknowledge
                      </button>
                    </h2>
                  </div>

                  <div
                    :id="
                      `medicine-collapse-${medicine.id}-${medicine.dsp_prescription_id}`
                    "
                    class="collapse"
                    :aria-labelledby="
                      `medicine-accordion-${medicine.id}-${medicine.dsp_prescription_id}`
                    "
                    data-parent="#medicine-accordion"
                  >
                    <div class="row">
                      <!-- Rx Change Header Message -->
                      <div
                        class="col-12"
                        v-if="isSpecialRxChange(medicine.selected_rxchange)"
                      >
                        <!-- Out of Stock -->
                        <div
                          class="card mb-3"
                          v-if="isOutOfStock(medicine.selected_rxchange)"
                        >
                          <div class="card-body">
                            <h5 class="text-center">
                              Pharmacy Message: Out of Stock
                            </h5>
                            <div class="text-center">
                              <p>
                                The requested medicine is currently out of stock
                                at the moment. The pharmacy has suggested a
                                replacement for the same.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Old medicine details -->
                      <div class="col-12">
                        <!-- RX Changes -->
                        <div
                          class="card"
                          v-if="
                            !isSpecialRxChange(medicine.selected_rxchange) &&
                              medicine.selected_rxchange &&
                              medicine.is_rxchange_requested &&
                              medicine.selected_rxchange.status === 'Pending'
                          "
                        >
                          <div class="card-body">
                            <!-- Prior Auth Number -->
                            <div
                              v-if="
                                medicine.selected_rxchange.rxchange_type ===
                                  'Prior Authorization'
                              "
                            >
                              <h5>Action Required</h5>
                              <form
                                @submit.prevent="
                                  approveRxChangeRequest(
                                    medicine.selected_rxchange
                                      .rxchange_request_id,
                                    medicine.selected_rxchange
                                      .prescribed_dsp_prescription_id,
                                    medicine.dsp_prescription_id
                                  )
                                "
                              >
                                <div class="form-group text-left">
                                  <label
                                    ><strong
                                      >Prior Authorization Number</strong
                                    ></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    v-model="priorAuthNumber"
                                    maxlength="35"
                                    required
                                  />
                                </div>
                                <div class="row">
                                  <div class="col-12">
                                    <button
                                      type="submit"
                                      class="btn mb-2 bg-success-light btn-block"
                                    >
                                      Approve
                                    </button>
                                  </div>
                                  <div class="col-12">
                                    <a
                                      href="javascript:void()"
                                      @click="
                                        showRejectRxChangeModal(
                                          medicine.selected_rxchange
                                            .rxchange_request_id
                                        )
                                      "
                                      class="btn btn-sm bg-warning-light btn-block"
                                    >
                                      Deny
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>

                            <!-- Script Clarification -->
                            <div
                              v-else-if="
                                medicine.selected_rxchange.rxchange_type ===
                                  'Script Clarification'
                              "
                            >
                              <h5>Verification Required</h5>
                              <p>
                                Pharmacy needs a script clarification. Please
                                verify the medication directions, refills,
                                quantity, days supply and approve the change.
                              </p>
                            </div>

                            <!-- Prescriber Authorization -->
                            <div
                              v-else-if="
                                medicine.selected_rxchange.rxchange_type ===
                                  'Prescriber Authorization'
                              "
                            >
                              <h5>Need Prescriber Authorization</h5>
                              <form
                                @submit.prevent="
                                  approveRxChangeRequest(
                                    medicine.selected_rxchange
                                      .rxchange_request_id,
                                    medicine.selected_rxchange
                                      .prescribed_dsp_prescription_id,
                                    medicine.dsp_prescription_id
                                  )
                                "
                              >
                                <div class="form-group text-left text-danger">
                                  <label
                                    ><strong
                                      >Please enter NPI ID*</strong
                                    ></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control form-control-xs"
                                    v-model="npiVerification"
                                    placeholder="NPI ID"
                                    aria-label="NPI ID"
                                    required
                                  />
                                </div>
                                <div class="row">
                                  <div class="col-12">
                                    <button
                                      type="submit"
                                      class="btn mb-2 bg-success-light btn-block"
                                    >
                                      Approve
                                    </button>
                                  </div>
                                  <div class="col-12">
                                    <a
                                      href="javascript:void()"
                                      @click="
                                        showRejectRxChangeModal(
                                          medicine.selected_rxchange
                                            .rxchange_request_id
                                        )
                                      "
                                      class="btn btn-sm bg-warning-light btn-block"
                                    >
                                      Deny
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>

                            <!-- Show general rx change message -->
                            <div v-else>
                              <h5>RxChange Request Message</h5>
                              <form
                                @submit.prevent="
                                  approveRxChangeRequest(
                                    medicine.selected_rxchange
                                      .rxchange_request_id,
                                    medicine.selected_rxchange
                                      .prescribed_dsp_prescription_id,
                                    medicine.dsp_prescription_id
                                  )
                                "
                              >
                                <p>
                                  {{ medicine.selected_rxchange.rxchange_type }}
                                </p>
                                <div class="row">
                                  <div class="col-6">
                                    <button
                                      @click="
                                        showRejectRxChangeModal(
                                          medicine.selected_rxchange
                                            .rxchange_request_id
                                        )
                                      "
                                      type="button"
                                      class="btn btn-sm bg-warning-light btn-block"
                                    >
                                      Deny
                                    </button>
                                  </div>
                                  <div class="col-6">
                                    <button
                                      type="submit"
                                      class="btn btn-sm bg-success-light btn-block"
                                    >
                                      Approve
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        <!-- Prescription Details -->
                        <div
                          class="card"
                          :style="
                            isOutOfStock(medicine.selected_rxchange)
                              ? `background: #eeeeee;`
                              : ``
                          "
                        >
                          <div class="card-header">
                            <div class="row">
                              <div class="col-8">
                                <h6>
                                  Message ID:
                                  {{
                                    medicine.selected_refill
                                      ? medicine.selected_refill
                                          .dsp_prescription_id
                                      : medicine.dsp_prescription_id
                                  }}
                                </h6>
                                <h6>
                                  Date:
                                  {{
                                    medicine.selected_refill
                                      ? new Date(
                                          medicine.selected_refill.written_date
                                        ).toLocaleString()
                                      : new Date(
                                          medicine.created_at
                                        ).toLocaleString()
                                  }}
                                </h6>
                              </div>
                              <div class="col-4 text-right">
                                <button
                                  class="btn btn-sm bg-danger-light btn-block"
                                  v-if="
                                    medicine.selected_rxchange &&
                                      medicine.selected_rxchange
                                        .rxchange_type === 'Out Of Stock'
                                  "
                                  :disabled="true"
                                >
                                  Expired
                                </button>
                                <button
                                  v-else
                                  class="btn btn-sm bg-info-light btn-block"
                                  @click="
                                    setPrintStatus(
                                      prescriptionDetails.patient.dsp_user_id,
                                      medicine.dsp_prescription_id
                                    )
                                  "
                                  :disabled="
                                    prescriptionDetails.status !== 'Accepted' ||
                                      medicine.dsp_status_id === 13 ||
                                      medicine.is_printed === 1 ||
                                      medicine.dsp_cancel_message
                                  "
                                >
                                  Print
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body"
                            v-if="
                              !medicine.selected_refill &&
                                !medicine.pharmacy_refills
                            "
                          >
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Quantity*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.quantity"
                                    min="1"
                                    max="5000"
                                    step="any"
                                    :disabled="isEditingAllowed(medicine)"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Unit*</strong></label>
                                  <select
                                    class="form-control select form-control-xs"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    v-model="medicine.unit_id"
                                    required
                                    @change="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    :disabled="isEditingAllowed(medicine)"
                                  >
                                    <option
                                      v-for="(unit, i) in units"
                                      :key="i"
                                      :value="unit.StandardDispenseUnitTypeID"
                                    >
                                      {{ unit.Plural }} ({{
                                        unit.Abbreviation
                                      }})
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label
                                    ><strong>Refills Requested*</strong></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    v-model="medicine.refills"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="0"
                                    max="99"
                                    :disabled="isEditingAllowed(medicine)"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Days Supply*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    v-model="medicine.supply_days"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="1"
                                    max="999"
                                    :disabled="isEditingAllowed(medicine)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Directions*</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.directions"
                                    placeholder="Directions of use"
                                    :disabled="isEditingAllowed(medicine)"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Pharmacy Notes</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.pharmacy_note"
                                    :disabled="isEditingAllowed(medicine)"
                                    placeholder="Notes to Pharmacy"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="row" v-if="isEditing">
                              <div class="col-12">
                                <button
                                  @click="savePrescriptionDetails"
                                  class="btn btn-sm mb-2 bg-success-light btn-block"
                                >
                                  Save Prescription
                                </button>
                              </div>
                            </div>
                          </div>

                          <!-- Refill Object -->
                          <div
                            class="card-body"
                            v-if="
                              medicine.selected_refill &&
                                medicine.pharmacy_refills
                            "
                          >
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Quantity*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.selected_refill.quantity"
                                    min="1"
                                    max="5000"
                                    step="any"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Unit*</strong></label>
                                  <select
                                    class="form-control select form-control-xs"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    v-model="medicine.selected_refill.unit_id"
                                    required
                                    @change="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    :disabled="true"
                                  >
                                    <option
                                      v-for="(unit, i) in units"
                                      :key="i"
                                      :value="unit.StandardDispenseUnitTypeID"
                                    >
                                      {{ unit.Plural }} ({{
                                        unit.Abbreviation
                                      }})
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label
                                    ><strong>Refills Requested*</strong></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    v-model="medicine.selected_refill.refills"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="0"
                                    max="99"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Days Supply*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    v-model="
                                      medicine.selected_refill.supply_days
                                    "
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="1"
                                    max="999"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Description</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="
                                      medicine.selected_refill.directions
                                    "
                                    placeholder="Directions of use"
                                    :disabled="true"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Pharmacy Notes</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="
                                      medicine.selected_refill.pharmacy_note
                                    "
                                    :disabled="true"
                                    placeholder="Notes to Pharmacy"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row"
                              v-if="
                                !isEditing &&
                                  medicine.selected_refill.status !==
                                    'Completed' &&
                                  medicine.selected_refill.status !== 'Denied'
                              "
                            >
                              <div class="col-6">
                                <button
                                  @click="
                                    showQuickDenyModal(
                                      medicine.prescription_id,
                                      medicine.selected_refill.refill_id
                                    )
                                  "
                                  class="btn btn-sm bg-warning-light btn-block"
                                >
                                  Deny Refill
                                </button>
                              </div>
                              <div class="col-6">
                                <button
                                  @click="
                                    showQuickApproveModal(
                                      medicine.prescription_id,
                                      medicine.selected_refill.refill_id
                                    )
                                  "
                                  class="btn btn-sm mb-2 bg-success-light btn-block"
                                >
                                  Approve Refill
                                </button>
                              </div>
                            </div>
                            <div class="row" v-if="isEditing">
                              <div class="col-12">
                                <button
                                  @click="savePrescriptionDetails"
                                  class="btn btn-sm mb-2 bg-success-light btn-block"
                                >
                                  Save Prescription
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- New Medicine Details -->
                      <div
                        class="col-12"
                        v-if="isSpecialRxChange(medicine.selected_rxchange)"
                      >
                        <!-- Prescription Details -->
                        <div
                          class="card"
                          v-if="isOutOfStock(medicine.selected_rxchange)"
                        >
                          <div class="card-header">
                            <div class="row">
                              <div class="col-12">
                                <h5>Pharmacy Replacement:</h5>
                              </div>
                            </div>
                            <hr />
                            <div class="row">
                              <div
                                class="col-12"
                                v-if="
                                  medicine.selected_rxchange &&
                                    medicine.selected_rxchange.suggestions[0]
                                "
                              >
                                <h4>
                                  {{
                                    medicine.selected_rxchange.suggestions[0]
                                      .medicine_name
                                  }}
                                </h4>
                              </div>
                              <div class="col-12">
                                <h6>
                                  New Message ID:
                                  {{
                                    medicine.selected_rxchange.suggestions[0]
                                      ? medicine.selected_rxchange
                                          .suggestions[0].dsp_prescription_id
                                      : medicine.dsp_prescription_id
                                  }}
                                </h6>
                                <h6>
                                  Created Date:
                                  {{
                                    medicine.selected_rxchange.suggestions[0]
                                      ? new Date(
                                          medicine.selected_rxchange.suggestions[0].written_date
                                        ).toLocaleString()
                                      : new Date(
                                          medicine.created_at
                                        ).toLocaleString()
                                  }}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Quantity*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    v-model="
                                      medicine.selected_rxchange.suggestions[0]
                                        .quantity
                                    "
                                    min="1"
                                    max="5000"
                                    step="any"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Unit*</strong></label>
                                  <select
                                    class="form-control select form-control-xs"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    v-model="
                                      medicine.selected_rxchange.suggestions[0]
                                        .unit_id
                                    "
                                    required
                                    :disabled="true"
                                  >
                                    <option
                                      v-for="(unit, i) in units"
                                      :key="i"
                                      :value="unit.StandardDispenseUnitTypeID"
                                    >
                                      {{ unit.Plural }} ({{
                                        unit.Abbreviation
                                      }})
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label
                                    ><strong>Refills Requested*</strong></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    v-model="
                                      medicine.selected_rxchange.suggestions[0]
                                        .refills
                                    "
                                    min="0"
                                    max="99"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Days Supply*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    v-model="
                                      medicine.selected_rxchange.suggestions[0]
                                        .supply_days
                                    "
                                    @change="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="1"
                                    max="999"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Description</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    v-model="
                                      medicine.selected_rxchange.suggestions[0]
                                        .directions
                                    "
                                    placeholder="Directions of use"
                                    :disabled="true"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Pharmacy Notes</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    v-model="
                                      medicine.selected_rxchange.suggestions[0]
                                        .pharmacy_note
                                    "
                                    :disabled="true"
                                    placeholder="Notes to Pharmacy"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <button
                                  @click="
                                    approveRxChangeRequest(
                                      medicine.selected_rxchange
                                        .rxchange_request_id,
                                      medicine.selected_rxchange.suggestions[0]
                                        .dsp_prescription_id,
                                      medicine.dsp_prescription_id
                                    )
                                  "
                                  class="btn mb-2 bg-success-light btn-block"
                                >
                                  Approve
                                </button>
                              </div>
                              <div class="col-12">
                                <button
                                  @click="
                                    showRejectRxChangeModal(
                                      medicine.selected_rxchange
                                        .rxchange_request_id
                                    )
                                  "
                                  class="btn btn-sm bg-warning-light btn-block"
                                >
                                  Deny
                                </button>
                              </div>
                            </div>
                          </div>

                          <!-- Refill Object -->
                          <div
                            class="card-body"
                            v-if="
                              medicine.selected_refill &&
                                medicine.pharmacy_refills
                            "
                          >
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Quantity*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.selected_refill.quantity"
                                    min="1"
                                    max="5000"
                                    step="any"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Unit*</strong></label>
                                  <select
                                    class="form-control select form-control-xs"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    v-model="medicine.selected_refill.unit_id"
                                    required
                                    @change="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    :disabled="true"
                                  >
                                    <option
                                      v-for="(unit, i) in units"
                                      :key="i"
                                      :value="unit.StandardDispenseUnitTypeID"
                                    >
                                      {{ unit.Plural }} ({{
                                        unit.Abbreviation
                                      }})
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label
                                    ><strong>Refills Requested*</strong></label
                                  >
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    v-model="medicine.selected_refill.refills"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="0"
                                    max="99"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group text-left">
                                  <label><strong>Days Supply*</strong></label>
                                  <input
                                    type="number"
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    v-model="
                                      medicine.selected_refill.supply_days
                                    "
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="1"
                                    max="999"
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Description</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="
                                      medicine.selected_refill.directions
                                    "
                                    placeholder="Directions of use"
                                    :disabled="true"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label><strong>Pharmacy Notes</strong></label>
                                  <textarea
                                    class="form-control form-control-xs"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="
                                      medicine.selected_refill.pharmacy_note
                                    "
                                    :disabled="true"
                                    placeholder="Notes to Pharmacy"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row"
                              v-if="
                                !isEditing &&
                                  medicine.selected_refill.status !==
                                    'Completed' &&
                                  medicine.selected_refill.status !== 'Denied'
                              "
                            >
                              <div class="col-6">
                                <button
                                  @click="
                                    showQuickDenyModal(
                                      medicine.prescription_id,
                                      medicine.selected_refill.refill_id
                                    )
                                  "
                                  class="btn btn-sm bg-warning-light btn-block"
                                >
                                  Deny Refill
                                </button>
                              </div>
                              <div class="col-6">
                                <button
                                  @click="
                                    showQuickApproveModal(
                                      medicine.prescription_id,
                                      medicine.selected_refill.refill_id
                                    )
                                  "
                                  class="btn btn-sm mb-2 bg-success-light btn-block"
                                >
                                  Approve Refill
                                </button>
                              </div>
                            </div>
                            <div class="row" v-if="isEditing">
                              <div class="col-12">
                                <button
                                  @click="savePrescriptionDetails"
                                  class="btn btn-sm mb-2 bg-success-light btn-block"
                                >
                                  Save Prescription
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="accordion" id="dosespot-accordion">
                          <!-- Refills History -->
                          <div
                            class="card-header"
                            :id="
                              `dosespot-accordion-${medicine.id}-${medicine.dsp_prescription_id}`
                            "
                            v-if="
                              !isSpecialRxChange(medicine.selected_rxchange) &&
                                medicine.pharmacy_refills &&
                                medicine.pharmacy_refills.length > 0
                            "
                          >
                            <h2 class="mb-0">
                              <button
                                class="btn bg-primary-light btn-block"
                                type="button"
                                data-toggle="collapse"
                                :data-target="
                                  `#dosespot-collapse-refills-${medicine.id}-${medicine.dsp_prescription_id}`
                                "
                                aria-expanded="true"
                                :aria-controls="
                                  `dosespot-collapse-refills-${medicine.id}-${medicine.dsp_prescription_id}`
                                "
                              >
                                Show Generated Refills
                              </button>
                            </h2>
                            <div class="row">
                              <div class="col-12">
                                <div
                                  :id="
                                    `dosespot-collapse-refills-${medicine.id}-${medicine.dsp_prescription_id}`
                                  "
                                  class="collapse"
                                  :aria-labelledby="
                                    `dosespot-accordion-refills-${medicine.id}-${medicine.dsp_prescription_id}`
                                  "
                                  data-parent="#dosespot-accordion"
                                >
                                  <div class="card">
                                    <div style="overflow: auto">
                                      <ul class="list-group">
                                        <li
                                          class="list-group-item"
                                          v-for="(refill,
                                          index) in medicine.pharmacy_refills"
                                          :key="index"
                                        >
                                          <p>
                                            Refill ID:
                                            <span> {{ refill.refill_id }}</span>
                                            <span
                                              v-if="
                                                medicine.selected_refill
                                                  .refill_id ===
                                                  refill.refill_id &&
                                                  medicine.selected_refill
                                                    .status !== 'Completed'
                                              "
                                              class="ml-4 badge bg-success-light"
                                              >SELECTED</span
                                            >
                                            <span
                                              v-if="
                                                medicine.selected_refill
                                                  .refill_id ===
                                                  refill.refill_id &&
                                                  medicine.selected_refill
                                                    .status === 'Completed'
                                              "
                                              class="ml-4 badge bg-success-light"
                                              >APPROVED</span
                                            >
                                          </p>
                                          <p class="mt-1">
                                            Date:
                                            {{
                                              new Date(
                                                refill.requested_date
                                              ).toLocaleString()
                                            }}
                                          </p>
                                          <p>
                                            Status:
                                            <span
                                              v-if="
                                                medicine.selected_refill
                                                  .refill_id ===
                                                  refill.refill_id &&
                                                  medicine.selected_refill
                                                    .status !== 'Completed'
                                              "
                                              class="badge bg-success-light"
                                              >SELECTED</span
                                            >
                                            <span
                                              v-if="
                                                medicine.selected_refill
                                                  .refill_id ===
                                                  refill.refill_id &&
                                                  medicine.selected_refill
                                                    .status === 'Completed'
                                              "
                                              class="badge bg-success-light"
                                              >APPROVED</span
                                            >
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- RxChange History -->
                          <div
                            class="card-header"
                            :id="
                              `dosespot-accordion-${medicine.id}-${medicine.dsp_prescription_id}`
                            "
                            v-if="
                              !isSpecialRxChange(medicine.selected_rxchange) &&
                                medicine.pharmacy_rxchanges &&
                                medicine.pharmacy_rxchanges.length > 0
                            "
                          >
                            <h2 class="mb-0">
                              <button
                                class="btn bg-primary-light btn-block"
                                type="button"
                                data-toggle="collapse"
                                :data-target="
                                  `#dosespot-collapse-rxchanges-${medicine.id}-${medicine.dsp_prescription_id}`
                                "
                                aria-expanded="true"
                                :aria-controls="
                                  `dosespot-collapse-rxchanges-${medicine.id}-${medicine.dsp_prescription_id}`
                                "
                              >
                                Show RxChange Requests
                              </button>
                            </h2>
                            <div class="row">
                              <div class="col-12">
                                <div
                                  :id="
                                    `dosespot-collapse-rxchanges-${medicine.id}-${medicine.dsp_prescription_id}`
                                  "
                                  class="collapse"
                                  :aria-labelledby="
                                    `dosespot-accordion-rxchanges-${medicine.id}-${medicine.dsp_prescription_id}`
                                  "
                                  data-parent="#dosespot-accordion"
                                >
                                  <div class="card mb-0">
                                    <div style="overflow: auto">
                                      <ul class="list-group">
                                        <li
                                          class="list-group-item"
                                          v-for="(rxchange,
                                          index) in medicine.pharmacy_rxchanges"
                                          :key="index"
                                        >
                                          <p>
                                            RxChange ID:
                                            <span>
                                              {{
                                                rxchange.rxchange_request_id
                                              }}</span
                                            >
                                          </p>
                                          <p>
                                            Message ID:
                                            <span>
                                              {{
                                                rxchange.requested_dsp_prescription_id
                                              }}</span
                                            >
                                          </p>
                                          <p>
                                            Date:
                                            {{
                                              new Date(
                                                rxchange.requested_date
                                              ).toLocaleString()
                                            }}
                                          </p>
                                          <p>
                                            Status:
                                            <span
                                              :class="
                                                getRxChangeStatusColor(
                                                  rxchange.status
                                                )
                                              "
                                              >{{
                                                String(
                                                  rxchange.status
                                                ).toUpperCase()
                                              }}</span
                                            >
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div class="row mt-3 mb-3">
                <div class="col-6">
                  <button
                    class="btn btn-sm btn-block btn-outline-info mr-2 mb-2"
                    @click="showModal('select-medicine-modal')"
                    v-show="prescriptionDetails.status === 'In Progress'"
                  >
                    <small>Change Medicine</small>
                  </button>
                </div>
                <div class="col-6">
                  <button
                    class="btn btn-sm btn-block btn-outline-info mr-2 mb-2"
                    @click="showModal('select-pharmacy-modal')"
                    v-show="prescriptionDetails.status === 'In Progress'"
                  >
                    <small> Change Pharmacy</small>
                  </button>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12">
                  <button
                    v-if="!isEditing"
                    @click="showAcceptPrescriptionModal"
                    :disabled="isError"
                    v-show="prescriptionDetails.status === 'In Progress'"
                    class="btn btn-block bg-success-light mr-2 mb-2"
                  >
                    <i class="fas fa-check"></i> Approve Request
                  </button>
                  <button
                    @click="showRejectPrescriptionModal"
                    v-if="
                      !isEditing &&
                        (prescriptionDetails.status === 'In Progress' ||
                          prescriptionDetails.status === 'Completed')
                    "
                    class="btn btn-sm btn-block bg-danger-light mr-2"
                  >
                    <i class="fas fa-times mr-2"></i> Cancel Request
                  </button>
                </div>
              </div>

              <!-- Footer buttons -->
              <div class="mb-5">
                <!-- <div
                  id="alert-msg-box"
                  class="alert alert-warning alert-dismissible fade mb-5"
                  role="alert"
                >
                  <span id="alert-msg"></span>
                </div> -->
                <!-- <div class="row"> -->

                <!-- DENY REFILL REQUEST -->
                <!-- <div v-show="prescriptionDetails.status === 'Accepted'" v-if="!isEditing"
                        class="col-6 col-md-3 mb-2">
                        <button @click="showDenyRefillRequestModal" :disabled="!isPharmacyVerified"
                          class="btn bg-warning-light btn-block">
                          Deny All Refills
                        </button>
                      </div> -->

                <!-- APPROVE BUTTON -->
                <!-- <div v-show="prescriptionDetails.status === 'Accepted'" v-if="!isEditing"
                        class="col-12 col-md-6 mb-2">
                        <button @click="showApprovePrescriptionModal" :disabled="!isPharmacyVerified"
                          class="btn bg-success-light btn-block">
                          Approve All Refills
                        </button>
                      </div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>

          <!-- PRESCRIBE NEW MEDICINES -->
          <div
            class="col-12"
            v-else-if="prescriptionDetails.status !== 'Cancelled'"
          >
            <div class="card m-0">
              <div class="card-header">
                <h4>Prescribe Medicines</h4>
              </div>
              <div class="card-body">
                <div class="accordion" id="prescribing-accordion">
                  <!-- CHECK SYMPTOMS -->
                  <div class="card-header" id="symptoms-details-accordion">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link p-0 btn-sm btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#symptoms-details-collapse"
                        aria-expanded="true"
                        aria-controls="symptoms-details-collapse"
                        @click="isSymptomsRead = true"
                      >
                        <span :class="isSymptomsRead ? 'text-success' : ''">
                          Step 1: Read Symptoms
                        </span>
                        <span
                          v-if="isSymptomsRead"
                          class="badge badge-pill bg-success inv-badge ml-3"
                          >Completed</span
                        >
                      </button>
                    </h2>
                  </div>
                  <div
                    id="symptoms-details-collapse"
                    class="collapse"
                    aria-labelledby="symptoms-details-accordion"
                    data-parent="#prescribing-accordion"
                  >
                    <div class="card-body">
                      {{ prescriptionDetails.observations.symptoms }}
                    </div>
                  </div>

                  <!-- SELECT MEDICINES -->
                  <div class="card-header" id="medicine-details-accordion">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link p-0 btn-sm btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#medicine-details-collapse"
                        aria-expanded="false"
                        aria-controls="medicine-details-collapse"
                        :disabled="
                          !isSymptomsRead && !prescriptionDetails.medicines
                        "
                        @click="showModal('select-medicine-modal')"
                      >
                        <span
                          :class="
                            prescriptionDetails.medicines ? 'text-success' : ''
                          "
                          >Step 2: Prescribe Medicines </span
                        ><span
                          v-if="prescriptionDetails.medicines"
                          class="badge badge-pill bg-success inv-badge"
                          >Completed</span
                        >
                      </button>
                    </h2>
                  </div>

                  <!-- SELECT PHARMACY -->
                  <div class="card-header" id="pharmacy-details-accordion">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link p-0 btn-sm btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#pharmacy-details-collapse"
                        aria-expanded="false"
                        aria-controls="pharmacy-details-collapse"
                        :disabled="medicineDetails.length === 0"
                        @click="showModal('select-pharmacy-modal')"
                      >
                        <span
                          :class="
                            prescriptionDetails.pharmacy ? 'text-success' : ''
                          "
                        >
                          Step 3: Select Pharmacy
                        </span>
                        <span
                          v-if="prescriptionDetails.pharmacy"
                          class="badge badge-pill bg-success inv-badge"
                          >Completed</span
                        >
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- REJECTED STATUS -->
          <div class="col-12" v-else>
            <div class="text-center p-5">
              <h4 class="text-muted">Request has been cancelled</h4>
              <h5 class="text-muted">
                For more details, please contact customer care or raise a ticket
                on support.
              </h5>
            </div>
          </div>
        </div>
        <div v-else>
          <chatbox
            class="mb-3"
            :patientImage="prescriptionDetails.patient.profile_image_url"
            :patientName="
              `${prescriptionDetails.patient.first_name} ${prescriptionDetails.patient.last_name}`
            "
            :prescriptionId="prescriptionId"
            :status="prescriptionDetails.status"
          ></chatbox>
        </div>
      </div>
    </div>

    <!-- Prescription Accept Modal -->
    <div
      class="modal fade"
      id="prescription-accept-modal"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <AcceptModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="acceptPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Approve Modal -->
    <div
      class="modal fade"
      id="prescription-approve-modal"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ApproveModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="approveAllRefills"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel Modal -->
    <div
      class="modal fade"
      id="prescription-cancel-modal"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body m-0 p-0">
            <RejectModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @cancel="rejectPrescription"
              @close="hideModal('prescription-cancel-modal')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel RX Change Modal -->
    <div
      class="modal fade"
      id="prescription-rxcancel-modal"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <DenyRxChangeModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              :selectedRxChangeId="selectedRxChangeId"
              @submit="denyRxChangeRequest"
              @close="hideModal('prescription-rxcancel-modal')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Refill Deny Modal -->
    <div class="modal fade" id="prescription-deny-modal" style="display: none">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <DenyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="denyAllRefills"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Select Medicines Modal -->
    <div class="modal fade" id="select-medicine-modal" style="display: none">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body mt-3">
            <SelectMedicineModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="saveMedicines"
              @close="hideModal('select-medicine-modal')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Select Pharmacy Modal -->
    <div class="modal fade" id="select-pharmacy-modal" style="display: none">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body mt-3">
            <SelectPharmacyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="savePharmacy"
              @close="hideModal('select-pharmacy-modal')"
            />
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import AcceptModal from "./AcceptModal.vue";
import ApproveModal from "./ApproveModal.vue";
import RejectModal from "./RejectModal.vue";
import DenyRxChangeModal from "./DenyRxChangeModal.vue";
import DenyModal from "./DenyModal.vue";
import SelectMedicineModal from "./SelectMedicineModal.vue";
import SelectPharmacyModal from "./SelectPharmacyModal.vue";
import Swal from "sweetalert2";
import chatbox from "./Chat.vue";

export default {
  props: {
    prescriptionId: String,
  },
  components: {
    chatbox,
    AcceptModal: AcceptModal,
    RejectModal: RejectModal,
    ApproveModal: ApproveModal,
    DenyRxChangeModal: DenyRxChangeModal,
    DenyModal: DenyModal,
    SelectMedicineModal: SelectMedicineModal,
    SelectPharmacyModal: SelectPharmacyModal,
  },
  mixins: [commonMixin],
  data() {
    return {
      prescriptionDetails: null,
      medicineDetails: null,
      isError: true,
      isEditing: false,
      isPharmacyVerified: false,
      newPrescribedMedicine: null,
      rxChangeRequestObj: null,
      units: [],
      isEditingMode: false,
      is_rxchange_requested: false,
      selectedRxChangeId: null,
      showFooterControls: false,
      isSymptomsRead: false,
      priorAuthNumber: "",
      npiVerification: null,
      isChatOpen: false,
      interval: null,
    };
  },
  computed: {
    chatMessages() {
      return this.$store.getters.chatMessages;
    },
    unreadMessages() {
      return this.$store.getters.chatMessages
        ? this.$store.getters.chatMessages
            .filter((d) => d.user_id !== this.$store.getters.user.id)
            .filter((c) => c.is_read === false).length
        : 0;
    },
    chatEnabled() {
      return this.prescriptionDetails.status === "Completed" ||
        this.prescriptionDetails.status === "Cancelled"
        ? false
        : true;
    },
    route() {
      return this.$route.name;
    },
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
  async created() {
    this.showLoader(true);
    const r = await this.getDispenseUnits();
    this.units = r
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);

    // LOAD PRESCRIPTION
    await this.loadPrescriptionDetails();

    // CHAT MESSAGE REQUESTS
    this.$socket.emit("REQUEST", {
      command: "GET:CHAT_MESSAGES",
      userId: this.$store.getters.user.id,
      prescriptionId: this.prescriptionId,
    });

    if (this.chatEnabled) {
      this.interval = setInterval(() => {
        if (!this.chatEnabled) {
          if (this.interval) clearInterval(this.interval);
          this.interval = null;
          return;
        }

        this.$socket.emit("REQUEST", {
          command: "GET:CHAT_MESSAGES",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }, 2000);
    } else {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    }

    this.showLoader(false);
  },
  watch: {
    medicineDetails: function() {
      this.updatePrescriptionRx();
    },
    route: function() {
      if (this.route !== "view-prescription") {
        if (this.interval) clearInterval(this.interval);
        this.interval = null;
        this.$socket.emit("REQUEST", {
          command: "GET:CLEAR_CHAT",
          userId: this.$store.getters.user.id,
        });
      }
    },
    chatMessages: function(newVal, oldVal) {
      if (newVal.length != oldVal.length && this.isChatOpen) {
        this.$socket.emit("REQUEST", {
          command: "POST:CHAT_MESSAGES_MARK_READ",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    async loadPrescriptionDetails() {
      const res = await axios.get(
        `${config.BASE_URL}${config.HTTP_API.providerGetPrescriptionById}/${this.prescriptionId}`
      );
      this.prescriptionDetails = res.data;

      this.medicineDetails = this.prescriptionDetails.medicines
        ? this.prescriptionDetails.medicines
        : [];
      const obj = this.medicineDetails.filter((m) => m.is_rxchange_requested);
      if (obj.length > 0) this.is_rxchange_requested = true;

      this.setEditModeState();
      this.updatePrescriptionRx();
    },
    closeChat() {
      this.isChatOpen = false;
    },
    markChatRead() {
      this.$socket.emit("REQUEST", {
        command: "POST:CHAT_MESSAGES_MARK_READ",
        userId: this.$store.getters.user.id,
        prescriptionId: this.prescriptionId,
      });

      this.isChatOpen = true;
    },
    showCommonRxChangeButtons(rxChangeType) {
      if (
        rxChangeType === "Prescriber Authorization" ||
        rxChangeType === "Prior Authorization" ||
        rxChangeType === "Out Of Stock"
      ) {
        return false;
      }

      return true;
    },
    isEditingAllowed(medicine) {
      return (
        !this.isEditingMode ||
        (medicine.is_rxchange_requested &&
          medicine.selected_rxchange.rxchange_type === "Out Of Stock") ||
        (medicine.pharmacy_refills && medicine.pharmacy_refills.length > 0)
      );
    },

    isOutOfStock(selectedRxChange) {
      if (!selectedRxChange) return false;
      let type = String(selectedRxChange.rxchange_type).toLowerCase();
      if (type === "out of stock") return true;
      return false;
    },
    isSpecialRxChange(selectedRxChange) {
      if (!selectedRxChange) return false;
      let type = String(selectedRxChange.rxchange_type).toLowerCase();
      if (type === "out of stock") return true;
      return false;
    },
    showModal(modalName) {
      $("#" + modalName).modal("show");
    },
    hideModal(modalName) {
      $("#" + modalName).modal("hide");
    },
    setFooterControlDisplay(type) {
      if (type === "Script Clarification") this.showFooterControls = true;
      else this.showFooterControls = false;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    async reconcilePrescription(prescriptionId) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerReconcilePrescription}`,
          {
            prescriptionId: prescriptionId,
          }
        );

        Swal.fire("Prescription Reconciled!", "", "success").then(async () => {
          await this.loadPrescriptionDetails();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async setPrintStatus(dspPatientId, prescriptionId) {
      Swal.fire({
        icon: "info",
        titleText: "Print Confirmation",
        text: `Do you want to set the prescription to printed?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success-light",
          denyButton: "bg-danger-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          try {
            this.showLoader(true);
            await axios.post(
              `${config.BASE_URL}${config.HTTP_API.providerPrintPrescription}`,
              {
                prescriptionId: prescriptionId,
                dspPatientId: dspPatientId,
              }
            );

            Swal.fire(
              "Status Updated",
              "Prescription status has been set to printed",
              "success"
            ).then(async () => {
              await this.loadPrescriptionDetails();
            });

            this.showLoader(false);
          } catch (e) {
            console.error(e);
            Swal.fire({
              icon: "error",
              title: `Ooops! ${e.response ? e.response.data.message : e}`,
              showConfirmButton: false,
              timer: 2000,
            });
            this.showLoader(false);
          }
        }
      });
    },

    selectNewMedicine(allMedicines, selectedKey) {
      // Change btn color
      for (let i = 0; i < allMedicines.length; i++) {
        if (i === selectedKey) {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm btn-info btn-block";
        } else {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm bg-info-light btn-block";
        }
      }

      this.newSelectedMedicine = allMedicines[selectedKey];
      this.showFooterControls = true;
    },

    isEditingRequired(medicine) {
      if (medicine.rxChange)
        this.setFooterControlDisplay(medicine.rxChange.rxChangeType);

      if (!medicine.is_rxchange_requested && medicine.dsp_status_id == 13) {
        this.isEditingMode = false;
      }

      if (
        medicine.is_rxchange_requested ||
        (medicine.is_refill_initiated &&
          this.prescriptionDetails.status !== "Completed")
      ) {
        this.isEditingMode = true;
      }
    },

    setEditModeState() {
      if (
        this.prescriptionDetails.status === "Cancelled" ||
        this.prescriptionDetails.status === "Accepted"
      ) {
        this.isEditingMode = false;
      } else {
        this.isEditingMode = true;
      }
    },

    async approveRxChangeRequest(
      rxChangeRequestId,
      newPrescriptionId,
      originalPrescriptionId
    ) {
      try {
        this.showLoader(true);

        let type = "";
        if (this.priorAuthNumber) type = "Prior";
        else if (this.npiVerification) type = "Prescriber";
        else type = "General";

        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerApproveRxChangeRequest}${rxChangeRequestId}`,
          {
            newPrescriptionId: this.newSelectedMedicine
              ? this.newSelectedMedicine.PrescriptionId
              : newPrescriptionId,
            originalPrescriptionId: originalPrescriptionId,
            npiVerification: this.npiVerification,
            priorAuthNumber: this.priorAuthNumber,
            type: type,
          }
        );
        this.npiVerification = null;
        this.newSelectedMedicine = null;

        Swal.fire("RxChange Completed!", "", "success").then(async () => {
          await this.loadPrescriptionDetails();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },

    async savePharmacy(pharmacyDetails) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            pharmacy: JSON.parse(pharmacyDetails),
          }
        );

        this.showLoader(false);
        this.isEditing = false;
        this.hideModal("select-pharmacy-modal");
        Swal.fire("Pharmacy Assigned!", "", "success").then(async () => {
          await this.loadPrescriptionDetails();
        });
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },

    async saveMedicines(medicines) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: JSON.parse(medicines),
          }
        );

        this.showLoader(false);
        this.isEditing = false;
        this.hideModal("select-medicine-modal");
        Swal.fire("Medicines Prescribed!", "", "success").then(async () => {
          await this.loadPrescriptionDetails();
        });
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },

    async savePrescriptionDetails() {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: this.medicineDetails,
          }
        );

        this.updatePrescriptionRx();
        this.showLoader(false);
        this.isEditing = false;
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
      }
    },
    showRejectPrescriptionModal() {
      $("#prescription-cancel-modal").modal("toggle");
    },
    showDenyRefillRequestModal() {
      $("#prescription-deny-modal").modal("toggle");
    },
    showAcceptPrescriptionModal() {
      $("#prescription-accept-modal").modal("show");
    },
    showApprovePrescriptionModal() {
      $("#prescription-approve-modal").modal("toggle");
    },
    showRejectRxChangeModal(rxChangeId) {
      this.selectedRxChangeId = rxChangeId;
      $("#prescription-rxcancel-modal").modal("toggle");
    },

    showQuickApproveModal(prescriptionId, refillId) {
      Swal.fire({
        icon: "info",
        titleText: "Need Approval Confirmation",
        text: `Do you want to approve the refill request ID: ${refillId} ?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success",
          denyButton: "bg-danger-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // SEND REFILL APPROVAL
          this.approveSingleRefill(prescriptionId, refillId);
        }
      });
    },
    showQuickDenyModal(prescriptionId, refillId) {
      Swal.fire({
        icon: "info",
        titleText: "Need Denial Confirmation",
        text: `Do you want to deny the refill request ID: ${refillId}?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success",
          denyButton: "bg-danger-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          this.denySingleRefill(prescriptionId, refillId);
        }
      });
    },

    updatePrescriptionRx() {
      let errorMessage = null;
      const directionsArray = this.medicineDetails.filter((m) => !m.directions);
      if (directionsArray.length > 0) {
        errorMessage = "Please provide the directions of use";
        this.isError = true;
        return;
      }

      const quantityArray = this.medicineDetails.filter(
        (m) => !m.quantity || m.quantity == 0
      );
      if (quantityArray.length > 0) {
        errorMessage =
          "Please enter Quantity for " +
          quantityArray.map((e) => e.name).join(", ");

        this.isError = true;
        return;
      }

      const refillsArray = this.medicineDetails.filter(
        (m) => !m.refills || m.refills < 0
      );
      if (refillsArray.length < 0) {
        errorMessage =
          "Please enter Refills for " +
          refillsArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      // if(errorMessage) {
      //   Swal.fire('', errorMessage, 'warning')
      // } else {
      //   this.isError = false;
      // }

      this.isError = false;
    },

    getRxChangeMessage(message, extraDetail) {
      switch (message) {
        case "Prescriber Authorization":
          return `Need Provider's Authorization`;

        case "State License Status":
          return `Please enter state license number for state code: ${extraDetail}`;

        default:
          return message;
      }
    },

    async acceptPrescription(prescriptionId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL +
            config.HTTP_API.providerAcceptPrescriptions}/${prescriptionId}/${
            this.$store.getters.user.id
          }`
        );

        $("#prescription-accept-modal").modal("hide");

        Swal.fire(
          "Rx Request Approved!",
          "The prescription has been sent to the pharmacy for approval",
          "success"
        ).then(async () => {
          this.$router.push("/provider/prescriptions");
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-accept-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async approveSingleRefill(prescriptionId, refillId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL +
            config.HTTP_API
              .providerApproveSingleRefill}/${prescriptionId}/${refillId}/${
            this.$store.getters.user.id
          }`
        );

        Swal.fire(
          "Refill Completed!",
          "Refill request has been approved",
          "success"
        ).then(async () => {
          await this.loadPrescriptionDetails();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async denySingleRefill(prescriptionId, refillId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL +
            config.HTTP_API
              .providerDenySingleRefill}/${prescriptionId}/${refillId}/${
            this.$store.getters.user.id
          }`
        );

        Swal.fire(
          "Refill Denied!",
          "Refill request denied successfully",
          "success"
        ).then(async () => {
          await this.loadPrescriptionDetails();
        });

        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async approveAllRefills(prescriptionId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL +
            config.HTTP_API.providerApproveAllRefills}/${prescriptionId}/${
            this.$store.getters.user.id
          }`
        );

        $("#prescription-approve-modal").modal("hide");

        Swal.fire(
          "Completed!",
          "Refill request has been approved",
          "success"
        ).then(async () => {
          await this.loadPrescriptionDetails();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        $("#prescription-approve-modal").modal("hide");
        this.showLoader(false);
      }
    },

    async denyAllRefills(prescriptionId) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL +
            config.HTTP_API.providerDenyAllRefills}/${prescriptionId}/${
            this.$store.getters.user.id
          }`,
          {
            reason: pres.reason,
          }
        );

        $("#prescription-deny-modal").modal("hide");
        Swal.fire(
          "Refill Denied!",
          "Refill request denied successfully",
          "success"
        ).then(async () => {
          await this.loadPrescriptionDetails();
        });

        this.showLoader(false);
      } catch (e) {
        $("#prescription-deny-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async rejectPrescription(prescription) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL + config.HTTP_API.providerRejectPrescriptions}/${
            pres.id
          }/${this.$store.getters.user.id}`,
          {
            reason: pres.reason,
          }
        );

        $("#prescription-cancel-modal").modal("hide");
        Swal.fire(
          "Success!",
          "Prescription request has been cancelled",
          "success"
        ).then(async () => {
          await this.loadPrescriptionDetails();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-cancel-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async acknowledgePrescription(prescriptionId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL +
            config.HTTP_API
              .providerAcknowledgePrescriptions}/${prescriptionId}/${
            this.$store.getters.user.id
          }`
        );

        Swal.fire(
          "Success!",
          "Prescription has been acknowledged and cancelled.",
          "success"
        ).then(async () => {
          await this.loadPrescriptionDetails();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-cancel-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async denyRxChangeRequest(rejectObj) {
      try {
        this.showLoader(true);
        const data = JSON.parse(rejectObj);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerDenyRxChangeRequest}${data.rxChangeId}`,
          {
            rxChangeRequestId: data.rxChangeId,
            reason: data.reason,
          }
        );
        $("#prescription-rxcancel-modal").modal("hide");

        Swal.fire("Success!", "RxChange denied successfully", "success").then(
          async () => {
            await this.loadPrescriptionDetails();
          }
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-rxcancel-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    getStatusColor(status) {
      // console.log(status);
      switch (status) {
        case "Pending":
          return "btn bg-secondary-light ml-2";

        case "In Progress":
          return "btn bg-warning-light ml-2";

        case "Completed":
        case "Accepted":
          return "btn bg-success-light ml-2";

        case "Cancelled":
          return "btn bg-danger-light ml-2";

        default:
          return "btn bg-warning-light ml-2";
      }
    },

    getRxStatusColor(status) {
      switch (status) {
        case "Pending":
          return "fas text-warning fa-refresh mr-2";

        case "Completed":
          return "fas text-success fa-refresh mr-2";

        case "Denied":
          return "fas text-danger fa-refresh mr-2";

        default:
          return "fas text-warning fa-refresh mr-2";
      }
    },

    getRxChangeStatusColor(status) {
      switch (status) {
        case "Pending":
          return "badge badge-pill bg-warning-light";

        case "Completed":
          return "badge badge-pill bg-success-light";

        case "Denied":
          return "badge badge-pill bg-danger-light";

        case "Obsolete":
          return "badge badge-pill bg-secondary-light";

        default:
          return "";
      }
    },

    getStatusText(status) {
      switch (status) {
        case "Accepted":
          if (
            this.medicineDetails.find(
              (e) =>
                e.dsp_status_id === 0 ||
                e.dsp_status_id === 1 ||
                !e.is_refill_initiated
            )
          ) {
            this.isPharmacyVerified = false;
            return "WAITING FOR PHARMACY CONFIRMATION";
          }

          this.isPharmacyVerified = true;
          return "PHARMACY VERIFIED";

        case "Cancelled":
          return this.medicineDetails.filter(
            (i) => i.dspCancellationStatus == "In Process"
          ).length > 0
            ? "Cancellation In Process"
            : "Cancelled";

        case "Error":
          return "Error / Cancelled";

        default:
          return status;
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}

.mycontent-left {
  border-right: 1px solid rgb(194, 194, 194);
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}
</style>
