import axios from 'axios'
import config, { HTTP_API } from '../../constants/config'

export default {
  state: {
    processing: false,
    location: '',
    user: null
  },
  getters: {
    processing: state => state.processing,
    location: state => state.location,
    user: state => state.user,
  },
  mutations: {
    setProcessing (state, payload) {
      state.processing = payload
    },
    setLocation (state, payload) {
      state.location = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
  },
  actions: {

    // Remove store user
    removeStoreUser ({ commit }) {
      commit('setUser', null)
      localStorage.removeItem("user-token");
      localStorage.removeItem('user')
    },

    setAuthorization ({ }, token) {
      axios.defaults.headers['Authorization'] = 'Bearer ' + token
    },

    // Validate session token
    async validateSession ({ }, token) {
      if (token) axios.defaults.headers['Authorization'] = 'Bearer ' + token
      await axios.post(config.BASE_URL + HTTP_API.session)
    },

    // Validate session token
    async validateLoginDevice ({ }, payload) {
      const res = await axios.post(config.BASE_URL + HTTP_API.validateLoginDevice, payload)
      return true
    },

    // Fetch users current location
    async updateUserLocation ({ commit }) {
      const res = await axios.get("http://ip-api.com/json");
      commit('setLocation', res.data)
      return res.data
    },

    // Update user registration information
    async registerUserDetails ({ commit }, payload) {
      let api = (payload.role == 'patient') ? HTTP_API.patientRegister : HTTP_API.providerRegister
      delete payload.role

      const res = await axios.post(config.BASE_URL + api, payload)
      // localStorage.setItem('user-token', res.data.tokens.access.token)
      // axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.tokens.access.token
      // commit('setUser', res.data.user)
      return res.data
    },

    // Save user profile details
    async saveUserDetails ({ commit }, payload) {
      let api = (payload.role == 'patient') ? HTTP_API.patientUpdateUser : HTTP_API.providerUpdateUser
      delete payload.role

      const res = await axios.post(config.BASE_URL + api, payload)
      localStorage.setItem('user', JSON.stringify(res.data))
      commit('setUser', res.data)
      return res.data
    },

    // Login to account
    async Login ({ commit }, payload) {
      const res = await axios.post(config.BASE_URL + HTTP_API.login, payload)
      localStorage.setItem('user-token', res.data.tokens.access.token)
      ReactNativeWebView.postMessage(
        JSON.stringify({ command: "LOGIN", data: JSON.stringify({ token: res.data.tokens.access.token }) })
      );

      axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.tokens.access.token
      commit('setUser', res.data.user)
      return true
    },

    // Save user profile photo
    async saveUserProfilePhoto ({ commit }, payload) {
      const res = await axios({
        method: "post",
        url: config.BASE_URL + HTTP_API.updateUserProfilePhoto,
        data: payload,
        headers: { "Content-Type": "multipart/form-data" }
      })

      commit('setUser', res.data)
      return res.data
    },

    // Complete registration
    async completeRegistrationProcess ({ commit }, payload) {
      const res = await axios.post(config.BASE_URL + HTTP_API.completeRegistration, payload)
      commit('setUser', res.data)
      return res.data
    },

    // Logout user
    async logOut ({ commit }, payload) {
      await axios.post(config.BASE_URL + HTTP_API.logout, payload)
      localStorage.removeItem('user-token')
      commit('setUser', null)
      commit('setDevice', null)
      ReactNativeWebView.postMessage(
        JSON.stringify({ command: "FLUSH_TOKEN" })
      );
      delete axios.defaults.headers['Authorization']
    },

    // Change user password
    async changeUserPassword ({ }, payload) {
      let api = (payload.role == 'patient') ? HTTP_API.patientChangePassword : HTTP_API.providerChangePassword
      delete payload.role

      const res = await axios.post(config.BASE_URL + api, payload)
      return res.data
    },

    // Deactivate account
    async deactivatePatientAccount ({ }, payload) {
      let api = HTTP_API.patientDeactivateAccount
      const res = await axios.post(config.BASE_URL + api, payload)
      return res.data
    },

    // Get user details
    async getUserDetails ({ commit }, payload) {
      const res = await axios.get(`${config.BASE_URL + HTTP_API.userDetails}/${payload}`)
      commit('setUser', res.data)
      return res.data
    },

    // Send Forgot Password Link
    async sendForgotPasswordLink ({ commit }, payload) {
      const res = await axios.post(config.BASE_URL + HTTP_API.sendResetPasswordLink, payload)
      return res.data
    },

    // Reset password
    async resetPassword ({ commit }, payload) {
      const res = await axios.post(config.BASE_URL + HTTP_API.resetPassword, payload)
      return res.data
    }
  }
}
