<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row" v-if="!isSearchInProgress">
        <div class="col-12 p-0 m-0">
          <div class="banner-wrapper-pharmacy mb-4">
            <div class="banner-header text-center">
              <h3>{{ $t("choosePharmacy.selectPharmacy") }}</h3>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 m-0" v-if="pharmacy && pharmacy.pharmacyName">
          <div class="card p-3">
            <div class="mb-3">
              <h6>{{ $t("choosePharmacy.nameLabel") }}:</h6>
              <h5>{{ pharmacy.pharmacyName }}</h5>
              <h6 class="mt-4">{{ $t("choosePharmacy.phoneLabel") }}:</h6>
              <h5 @click="callPhone">{{ pharmacy.phone }}</h5>
              <h6 class="mt-4">{{ $t("choosePharmacy.addressLabel") }}:</h6>
              <h5>{{ getStoreAddress(pharmacy.address) }}</h5>
            </div>
            <hr />
            <div class="mb-3">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary btn-block"
                @click="searchPharmacy"
              >
                <i class="fas fa-clinic-medical mr-2"></i>
                {{ $t("choosePharmacy.changePharmacyBtn") }}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-block"
                @click="completePharmacySelect"
              >
                <i class="fas fa-check mr-2"></i>
                {{ $t("choosePharmacy.proceedToStep2Btn") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 m-0" v-else>
          <div class="mb-3">
            <p>
              <small> {{ $t("choosePharmacy.selectPharmacyFirst") }}</small>
            </p>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary btn-block"
              @click="searchPharmacy"
            >
              <i class="fas fa-clinic-medical mr-2"></i>
              {{ $t("choosePharmacy.selectPharmacy") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 p-0 m-0">
          <ChangePharmacySearch
            @select="selectPharmacy"
            @cancel="isSearchInProgress = false"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import ChangePharmacySearch from "./ChangePharmacySearch.vue";
import Swal from "sweetalert2";
const images = require.context(
  "../../assets/img/stores/",
  false,
  /\.png$|\.jpg$/
);

export default {
  mixins: [commonMixin],
  components: { ChangePharmacySearch },
  data() {
    return {
      pharmacy: null,
      isSearchInProgress: false,
    };
  },
  mounted() {
    this.pharmacy = this.$store.getters.user.pharmacy;
  },
  methods: {
    ...mapActions(["showLoader"]),
    completePharmacySelect() {
      if (!this.pharmacy || !this.pharmacy.pharmacyName) {
        Swal.fire("Select Pharmacy", "Please select the pharmacy", "error");
        return;
      }

      this.$emit("select", JSON.stringify(this.pharmacy));
    },

    getStoreAddress(addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },

    searchPharmacy() {
      this.isSearchInProgress = true;
    },

    selectPharmacy(data) {
      this.pharmacy = JSON.parse(data);
      this.isSearchInProgress = false;
    },

    cancelSearch() {
      this.isSearchInProgress = false;
    },

    getImage(storeBrand) {
      switch (String(storeBrand).toLowerCase()) {
        case "walgreens":
          return images("./walgreens.jpg").default;

        default:
          return images("./notavailable.png").default;
      }
    },
  },
};
</script>
<style scoped>
.banner-wrapper-pharmacy {
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
}

.section-search {
  min-height: 570px;
}

.pharmacy-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pharmacy-search-box .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  padding-left: 35px;
}
.pharmacy-search-box .search-location {
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
  margin-right: 12px;
  position: relative;
  width: 280px;
}

.pharmacy-search-box .search-filter {
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  margin-right: 12px;
  position: relative;
  width: 180px;
}

.pharmacy-search-box .search-info {
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
  margin-right: 12px;
  position: relative;
  width: 400px;
}
.search-info .form-control {
  background: #fff url(../../assets/img/search.png) no-repeat 10px center;
}
.search-filter .form-control {
  background: #fff url(../../assets/img/search.png) no-repeat 10px center;
}

.pharmacy-search-box .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
}
.pharmacy-search-box .search-btn span {
  display: none;
  font-weight: 500;
}
.pharmacy-search-box .form-text {
  color: #757575;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .pharmacy-search-box .search-info {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }

  .pharmacy-search-box .search-filter {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }
}
</style>
