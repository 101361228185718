<template>
  <div class="main-wrapper">
    <!-- Home Banner -->
    <section class="section section-search mt-5" style="height: 100vh">
      <div class="container-fluid">
        <div class="banner-wrapper text-center">
          <img
            src="../assets/img/logo.png"
            class="img-fluid mb-5"
            alt="StepaheadHealth"
            width="200"
          />

          <div class="banner-header text-center">
            <h1>404</h1>
            <p class="mb-3">OOPS! PAGE NOT FOUND.</p>
            <button @click="goBack" class="btn btn-info">Go Back</button>
          </div>
        </div>
      </div>
    </section>
    <!-- /Home Banner -->
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      window.history.go(-1);
      return false;
    },
  },
};
</script>