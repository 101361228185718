<template>
  <div class="main-wrapper" v-if="prescriptionDetails">
    <layout-header></layout-header>

    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 col-12">
            <h2 class="breadcrumb-title">
              Rx Refill ID: {{ prescriptionDetails.id }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <h4>
                  Request Date:
                  {{
                    new Date(prescriptionDetails.created_at).toLocaleString()
                  }}
                </h4>
              </div>
              <div class="col-8 text-right">
                <h4>
                  Status:
                  <button
                    v-if="
                      medicineDetails.filter(
                        (m) =>
                          m.dsp_cancel_message &&
                          String(m.dsp_cancel_message).length > 0
                      ).length === 0
                    "
                    :class="getStatusColor(prescriptionDetails.status)"
                  >
                    {{ getStatusText(prescriptionDetails.status) }}
                  </button>
                  <button v-else :class="getStatusColor('Cancelled')">
                    {{ getStatusText("Error") }}
                  </button>
                  <button
                    v-show="medicineDetails.is_rxchange_requested"
                    class="btn bg-warning-light ml-2"
                  >
                    RX Change Requested
                  </button>
                  <button
                    @click="setPrintStatus(prescriptionDetails.id)"
                    v-show="prescriptionDetails.status === 'Accepted'"
                    class="btn bg-info-light ml-2"
                  >
                    Print
                  </button>
                </h4>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <div
                  class="accordion"
                  id="prescription-accordion"
                  style="font-size: 12px"
                >
                  <div class="card">
                    <div class="card-header" id="patient-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#patient-info-collapse"
                          aria-expanded="true"
                          aria-controls="patient-info-collapse"
                        >
                          <i class="fas fa-user mr-1"></i> Patient Information
                        </button>
                      </h2>
                    </div>

                    <div
                      id="patient-info-collapse"
                      class="collapse show"
                      aria-labelledby="patient-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        <p>
                          Name: {{ prescriptionDetails.patient.first_name }}
                          {{ prescriptionDetails.patient.last_name }}
                        </p>
                        <p>
                          Date of birth:
                          {{ prescriptionDetails.patient.dob }}
                          <!-- ({{
                            getAge(prescriptionDetails.patient.dob)
                          }}
                          Years) -->
                        </p>
                        <p>
                          Gender:
                          {{ prescriptionDetails.patient.gender }}
                        </p>
                        <p>
                          Height:
                          {{
                            String(prescriptionDetails.patient.height).replace(
                              "-",
                              " "
                            )
                          }}
                        </p>
                        <p>
                          Weight:
                          {{
                            String(prescriptionDetails.patient.weight).replace(
                              "-",
                              " "
                            )
                          }}
                        </p>
                        <p>
                          Mobile:
                          {{ prescriptionDetails.patient.primary_phone }}
                        </p>
                        <p>
                          Address:
                          {{ prescriptionDetails.patient.address1 }},
                          {{ prescriptionDetails.patient.address2 }},
                          {{ prescriptionDetails.patient.city }},
                          {{ prescriptionDetails.patient.state }} -
                          {{ prescriptionDetails.patient.zip_code }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="pharmacy-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#pharmacy-info-collapse"
                          aria-expanded="false"
                          aria-controls="pharmacy-info-collapse"
                        >
                          <i class="fas fa-hospital mr-1"></i> Pharmacy Details
                        </button>
                      </h2>
                    </div>
                    <div
                      id="pharmacy-info-collapse"
                      class="collapse"
                      aria-labelledby="pharmacy-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        <p>
                          Store ID:
                          {{ prescriptionDetails.pharmacy.pharmacy_id }}
                        </p>
                        <p>
                          Store Name:
                          {{ prescriptionDetails.pharmacy.pharmacyName }}
                        </p>
                        <p>Phone: {{ prescriptionDetails.pharmacy.phone }}</p>
                        <p v-if="prescriptionDetails.pharmacy.address">
                          Address:
                          {{ prescriptionDetails.pharmacy.address1 }},
                          {{ prescriptionDetails.pharmacy.address2 }},
                          {{
                            prescriptionDetails.pharmacy.address.addressLine3
                          }}, {{ prescriptionDetails.pharmacy.city }},
                          {{ prescriptionDetails.pharmacy.state }} -
                          {{ prescriptionDetails.pharmacy.address.zipCode }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="health-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#health-info-collapse"
                          aria-expanded="false"
                          aria-controls="health-info-collapse"
                        >
                          <i class="fas fa-heart mr-1"></i> Health Information
                        </button>
                      </h2>
                    </div>
                    <div
                      id="health-info-collapse"
                      class="collapse"
                      aria-labelledby="health-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div
                        class="card-body"
                        v-if="prescriptionDetails.dosageInfo"
                      >
                        <p>
                          Current Medications:
                          {{
                            prescriptionDetails.dosageInfo.currentMedications
                          }}
                        </p>
                        <p>
                          Known Allergies:
                          {{ prescriptionDetails.dosageInfo.knownAllergies }}
                        </p>
                        <p>
                          Reason for Medication:
                          {{
                            prescriptionDetails.dosageInfo.reasonForMedication
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card m-0" style="font-size: 12px">
                  <div class="card-header">
                    <h4>Requested Medications</h4>
                  </div>
                  <div class="card-body">
                    <div
                      class="accordion"
                      style="height: 300px; overflow: auto"
                      id="medicine-accordion"
                    >
                      <div
                        class="card"
                        v-for="(medicine, i) in medicineDetails"
                        :key="i"
                      >
                        <div
                          class="card-header"
                          :id="`medicine-accordion-${i}`"
                        >
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-sm btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              :data-target="`#medicine-collapse-${i}`"
                              aria-expanded="true"
                              :aria-controls="`medicine-collapse-${i}`"
                              @click="isEditingRequired(medicine)"
                            >
                              {{ i + 1 }}. {{ medicine.name }}
                              <div
                                class="float-right"
                                v-if="
                                  medicine.dspCancellationStatus == 'Completed'
                                "
                              >
                                <i
                                  class="fas text-danger fa-ban mr-2"
                                  title="Prescription Cancelled"
                                ></i>
                              </div>
                              <div v-else class="float-right">
                                <i
                                  v-if="medicine.directions"
                                  :id="`status-pass-${i}`"
                                  class="fas text-success fa-check mr-2"
                                  title="Looks good"
                                ></i>
                                <i
                                  v-else
                                  :id="`status-fail-${i}`"
                                  class="fas text-warning fa-exclamation mr-2"
                                  title="Incomplete details"
                                ></i>
                                <i
                                  v-if="!medicine.isRefillInitiated"
                                  :id="`status-refill-${i}`"
                                  class="fas text-warning fa-clock mr-2"
                                  title="Pending pharmacy confirmation"
                                ></i>
                                <i
                                  v-else
                                  :id="`status-refill-${i}`"
                                  class="fas text-success fa-file mr-2"
                                  title="Refill is ready"
                                ></i>
                                <i
                                  v-if="medicine.is_rxchange_requested"
                                  :id="`status-fail-${i}`"
                                  :class="
                                    getRxStatusColor(medicine.rxChangeStatus)
                                  "
                                  title="Rx Change Requested"
                                ></i>
                                <!-- <i class="fas text-danger fa-trash-alt"></i> -->
                              </div>
                              <br />
                              <span
                                v-if="medicine.pin"
                                class="m-2"
                                style="font-size: 11px"
                                >PIN:
                                {{
                                  medicine.pin ? medicine.pin : "Not Generated"
                                }}</span
                              >
                              <span
                                v-if="
                                  prescriptionDetails.selectedRefill
                                    .totalRefills &&
                                  prescriptionDetails.status !== 'Cancelled'
                                "
                                class="m-2"
                                style="font-size: 11px"
                                >Total Refills Count:
                                {{
                                  prescriptionDetails.selectedRefill
                                    .totalRefills
                                }}</span
                              >
                              <span
                                v-if="
                                  prescriptionDetails.selectedRefill
                                    .latestRefillId &&
                                  prescriptionDetails.status !== 'Cancelled'
                                "
                                class="m-2"
                                style="font-size: 11px"
                                >Refill ID:
                                {{
                                  prescriptionDetails.selectedRefill
                                    .latestRefillId
                                }}</span
                              >
                              <span
                                v-if="
                                  prescriptionDetails.selectedRefill
                                    .latestRefillDateTime &&
                                  prescriptionDetails.status !== 'Cancelled'
                                "
                                class="m-2"
                                style="font-size: 11px"
                                >Written Date:
                                {{
                                  new Date(
                                    prescriptionDetails.selectedRefill.latestRefillDateTime
                                  ).toLocaleString()
                                }}</span
                              >
                              <span
                                v-if="
                                  prescriptionDetails.status == 'Cancelled' ||
                                  (prescriptionDetails.status == 'Accepted' &&
                                    medicine.dsp_cancel_message)
                                "
                                class="m-2"
                                style="font-size: 11px"
                                >Pharmacy Message:
                                <strong>{{
                                  medicine.dsp_cancel_message
                                }}</strong></span
                              >
                              <span
                                v-if="
                                  medicine.rxChange &&
                                  medicine.is_rxchange_requested
                                "
                                class="m-2"
                                style="font-size: 11px"
                                >Pharmacy Message:
                                <span class="text-danger">
                                  {{
                                    getRxChangeMessage(
                                      medicine.rxChange.rxChangeType
                                    )
                                  }}</span
                                >
                              </span>
                            </button>
                          </h2>
                        </div>

                        <div
                          :id="`medicine-collapse-${i}`"
                          class="collapse"
                          :aria-labelledby="`medicine-accordion-${i}`"
                          data-parent="#medicine-accordion"
                        >
                          <div class="card-body">
                            <div class="table-responsive">
                              <!-- RX CHANGE REQUEST -->
                              <table
                                class="table table-hover table-center"
                                v-if="
                                  medicine.is_rxchange_requested &&
                                  medicine.rxChangeStatus !== 'Completed'
                                "
                              >
                                <thead>
                                  <tr>
                                    <th
                                      v-if="
                                        medicine.rxChange &&
                                        medicine.rxChange.rxChangeType ==
                                          'Prior Authorization'
                                      "
                                    >
                                      Need Action
                                    </th>
                                    <th
                                      v-else-if="
                                        medicine.rxChange
                                          .requestedMedications &&
                                        medicine.rxChange.requestedMedications
                                          .length > 0
                                      "
                                    >
                                      <div class="row">
                                        <div class="col-8">
                                          Pharmacy Alternatives
                                        </div>
                                        <div class="col-4">
                                          <button
                                            @click="
                                              showRejectRxChangeModal(
                                                medicine.rxChange
                                                  .rxChangeRequestId
                                              )
                                            "
                                            class="
                                              btn btn-sm
                                              bg-danger-light
                                              btn-block
                                            "
                                          >
                                            Deny Request
                                          </button>
                                        </div>
                                      </div>
                                    </th>

                                    <th v-else>Need Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div
                                        v-if="
                                          medicine.rxChange &&
                                          medicine.rxChange.rxChangeType ==
                                            'Prior Authorization'
                                        "
                                      >
                                        <h5 class="text-danger">
                                          Please enter prior authorization
                                          number (Max 35 Characters)
                                        </h5>
                                        <form
                                          @submit.prevent="
                                            approveRxChangeRequest(
                                              medicine.rxChange
                                                .rxChangeRequestId,
                                              medicine.rxChange
                                                .prescribedMedication
                                                .PrescriptionId,
                                              medicine.dspPrescriptionId
                                            )
                                          "
                                        >
                                          <div class="input-group">
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="Prior Authorization Number (Max 35 Characters)"
                                              v-model="priorAuthNumber"
                                              maxlength="35"
                                              required
                                            />
                                            <div class="input-group-append">
                                              <button
                                                class="
                                                  btn btn-sm
                                                  bg-success-light
                                                "
                                                type="submit"
                                              >
                                                Approve
                                              </button>
                                              <button
                                                @click="
                                                  showRejectRxChangeModal(
                                                    medicine.rxChange
                                                      .rxChangeRequestId
                                                  )
                                                "
                                                class="
                                                  btn btn-sm
                                                  bg-danger-light
                                                "
                                                type="button"
                                              >
                                                Deny
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      <div
                                        v-else-if="
                                          medicine.rxChange &&
                                          medicine.rxChange.rxChangeType ==
                                            'Script Clarification'
                                        "
                                      >
                                        <h5 class="text-danger">
                                          Please verify the medication
                                          directions, quantity and refills
                                        </h5>
                                      </div>
                                      <div
                                        v-else-if="
                                          medicine.rxChange
                                            .requestedMedications &&
                                          medicine.rxChange.requestedMedications
                                            .length > 0
                                        "
                                      >
                                        <div
                                          class="row m-2"
                                          v-for="(newMedicine, key) in medicine
                                            .rxChange.requestedMedications"
                                          :key="key"
                                        >
                                          <div class="col-8">
                                            {{ newMedicine.DisplayName }}
                                          </div>
                                          <div class="col-4">
                                            <button
                                              :id="`select-btn-${key}`"
                                              class="
                                                btn btn-sm
                                                bg-info-light
                                                btn-block
                                              "
                                              @click="
                                                selectNewMedicine(
                                                  medicine.rxChange
                                                    .requestedMedications,
                                                  key,
                                                  medicine.dspPrescriptionId
                                                )
                                              "
                                            >
                                              Select
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="
                                          medicine.rxChange &&
                                          medicine.rxChange.rxChangeType ==
                                            'Prescriber Authorization'
                                        "
                                      >
                                        <div class="row">
                                          <div class="col-12">
                                            <h5>
                                              {{
                                                getRxChangeMessage(
                                                  medicine.rxChange
                                                    .rxChangeSubType,
                                                  medicine.rxChange.pharmacy
                                                    .State
                                                )
                                              }}
                                            </h5>
                                            <form
                                              @submit.prevent="
                                                approveRxChangeRequest(
                                                  medicine.rxChange
                                                    .rxChangeRequestId,
                                                  medicine.rxChange
                                                    .prescribedMedication
                                                    .PrescriptionId,
                                                  medicine.dspPrescriptionId
                                                )
                                              "
                                            >
                                              <div class="input-group">
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="NPI ID"
                                                  aria-label="NPI ID"
                                                  v-model="npiVerification"
                                                  required
                                                />
                                                <div class="input-group-append">
                                                  <button
                                                    class="
                                                      btn btn-sm
                                                      bg-success-light
                                                    "
                                                    type="submit"
                                                  >
                                                    Verify & Approve
                                                  </button>
                                                  <button
                                                    @click="
                                                      showRejectRxChangeModal(
                                                        medicine.rxChange
                                                          .rxChangeRequestId
                                                      )
                                                    "
                                                    class="
                                                      btn btn-sm
                                                      bg-danger-light
                                                    "
                                                    type="button"
                                                  >
                                                    Deny
                                                  </button>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <!-- DIRECTIONS TO USE AND QUANTITY -->
                              <table
                                class="
                                  table table-hover table-center
                                  webViewTable
                                "
                              >
                                <thead>
                                  <tr>
                                    <th>Directions</th>
                                    <th>Notes</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Refills Requested</th>
                                    <th>Days Supply</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <textarea
                                          class="form-control"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="medicine.directions"
                                          placeholder="Directions of use"
                                          :disabled="!isEditingMode"
                                        ></textarea>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="form-group">
                                        <textarea
                                          class="form-control"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="medicine.pharmacyMessage"
                                          :disabled="!isEditingMode"
                                          placeholder="Notes to Pharmacy"
                                        ></textarea>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        class="form-control"
                                        @keyup="updatePrescriptionRx"
                                        @keydown="
                                          () => {
                                            isEditing = true;
                                          }
                                        "
                                        v-model="medicine.quantity"
                                        min="1"
                                        max="5000"
                                        step="any"
                                        :disabled="!isEditingMode"
                                      />
                                    </td>
                                    <td>
                                      <select
                                        class="form-control select"
                                        tabindex="-1"
                                        aria-hidden="true"
                                        v-model="medicine.unit"
                                        required
                                        :disabled="!isEditingMode"
                                      >
                                        <option
                                          v-for="(unit, i) in units"
                                          :key="i"
                                          :value="unit"
                                        >
                                          {{ unit.Plural }} ({{
                                            unit.Abbreviation
                                          }})
                                        </option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        class="form-control"
                                        @keyup="updatePrescriptionRx"
                                        v-model="medicine.refills"
                                        @keydown="
                                          () => {
                                            isEditing = true;
                                          }
                                        "
                                        min="0"
                                        max="99"
                                        :disabled="!isEditingMode"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        class="form-control"
                                        @keyup="updatePrescriptionRx"
                                        v-model="medicine.daysSupply"
                                        @keydown="
                                          () => {
                                            isEditing = true;
                                          }
                                        "
                                        min="1"
                                        max="999"
                                        :disabled="!isEditingMode"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <!-- Mobile view = direction to use -->
                              <div class="mobileView">
                                <div class="row mb-2">
                                  <label class="col-4">Usage: </label>
                                  <textarea
                                    class="form-control col-8"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.directions"
                                    :disabled="!isEditingMode"
                                  ></textarea>
                                </div>

                                <div class="row mb-2">
                                  <label class="col-4 mb-2">Qty: </label>
                                  <input
                                    type="number"
                                    class="form-control col-8"
                                    @keyup="updatePrescriptionRx"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    v-model="medicine.quantity"
                                    min="1"
                                    max="5000"
                                    step="any"
                                    :disabled="!isEditingMode"
                                  />
                                </div>

                                <div class="row mb-2">
                                  <label class="col-4">Unit: </label>
                                  <select
                                    class="form-control select col-8"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    v-model="medicine.unit"
                                    required
                                    :disabled="!isEditingMode"
                                  >
                                    <option
                                      v-for="(unit, i) in units"
                                      :key="i"
                                      :value="unit"
                                    >
                                      {{ unit.Plural }} ({{
                                        unit.Abbreviation
                                      }})
                                    </option>
                                  </select>
                                </div>

                                <div class="row mb-2">
                                  <label class="col-4">Refills: </label>
                                  <input
                                    type="number"
                                    class="form-control col-8"
                                    @keyup="updatePrescriptionRx"
                                    v-model="medicine.refills"
                                    @keydown="
                                      () => {
                                        isEditing = true;
                                      }
                                    "
                                    min="0"
                                    max="50"
                                    :disabled="!isEditingMode"
                                  />
                                </div>
                              </div>
                              <!-- / Mobile view = direction to use -->

                              <!-- APPROVE DENY -->
                              <div
                                class="row p-1"
                                v-if="
                                  medicine.isPrinted && !medicine.isReconciled
                                "
                              >
                                <div class="col-12">
                                  <button
                                    @click="
                                      reconcilePrescription(
                                        medicine.dspPrescriptionId
                                      )
                                    "
                                    class="btn bg-info-light ml-2 btn-block"
                                  >
                                    Reconcile Prescription
                                  </button>
                                </div>
                              </div>

                              <div
                                class="row p-1"
                                v-if="
                                  medicine.is_rxchange_requested &&
                                  !isEditing &&
                                  showFooterControls
                                "
                              >
                                <div class="col-6">
                                  <button
                                    @click="
                                      approveRxChangeRequest(
                                        medicine.rxChange.rxChangeRequestId,
                                        medicine.rxChange.prescribedMedication
                                          .PrescriptionId,
                                        medicine.dspPrescriptionId
                                      )
                                    "
                                    class="
                                      btn btn-sm
                                      bg-success-light
                                      btn-block
                                    "
                                  >
                                    Approve
                                  </button>
                                </div>
                                <div class="col-6">
                                  <button
                                    @click="
                                      showRejectRxChangeModal(
                                        medicine.rxChange.rxChangeRequestId
                                      )
                                    "
                                    class="btn btn-sm bg-danger-light btn-block"
                                  >
                                    Deny
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Footer buttons -->
                    <div class="p-2 mt-4">
                      <div
                        id="alert-msg-box"
                        class="alert alert-warning alert-dismissible fade"
                        role="alert"
                      >
                        <span id="alert-msg"></span>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="isEditing">
                          <button
                            @click="savePrescriptionDetails"
                            class="btn bg-info-light btn-block"
                          >
                            Save Prescription
                          </button>
                        </div>
                        <!-- REJECT PRESCRIPTION -->
                        <div
                          v-if="
                            !isEditing &&
                            prescriptionDetails.status !== 'Cancelled'
                          "
                          class="col-6 col-md-3 mb-2"
                        >
                          <button
                            @click="showRejectPrescriptionModal"
                            class="btn btn-sm bg-danger-light btn-block"
                          >
                            Cancel
                          </button>
                        </div>

                        <!-- DENY REFILL REQUEST -->
                        <div
                          v-show="prescriptionDetails.status === 'Accepted'"
                          v-if="!isEditing"
                          class="col-6 col-md-3 mb-2"
                        >
                          <button
                            @click="showDenyRefillRequestModal"
                            :disabled="!isPharmacyVerified"
                            class="btn btn-sm bg-warning-light btn-block"
                          >
                            Deny Refill
                          </button>
                        </div>

                        <!-- ACCEPT BUTTON -->
                        <div
                          v-show="prescriptionDetails.status === 'InProgress'"
                          v-if="!isEditing"
                          class="col-6"
                        >
                          <button
                            @click="showAcceptPrescriptionModal"
                            :disabled="isError"
                            class="btn btn-sm bg-success-light btn-block"
                          >
                            Accept Prescription
                          </button>
                        </div>

                        <!-- APPROVE BUTTON -->
                        <div
                          v-show="prescriptionDetails.status === 'Accepted'"
                          v-if="!isEditing"
                          class="col-12 col-md-6 mb-2"
                        >
                          <button
                            @click="showApprovePrescriptionModal"
                            :disabled="!isPharmacyVerified"
                            class="btn btn-sm bg-success-light btn-block"
                          >
                            Approve Refill Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 m-0">
                <div class="card m-0" style="font-size: 12px">
                  <div class="card-header">
                    <h4>Refills History</h4>
                  </div>
                  <div style="overflow: auto; height: 470px">
                    <div
                      v-for="(refill, index) in prescriptionDetails.refills"
                      :key="index"
                    >
                      <div
                        :class="`card p-2 m-1 ${
                          refill.isSelected ? 'bg-success-light' : ''
                        }`"
                      >
                        <div class="row">
                          <div class="col-7">
                            Refill ID: <span> {{ refill.refillId }}</span>
                          </div>
                          <div class="col-5" v-if="refill.isSelected">
                            <span class="badge bg-success-light">SELECTED</span>
                          </div>
                        </div>
                        <br />
                        <span
                          >Date:
                          {{ new Date(refill.dateTime).toLocaleString() }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Accept Modal -->
    <div
      class="modal fade"
      id="prescription-accept-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <AcceptModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="acceptPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Approve Modal -->
    <div
      class="modal fade"
      id="prescription-approve-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <ApproveModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="approvePrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel Modal -->
    <div
      class="modal fade"
      id="prescription-cancel-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <RejectModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @cancel="rejectPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel RX Change Modal -->
    <div
      class="modal fade"
      id="prescription-rxcancel-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <DenyRxChangeModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              :selectedRxChangeId="selectedRxChangeId"
              @submit="denyRxChangeRequest"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Refill Deny Modal -->
    <div class="modal fade" id="prescription-deny-modal" style="display: none">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <DenyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="denyPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import AcceptModal from "./AcceptModal.vue";
import ApproveModal from "./ApproveModal.vue";
import RejectModal from "./RejectModal.vue";
import DenyRxChangeModal from "./DenyRxChangeModal.vue";
import DenyModal from "./DenyModal.vue";
import Swal from "sweetalert2";

export default {
  props: {
    prescriptionId: String,
  },
  components: {
    AcceptModal: AcceptModal,
    RejectModal: RejectModal,
    ApproveModal: ApproveModal,
    DenyRxChangeModal: DenyRxChangeModal,
    DenyModal: DenyModal,
  },
  mixins: [commonMixin],
  data() {
    return {
      prescriptionDetails: null,
      medicineDetails: null,
      isError: true,
      isEditing: false,
      isPharmacyVerified: false,
      newPrescribedMedicine: null,
      rxChangeRequestObj: null,
      units: [],
      isEditingMode: false,
      is_rxchange_requested: false,
      selectedRxChangeId: null,
      showFooterControls: false,
    };
  },
  async created() {
    this.showLoader(true);
    const r = await this.getDispenseUnits();
    this.units = r
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);

    const res = await axios.get(
      `${config.BASE_URL}${config.HTTP_API.providerGetPrescriptionById}/${this.prescriptionId}`
    );
    this.prescriptionDetails = res.data;

    if (this.prescriptionDetails.refills.length > 0) {
      let currentMedicine = this.prescriptionDetails.medicines[0];
      currentMedicine.latestPrescriptionId =
        this.prescriptionDetails.refills[0].PrescriptionId;
      currentMedicine.quantity = this.prescriptionDetails.refills[0].Quantity;
      currentMedicine.refills = this.prescriptionDetails.refills[0].Refills;
    }

    this.medicineDetails = this.prescriptionDetails.medicines;
    const obj = this.medicineDetails.filter((m) => m.is_rxchange_requested);
    if (obj.length > 0) this.is_rxchange_requested = true;

    this.setEditModeState();
    this.showLoader(false);
  },
  watch: {
    medicineDetails: function () {
      this.updatePrescriptionRx();
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    setFooterControlDisplay(type) {
      if (type === "Script Clarification") this.showFooterControls = true;
      else this.showFooterControls = false;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    async reconcilePrescription(prescriptionId) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerReconcilePrescription}`,
          {
            prescriptionId: prescriptionId,
          }
        );

        Swal.fire(
          "Prescription Reconciled!",
          "Prescription has been reconciled successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async setPrintStatus(prescriptionId) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerPrintPrescription}`,
          {
            prescriptionId: prescriptionId,
          }
        );

        Swal.fire(
          "Print Successful!",
          "Prescription has been printed successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }

      // /prescriptions/print
    },

    selectNewMedicine(allMedicines, selectedKey, originalPrescriptionId) {
      // Change btn color
      for (let i = 0; i < allMedicines.length; i++) {
        if (i === selectedKey) {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm btn-info btn-block";
        } else {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm bg-info-light btn-block";
        }
      }

      this.newSelectedMedicine = allMedicines[selectedKey];
      const currentMedicine = this.medicineDetails.find(
        (i) => i.dspPrescriptionId == originalPrescriptionId
      );

      currentMedicine.directions = this.newSelectedMedicine.Directions;
      currentMedicine.name = this.newSelectedMedicine.DisplayName;
      currentMedicine.ndc = this.newSelectedMedicine.NDC;
      currentMedicine.quantity = this.newSelectedMedicine.Quantity;
      currentMedicine.refills = this.newSelectedMedicine.Refills;
      this.showFooterControls = true;
    },

    isEditingRequired(medicine) {
      if (medicine.rxChange)
        this.setFooterControlDisplay(medicine.rxChange.rxChangeType);

      if (!medicine.is_rxchange_requested && medicine.dspStatusId == 13) {
        this.isEditingMode = false;
      }

      if (
        medicine.is_rxchange_requested ||
        (medicine.isRefillInitiated &&
          this.prescriptionDetails.status !== "Completed")
      ) {
        this.isEditingMode = true;
      }
    },

    setEditModeState() {
      if (
        this.prescriptionDetails.status === "Cancelled" ||
        this.prescriptionDetails.status === "Accepted"
      ) {
        this.isEditingMode = false;
      } else {
        this.isEditingMode = true;
      }
    },

    async approveRxChangeRequest(
      rxChangeRequestId,
      selectedMedicationId,
      dspPrescriptionId
    ) {
      try {
        this.showLoader(true);

        let type = "";
        if (this.priorAuthNumber) type = "Prior";
        else if (this.npiVerification) type = "Prescriber";
        else type = "General";

        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerApproveRxChangeRequest}${rxChangeRequestId}`,
          {
            newPrescriptionId: this.newSelectedMedicine
              ? this.newSelectedMedicine.PrescriptionId
              : selectedMedicationId,
            dspPrescriptionId: dspPrescriptionId,
            npiVerification: this.npiVerification,
            priorAuthNumber: this.priorAuthNumber,
            type: type,
          }
        );
        this.npiVerification = "";
        this.newSelectedMedicine = null;

        Swal.fire(
          "RxChange Completed!",
          "RxChange request has been approved.",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
        Swal.fire(
          "Approval Failed!",
          "Failed to approve RxChange request",
          "error"
        );
      }
    },

    async denyRxChangeRequest() {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerDenyRxChangeRequest}${this.rxChangeRequestObj.RxChangeRequestId}`
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
      }
    },
    async savePrescriptionDetails() {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: this.medicineDetails,
          }
        );

        this.showLoader(false);
        this.isEditing = false;
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
      }
    },
    showRejectPrescriptionModal() {
      $("#prescription-cancel-modal").modal("toggle");
    },
    showDenyRefillRequestModal() {
      $("#prescription-deny-modal").modal("toggle");
    },
    showAcceptPrescriptionModal() {
      $("#prescription-accept-modal").modal("show");
    },
    showApprovePrescriptionModal() {
      $("#prescription-approve-modal").modal("toggle");
    },
    showRejectRxChangeModal(rxChangeId) {
      this.selectedRxChangeId = rxChangeId;
      $("#prescription-rxcancel-modal").modal("toggle");
    },

    updatePrescriptionRx() {
      const alertBox = document.getElementById("alert-msg-box");
      const alertMsg = document.getElementById("alert-msg");
      const directionsArray = this.medicineDetails.filter((m) => !m.directions);
      if (directionsArray.length > 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Directions of use for " +
            directionsArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      const quantityArray = this.medicineDetails.filter(
        (m) => !m.quantity || m.quantity == 0
      );
      if (quantityArray.length > 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Quantity for " +
            quantityArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      const refillsArray = this.medicineDetails.filter(
        (m) => !m.refills || m.refills < 0
      );
      if (refillsArray.length < 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Refills for " +
            refillsArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      if (alertBox) {
        alertBox.classList = "alert alert-success alert-dismissible fade show";
      }
      if (alertMsg)
        alertMsg.innerText =
          "Great! Everything looks good. Please save the prescription.";
      this.isError = false;
    },

    getRxChangeMessage(message, extraDetail) {
      switch (message) {
        case "Prescriber Authorization":
          return `Need Provider's Authorization`;

        case "State License Status":
          return `Please verify state license number for state code: ${extraDetail}`;

        default:
          return message;
      }
    },

    async acceptPrescription(prescriptionId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerAcceptPrescriptions
          }/${prescriptionId}/${this.$store.getters.user.id}`
        );

        $("#prescription-accept-modal").modal("hide");

        Swal.fire(
          "Accepted!",
          "Prescription request accepted successfully!",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async approvePrescription(prescriptionId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerApprovePrescriptions
          }/${prescriptionId}/${this.$store.getters.user.id}`
        );

        $("#prescription-approve-modal").modal("hide");

        Swal.fire("Success!", "Refill request approved", "success").then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        $("#prescription-approve-modal").modal("hide");
        this.showLoader(false);
      }
    },

    async rejectPrescription(prescription) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);

        const res = await axios.post(
          `${config.BASE_URL + config.HTTP_API.providerRejectPrescriptions}/${
            pres.id
          }/${this.$store.getters.user.id}`,
          {
            reason: pres.reason,
            latestPrescriptionId: pres.dspPrescriptionId,
          }
        );

        $("#prescription-cancel-modal").modal("hide");
        Swal.fire(
          "Success!",
          "Prescription request rejected successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-cancel-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async denyRxChangeRequest(rejectObj) {
      try {
        this.showLoader(true);
        const data = JSON.parse(rejectObj);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerDenyRxChangeRequest}${data.rxChangeId}`,
          {
            rxChangeRequestId: data.rxChangeId,
            reason: data.reason,
          }
        );
        $("#prescription-rxcancel-modal").modal("hide");

        Swal.fire("Success!", "RxChange denied successfully", "success").then(
          () => {
            this.$router.go();
          }
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-rxcancel-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async denyPrescription(prescription) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);
        const res = await axios.post(
          `${config.BASE_URL + config.HTTP_API.providerDenyRefillRequest}/${
            pres.refillId
          }/${this.$store.getters.user.id}`,
          {
            prescriptionId: pres.id,
            reason: pres.reason,
          }
        );

        $("#prescription-deny-modal").modal("hide");
        Swal.fire(
          "Success!",
          "Refill request denied successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        $("#prescription-deny-modal").modal("hide");
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    getStatusColor(status) {
      // console.log(status);
      switch (status) {
        case "Pending":
          return "btn bg-secondary-light ml-2";

        case "InProgress":
          return "btn bg-warning-light ml-2";

        case "Completed":
        case "Accepted":
          return "btn bg-success-light ml-2";

        case "Cancelled":
          return "btn bg-danger-light ml-2";

        default:
          return "btn bg-warning-light ml-2";
      }
    },

    getRxStatusColor(status) {
      switch (status) {
        case "Pending":
          return "fas text-warning fa-refresh mr-2";

        case "Completed":
          return "fas text-success fa-refresh mr-2";

        case "Denied":
          return "fas text-danger fa-refresh mr-2";

        default:
          return "fas text-warning fa-refresh mr-2";
      }
    },

    getStatusText(status) {
      switch (status) {
        case "Accepted":
          if (
            this.medicineDetails.find(
              (e) =>
                e.dspStatusId === 0 ||
                e.dspStatusId === 1 ||
                !e.isRefillInitiated
            )
          ) {
            this.isPharmacyVerified = false;
            return "Waiting for Pharmacy Confirmation";
          }

          this.isPharmacyVerified = true;
          return "Pharmacy Verified";

        case "Cancelled":
          return this.medicineDetails.filter(
            (i) => i.dspCancellationStatus == "In Process"
          ).length > 0
            ? "Cancellation In Process"
            : "Cancelled";

        case "Error":
          return "Error / Cancelled";

        default:
          return status;
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}
</style>

