// exports.BASE_URL = "http://172.20.10.2:3000";
// exports.SOCKET_URL = 'http://172.20.10.2:3000'

// exports.BASE_URL = 'https://beta.stepaheadhealth.com/server'
// exports.SOCKET_URL = 'https://beta.stepaheadhealth.com'

exports.BASE_URL = 'https://stepaheadhealth.com/server'
exports.SOCKET_URL = 'https://stepaheadhealth.com'

exports.HTTP_API = {
  // Common Routes
  session: "/common/auth/session",
  validateLoginDevice: "/common/auth/validate-device",
  login: "/common/auth/login",
  logout: "/common/auth/logout",
  updateUserProfilePhoto: "/patient/user/updateprofileImage",
  completeRegistration: "/provider/user/complete-registration",
  userDetails: "/common/user",
  sendResetPasswordLink: "/common/auth/forgot-password",
  resetPassword: "/common/auth/reset-password",

  // RX Helper
  pharmacySearch: "/rxhelper/pharmacy/search",
  medicineSearch: "/rxhelper/medication/search",
  dispenseUnit: "/rxhelper/medication/units",

  // Patient Routers
  patientRegister: "/patient/auth/register",
  patientExists: "/patient/auth/exists",
  patientUpdateUser: "/patient/user/updateprofile",
  patientChangePassword: "/patient/user/changepassword",
  patientCreatePrescription: "/patient/services/new-prescription",
  patientCancelPrescription: "/patient/services/cancel-prescription",
  patientCreateFreePrescription: "/patient/services/new-free-prescription",
  patientGetPrescriptions: "/patient/services/prescriptions",
  patientConfirmPrescription: "/patient/services/confirm-prescription",
  patientConfirmProviderPayment: "/patient/services/confirm-provider-payment",
  patientValidatePrescription: "/patient/services/validate",
  patientValidateProviderPayment: "/patient/services/payment",
  patientGetTransactionDetails: "/patient/services/transaction-details",
  patientDeactivateAccount: "/patient/services/deactivate",

  // Provider Routers
  providerRegister: "/provider/auth/register",
  providerExists: "/provider/auth/exists",
  providerLogout: "/provider/auth/logout",
  providerUpdateUser: "/provider/user/updateprofile",
  providerUpdateUserProfilePhoto: "/provider/user/updateprofileImage",
  providerChangePassword: "/provider/user/changepassword",
  providerGetPrescriptions: "/provider/services/prescriptions",
  providerGetIdpStatus: "/provider/services/idp/",
  providerGetAgreements: "/provider/services/agreements/",
  providerGetDisclaimer: "/provider/services/disclaimer/",
  providerAcceptAgreement: "/provider/services/agreement/accept/",
  providerAcceptDisclaimer: "/provider/services/disclaimer/accept/",
  providerAddIdpDetails: "/provider/services/idp/",
  providerAddIdpAnswers: "/provider/services/idp/answers/",
  providerSearchPatient: "/provider/services/search-patient",
  providerGetPrescriptionById: "/provider/services/prescription",
  providerAcceptPrescriptions: "/provider/services/prescriptions/accept",
  providerRejectPrescriptions: "/provider/services/prescriptions/reject",
  providerApproveSingleRefill: "/provider/services/prescriptions/refill/approve",
  providerDenySingleRefill: "/provider/services/prescriptions/refill/deny",
  providerApproveAllRefills: "/provider/services/prescriptions/refill/approve-all",
  providerDenyAllRefills: "/provider/services/prescriptions/refill/deny-all",
  providerSavePrescription: "/provider/services/prescriptions/save/",
  providerGetRxChangeRequest: "/provider/services/prescription/rxchange/",
  providerApproveRxChangeRequest:
    "/provider/services/prescription/rxchange/approve/",
  providerDenyRxChangeRequest: "/provider/services/prescription/rxchange/deny/",
  providerPrintPrescription: "/provider/services/prescriptions/print",
  providerReconcilePrescription: "/provider/services/prescription/rxchange/reconcile",
  providerGetFinanceOverview: "/provider/services/finance/overview/",
  providerSendWithdrawRequest: "/provider/services/finance/withdraw/",
  providerCancelWithdrawRequest: "/provider/services/finance/withdraw/cancel",
  providerReferByEmail: '/provider/services/refer/email'
};
