<template>
  <div class="main-wrapper list-holder">
    <div class="profile-sidebar" v-if="user">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <a href="javascript:void();" class="booking-doc-img">
            <img :src="profileImage" :alt="user.first_name" />
          </a>
          <div class="profile-det-info">
            <h3>
              {{ formatLongString(user.first_name) }}
              {{ formatLongString(user.last_name) }}
            </h3>
            <div class="patient-details">
              <h5><i class="fas fa-birthday-cake"></i> {{ dob }}</h5>
              <h5 class="mb-0">
                <i class="fas fa-map-marker-alt"></i> {{ user.city }},
                {{ user.state }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-widget">
        <nav class="dashboard-menu">
          <ul>
            <li class="active">
              <a href="javascript:void();">
                <i class="fas fa-key"></i>
                <span
                  >{{ $t("accountSetting.accountId") }}: {{ user.id }}-{{
                    user.dsp_user_id
                  }}</span
                >
              </a>
            </li>
            <li>
              <router-link to="/patient/prescriptions/rxrequests">
                <i class="fas fa-file-prescription"></i>
                <span>{{ $t("accountSetting.myPrescriptionRefills") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/patient/payments">
                <i class="fas fa-receipt"></i>
                <span>{{ $t("accountSetting.paymentHistory") }}</span>
              </router-link>
            </li>
            <li
              :class="
                currentPath == 'patient-profile-settings'
                  ? 'active'
                  : 'notaactive'
              "
            >
              <router-link to="/patient/profile">
                <i class="fas fa-user-cog"></i>
                <span>{{ $t("accountSetting.profileSetting") }}</span>
              </router-link>
            </li>
            <li
              :class="
                currentPath == 'patient-change-pharmacy'
                  ? 'active'
                  : 'notaactive'
              "
            >
              <router-link to="/patient/change-pharmacy">
                <i class="fas fa-clinic-medical"></i>
                <span>{{ $t("accountSetting.changePharmacy") }}</span>
              </router-link>
            </li>
            <li
              :class="
                currentPath == 'patient-change-password'
                  ? 'active'
                  : 'notaactive'
              "
            >
              <router-link to="/patient/change-password">
                <i class="fas fa-lock"></i>
                <span>{{ $t("accountSetting.changePassword") }}</span>
              </router-link>
            </li>
            <li
              :class="
                currentPath == 'patient-deactivate-account'
                  ? 'active'
                  : 'notaactive'
              "
            >
              <router-link to="/patient/deactivate-account">
                <i class="fas fa-trash text-danger"></i>
                <span class="text-danger">{{
                  $t("accountSetting.deactivateAccount")
                }}</span>
              </router-link>
            </li>
            <!-- <li class="notaactive" @click="logout">
              <a href="javascript:void();">
                <i class="fas fa-sign-out-alt"></i>
                <span>Logout</span>
              </a>
            </li> -->
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const images = require.context("../../assets/img", false, /\.png$|\.jpg$/);

export default {
  methods: {
    ...mapActions(["logOut"]),
    formatLongString(str) {
      let s = String(str);
      return s.length > 10 ? s.substring(0, 10) + "..." : s;
    },

    async logout() {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    profileImage() {
      return this.user && this.user.profile_image_url
        ? this.user.profile_image_url
        : images("./blank.png").default;
    },
    user() {
      return this.$store.getters.user;
    },
    dob() {
      const dob = this.$store.getters.user.dob.split("/");
      let month = "";
      if (dob[1] == 1) month = "Jan";
      if (dob[1] == 2) month = "Feb";
      if (dob[1] == 3) month = "Mar";
      if (dob[1] == 4) month = "Apr";
      if (dob[1] == 5) month = "May";
      if (dob[1] == 6) month = "Jun";
      if (dob[1] == 7) month = "Jul";
      if (dob[1] == 8) month = "Aug";
      if (dob[1] == 9) month = "Sep";
      if (dob[1] == 10) month = "Oct";
      if (dob[1] == 11) month = "Nov";
      if (dob[1] == 12) month = "Dec";

      const age = new Date().getFullYear() - parseInt(dob[0]);
      // return `${dob[0]} ${month} ${dob[2]}, ${age - 1} Years`;
      return `${dob[0]} ${month} ${dob[2]}`;
    },
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
<style>
.list-holder {
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-x: hidden;
}
</style>
