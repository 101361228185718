<template>
  <!-- Process Sections-->
  <section class="facility-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 m-auto">
          <div class="section-header text-center">
            <h2>{{ $t("aboutUs.howItWorksSection.title") }}</h2>
            <p class="sub-title">
              {{ $t("aboutUs.howItWorksSection.subTitle") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <img
            src="../assets/img/process.png"
            width="550"
            alt=""
            class="operat-img"
          />
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="visit-doctor">
            <div class="inner-details">
              <div class="count">
                <h1>01</h1>
              </div>
              <div class="info">
                <h1>{{ $t("aboutUs.howItWorksSection.step1Title") }}</h1>
                <p>
                  {{ $t("aboutUs.howItWorksSection.step1Description") }}
                </p>
                <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
              </div>
              <img src="../assets/img/icons/visit-01.png" alt="" />
            </div>
          </div>
          <div class="visit-doctor">
            <div class="inner-details pharmacy">
              <div class="count">
                <h1>02</h1>
              </div>
              <div class="info">
                <h1>{{ $t("aboutUs.howItWorksSection.step2Title") }}</h1>
                <p>
                  {{ $t("aboutUs.howItWorksSection.step2Description") }}
                </p>
                <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
              </div>
              <img src="../assets/img/icons/visit-02.png" alt="" />
            </div>
          </div>
          <div class="visit-doctor">
            <div class="inner-details lab">
              <div class="count">
                <h1>03</h1>
              </div>
              <div class="info">
                <h1>{{ $t("aboutUs.howItWorksSection.step3Title") }}</h1>
                <p>
                  {{ $t("aboutUs.howItWorksSection.step3Description") }}
                </p>
                <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
              </div>
              <img src="../assets/img/icons/visit-03.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Process Sections-->
</template>
