import { createApp } from "vue";
import { router } from "./router";
import axios from "axios";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fontawesome/css/fontawesome.min.css";
import "./assets/fontawesome/css/all.min.css";

import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.min.css";

import "core-js/stable";
import "regenerator-runtime/runtime";

import App from "./App.vue";
import Alert from "./components/Alert";
import AlertModal from "./components/layouts/AlertModal";
import LayoutHeader from "./components/layouts/Header";
import PatientFooter from "./components/layouts/patient/Footer";
import ProviderFooter from "./components/layouts/provider/Footer";
import PatientSidebar from "./components/layouts/patient/SideBar";
import ProviderSidebar from "./components/layouts/provider/SideBar";
import HomeBanner from "./components/HomeBanner";
import Process from "./components/Process";
import Looking from "./components/Looking";
import Specialities from "./components/Specialities";
import BrowseSpecialities from "./components/BrowseSpecialities";
import BookDoctor from "./components/BookDoctor";
import BestDoctor from "./components/BestDoctor";
import Loader from "./components/Loader";
import Features from "./components/Features";
import FaqHome from "./components/FaqHome";
import SearchFilter from "./components/patient/SearchFilter";
import config from "./constants/config";

/** Breadcrumbs **/
import BreadCrumb from "./components/breadcrumb/Dashboard";
import BreadCrumb1 from "./components/breadcrumb/Appointments";
import BreadCrumb2 from "./components/breadcrumb/MyPatients";
import BreadCrumb3 from "./components/breadcrumb/ScheduleTimings";
import BreadCrumb4 from "./components/breadcrumb/Invoices";
import BreadCrumb5 from "./components/breadcrumb/Reviews";
import BreadCrumb6 from "./components/breadcrumb/ProfileSettings";
import BreadCrumb7 from "./components/breadcrumb/SocialMedia";
import BreadCrumb8 from "./components/breadcrumb/ChangePassword";
import BreadCrumb9 from "./components/breadcrumb/DoctorProfile";
import BreadCrumb10 from "./components/breadcrumb/SearchDoctor";
import BreadCrumb11 from "./components/breadcrumb/DoctorBlog";
import BreadCrumb12 from "./components/breadcrumb/AddBlog";
import BreadCrumb13 from "./components/breadcrumb/BlogDetails";
import BreadCrumb14 from "./components/breadcrumb/BlogList";
import BreadCrumb15 from "./components/breadcrumb/BlogGrid";
import BreadCrumb16 from "./components/breadcrumb/Booking";
import BreadCrumb17 from "./components/breadcrumb/Checkout";
import BreadCrumb18 from "./components/breadcrumb/Favourites";
import BreadCrumb19 from "./components/breadcrumb/AddDependent";
import BreadCrumb20 from "./components/breadcrumb/Pharmacy";
import BreadCrumb21 from "./components/breadcrumb/PharmacyDetails";
import BreadCrumb22 from "./components/breadcrumb/PharmacySearch";
import BreadCrumb23 from "./components/breadcrumb/Product";
import BreadCrumb24 from "./components/breadcrumb/ProductDescription";
import BreadCrumb25 from "./components/breadcrumb/Cart";
import BreadCrumb26 from "./components/breadcrumb/PaymentSuccess";
import BreadCrumb27 from "./components/breadcrumb/Calender";
import BreadCrumb28 from "./components/breadcrumb/Components";
import BreadCrumb29 from "./components/breadcrumb/InvoiceView";
import BreadCrumb30 from "./components/breadcrumb/Blank";
import BreadCrumb31 from "./components/breadcrumb/EditDependent";
import BreadCrumb32 from "./components/breadcrumb/Profile";
import BreadCrumb33 from "./components/breadcrumb/AddPrescription";
import BreadCrumb34 from "./components/breadcrumb/AddBilling";
import BreadCrumb35 from "./components/breadcrumb/EditPrescription";
import BreadCrumb36 from "./components/breadcrumb/EditBilling";
import BreadCrumb37 from "./components/breadcrumb/EditBlog";
import BreadCrumb38 from "./components/breadcrumb/TermCondition";
import BreadCrumb39 from "./components/breadcrumb/PrivacyPolicy";
import BreadCrumb40 from "./components/breadcrumb/Faqheader";
import BreadCrumb41 from "./components/breadcrumb/Aboutusheader";
import BreadCrumb42 from "./components/breadcrumb/ContactUsHeader";
import BreadCrumb43 from "./components/breadcrumb/Settings";
import SocketIO from "socket.io-client";
import VueSocketIO from "vue-3-socket.io";
import VueSweetalert2 from "vue-sweetalert2";
// import PullToRefresh from "pulltorefreshjs";
import Clipboard from 'v-clipboard'
import jquery from "jquery";
window.$ = jquery;

// const ptr = PullToRefresh.init({
//   mainElement: "body",
//   instructionsPullToRefresh: '',
//   distThreshold: 100,
//   distMax: 120,
//   distReload: 90,
//   onRefresh () {
//     localStorage.setItem('force-reload', true)
//     window.location.reload()
//   },
// });

if (window.location.href.includes("template/pharmacyadmin")) {
  require("./assets/plugins/select2/css/select2.min.css");
  require("./assets/plugins/select2/js/select2.min.js");
  require("./assets/css/bootstrap-datetimepicker.min.css");
  require("./assets/js/bootstrap-datetimepicker.min.js");
  require("./assets/PharmacyAdminStyle.css");
  require("./assets/css/bootstrap4.min.css");
  require("./assets/css/dataTables.bootstrap4.min.css");
  require("./assets/css/jquery.dataTables.min.js");
  // require('./assets/css/dataTables.bootstrap4.min.js')
} else if (window.location.href.includes("template/admin")) {
  require("./assets/plugins/select2/css/select2.min.css");
  require("./assets/plugins/select2/js/select2.min.js");
  require("./assets/css/bootstrap-datetimepicker.min.css");
  require("./assets/js/bootstrap-datetimepicker.min.js");
  require("./assets/AdminStyle.css");
  require("./assets/css/bootstrap4.min.css");
  require("./assets/css/dataTables.bootstrap4.min.css");
  require("./assets/css/jquery.dataTables.min.js");
  // require('./assets/css/dataTables.bootstrap4.min.js')
} else if (
  !window.location.href.includes("template/admin") &&
  !window.location.href.includes("template/pharmacyadmin")
) {
  require("./assets/js/jquery.min.js");
  require("./assets/plugins/select2/css/select2.min.css");
  require("./assets/plugins/select2/js/select2.min.js");
  require("./assets/css/bootstrap-datetimepicker.min.css");
  require("./assets/css/owl.carousel.min.css");
  require("./assets/js/owl.carousel.min.js");
  require("./assets/js/slick.js");
  require("./assets/plugins/apex/apexcharts.css");
  require("./assets/js/bootstrap-datetimepicker.min.js");
  require("./assets/js/circle-progress.min.js");
  require("./assets/plugins/dropzone/dropzone.min.css");
  require("./assets/plugins/dropzone/dropzone.min.js");
  require("./assets/plugins/fancybox/jquery.fancybox.min.css");
  require("./assets/plugins/fancybox/jquery.fancybox.min.js");
  require("./assets/plugins/daterangepicker/daterangepicker.css");
  require("./assets/plugins/daterangepicker/daterangepicker.js");
  require("./assets/plugins/bootstrap-tagsinput/css/bootstrap-tagsinput.css");
  require("./assets/plugins/bootstrap-tagsinput/js/bootstrap-tagsinput.js");
  require("./assets/plugins/fullcalendar/fullcalendar.min.css");
  require("./assets/plugins/jquery-ui/jquery-ui.min.js");
  require("./assets/plugins/apex/apexcharts.min.js");
  require("./assets/plugins/fullcalendar/fullcalendar.min.js");
  require("./assets/plugins/swiper/css/swiper.min.css");
  require("./assets/plugins/swiper/js/swiper.min.js");
  require("./assets/style.css");
}

import Raphael from "raphael/raphael";
import i18n from './i18n'

global.Raphael = Raphael;
const app = createApp(App).use(i18n);

/*Global Components */
app.component("vue-select", VueSelect);
app.component("alert", Alert);
app.component("alert-modal", AlertModal);
app.component("layout-header", LayoutHeader);
app.component("patient-sidebar", PatientSidebar);
app.component("provider-sidebar", ProviderSidebar);
app.component("patient-footer", PatientFooter);
app.component("provider-footer", ProviderFooter);
app.component("home-banner", HomeBanner);
app.component("process", Process);
app.component("looking", Looking);
app.component("specialities", Specialities);
app.component("browse-specialities", BrowseSpecialities);
app.component("book-doctor", BookDoctor);
app.component("best-doctor", BestDoctor);
app.component("features", Features);
app.component("FaqHome", FaqHome);
app.component("search-filter", SearchFilter);
app.component("loader", Loader);

/* BreadCrumbs */
app.component("breadcrumb", BreadCrumb);
app.component("breadcrumb1", BreadCrumb1);
app.component("breadcrumb2", BreadCrumb2);
app.component("breadcrumb3", BreadCrumb3);
app.component("breadcrumb4", BreadCrumb4);
app.component("breadcrumb5", BreadCrumb5);
app.component("breadcrumb6", BreadCrumb6);
app.component("breadcrumb7", BreadCrumb7);
app.component("breadcrumb8", BreadCrumb8);
app.component("breadcrumb9", BreadCrumb9);
app.component("breadcrumb10", BreadCrumb10);
app.component("breadcrumb11", BreadCrumb11);
app.component("breadcrumb12", BreadCrumb12);
app.component("breadcrumb13", BreadCrumb13);
app.component("breadcrumb14", BreadCrumb14);
app.component("breadcrumb15", BreadCrumb15);
app.component("breadcrumb16", BreadCrumb16);
app.component("breadcrumb17", BreadCrumb17);
app.component("breadcrumb18", BreadCrumb18);
app.component("breadcrumb19", BreadCrumb19);
app.component("breadcrumb20", BreadCrumb20);
app.component("breadcrumb21", BreadCrumb21);
app.component("breadcrumb22", BreadCrumb22);
app.component("breadcrumb23", BreadCrumb23);
app.component("breadcrumb24", BreadCrumb24);
app.component("breadcrumb25", BreadCrumb25);
app.component("breadcrumb26", BreadCrumb26);
app.component("breadcrumb27", BreadCrumb27);
app.component("breadcrumb28", BreadCrumb28);
app.component("breadcrumb29", BreadCrumb29);
app.component("breadcrumb30", BreadCrumb30);
app.component("breadcrumb31", BreadCrumb31);
app.component("breadcrumb32", BreadCrumb32);
app.component("breadcrumb33", BreadCrumb33);
app.component("breadcrumb34", BreadCrumb34);
app.component("breadcrumb35", BreadCrumb35);
app.component("breadcrumb36", BreadCrumb36);
app.component("breadcrumb37", BreadCrumb37);
app.component("breadcrumb38", BreadCrumb38);
app.component("breadcrumb39", BreadCrumb39);
app.component("breadcrumb40", BreadCrumb40);
app.component("breadcrumb41", BreadCrumb41);
app.component("breadcrumb42", BreadCrumb42);
app.component("breadcrumb43", BreadCrumb43);

async function authCheck (routePath) {
  try {
    await store.dispatch("validateSession");

    switch (store.getters.user.role) {
      case "patient":
        let routePath1 = `/patient/step-${store.getters.user.current_registration_step}`;
        if (
          !routePath.includes("/patient/step-") &&
          !store.getters.user.is_registration_complete
        ) {
          router.push(routePath1);
        }
        break;

      case "provider":
        let routePath2 = `/provider/step-${store.getters.user.current_registration_step}`;
        if (
          !routePath.includes("/provider/step-") &&
          !store.getters.user.is_registration_complete
        ) {
          router.push(routePath2);
        }
        break;
    }
  } catch (e) {
    console.error("Session expired");
    store.dispatch('removeStoreUser')
    router.push("/login");
  }
}

// Set page settings and title
router.beforeEach(async (to, from, next) => {
  // Session Validation
  if (to.meta.auth) {
    authCheck(to.path)
  }

  // Restrict roles to switch dashboards
  if (
    to.path.includes("provider") &&
    store.getters.user &&
    store.getters.user.role == "patient"
  ) {
    return next(false);
  } else if (
    to.path.includes("patient") &&
    store.getters.user &&
    store.getters.user.role == "provider"
  ) {
    return next(false);
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// Set auth token in headers
axios.interceptors.request.use(
  (req) => {
    let token = localStorage.getItem("user-token");
    if (token) req.headers["Authorization"] = `Bearer ${token}`;
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle error responses
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Redirect to login if unauthorized
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user-token");
      store.commit("setUser", null);
      router.push("/login");
    }
    return Promise.reject(error); // this is the important part
  }
);

app
  .use(router)
  .use(store)
  .use(
    new VueSocketIO({
      debug: false,
      connection: SocketIO(config.SOCKET_URL, { transports: ["websocket"] }),
      vuex: {
        store,
        actionPrefix: "SOCKET_",
      },
    })
  )
  .use(VueSweetalert2)
  .use(Clipboard)
  .mount("#app");
