<template>
  <div class="main-wrapper">
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4>{{ $t("changePassword.title") }}</h4>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <!-- Change Password Form -->
                    <form @submit.prevent="changePassword">
                      <div class="form-group">
                        <label>{{
                          $t("changePassword.oldPasswordLabel")
                        }}</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="oldPassword"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label>{{
                          $t("changePassword.newPasswordLabel")
                        }}</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="newPassword"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label>{{
                          $t("changePassword.confirmPasswordLabel")
                        }}</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="repeatPassword"
                          required
                        />
                      </div>
                      <hr />
                      <div class="submit-section">
                        <button
                          type="submit"
                          class="btn btn-sm btn-primary submit-btn btn-block"
                        >
                          <i class="fas fa-key mr-2"></i>
                          {{ $t("changePassword.button") }}
                        </button>
                      </div>
                    </form>
                    <!-- /Change Password Form -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    };
  },
  methods: {
    ...mapActions(["changeUserPassword", "showLoader"]),
    async changePassword() {
      try {
        if (this.newPassword !== this.repeatPassword) {
          Swal.fire({
            icon: "warning",
            title: `Passwords do not match`,
            showConfirmButton: false,
            timer: 2000,
          });
          return;
        }

        this.showLoader(true);

        await this.changeUserPassword({
          id: this.$store.getters.user.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          role: this.$store.getters.user.role,
        });

        Swal.fire({
          icon: "success",
          title: `Well Done!`,
          text: `Password Changed!`,
          showConfirmButton: false,
          timer: 2000,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";

        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";
        this.showLoader(false);
      }
    },
  },
};
</script>
