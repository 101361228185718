<template>
  <div
    class="modal fade"
    id="alert-modal"
    style="display: none;"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="exampleModalLongTitle"
          >
            <span
              v-if="alertType === 'danger'"
              class="text-danger"
            >Uh Oh!</span>
            <span
              v-if="alertType === 'success'"
              class="text-success"
            >Yay, Great!</span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ alertMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  watch: {
    alertState: function(state) {
      state ? $("#alert-modal").modal("show") : $("#alert-modal").modal("hide");
    }
  },
  computed: {
    alertState() {
      return this.$store.getters.alert.state;
    },
    alertMessage() {
      return this.$store.getters.alert.message;
    },
    alertType() {
      return this.$store.getters.alert.type;
    }
  }
};
</script>