<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/provider/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-3" class="active-done"
                            >3</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-4" class="active-done"
                            >4</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">5</a>
                        </li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                        <li><a href="javascript:void();">9</a></li>
                      </ul>
                    </div>

                    <form @submit.prevent="saveStep">
                      <h3 class="my-4">Collaborating Physician Information</h3>

                      <div class="row">
                        <div class="col-6">
                          <div class="form-group text-left">
                            <label>Physician First Name</label>
                            <input
                              type="text"
                              name="firstname"
                              v-model="firstName"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group text-left">
                            <label>Physician Last Name</label>
                            <input
                              type="text"
                              name="lastname"
                              v-model="lastName"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group text-left">
                        <label>Physician Email Address</label>
                        <input
                          type="text"
                          name="email"
                          v-model="email"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group text-left">
                            <label>Physician NPI Number</label>
                            <input
                              type="text"
                              name="npiId"
                              v-model="npiId"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group text-left">
                            <label>Degree Type</label>
                            <select
                              class="form-control select"
                              aria-hidden="true"
                              v-model="degreeType"
                              required
                            >
                              <option value="MD">MD</option>
                              <option value="DO">DO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <button
                              type="submit"
                              class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              "
                            >
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/provider/step-4">
                              <a
                                href="javascript:void()"
                                class="btn btn-light btn-block"
                              >
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2024. StepAhead Health Inc. </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";
export default {
  mixins: [common],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      npiId: "",
      degreeType: "",
    };
  },
  async mounted() {
    this.showLoader(true);

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader"]),

    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          collaborative_physician: {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            npi: this.npiId,
            degree_type: this.degreeType,
          },
          current_registration_step: 6,
        });

        this.$router.push("/provider/step-6");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
