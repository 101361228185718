<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0 m-0">
          <div class="banner-wrapper">
            <div class="mb-3 text-center">
              <h4>Refill Information</h4>
            </div>
            <form @submit.prevent="confirmProceed">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <!-- MEDICATION LIST -->
                      <div
                        class="mb-4"
                        v-for="(med, i) in selectedMedicines"
                        :key="i"
                      >
                        <div class="w-100 mb-3">
                          <h6>{{ med.name }}</h6>
                        </div>
                        <div class="row">
                          <label class="col-4" style="font-size: 11px"
                            >Quantity:
                          </label>
                          <input
                            type="number"
                            class="
                              form-control form-control-sm
                              mb-1
                              col-8
                              form-control-xs
                            "
                            min="1"
                            max="5000"
                            step="any"
                            @change="
                              quanityCorrection(
                                selectedMedicines,
                                med.quantity,
                                i
                              )
                            "
                            v-model="med.quantity"
                            required
                          />
                        </div>

                        <div class="row">
                          <label class="col-4" style="font-size: 11px"
                            >Unit:
                          </label>
                          <select
                            class="
                              form-control form-control-sm
                              mb-1
                              col-8
                              form-control-xs
                            "
                            aria-hidden="true"
                            v-model="med.unit_id"
                            required
                          >
                            <option
                              v-for="(unit, i) in units"
                              :key="i"
                              :value="unit.StandardDispenseUnitTypeID"
                            >
                              {{ unit.Plural }} ({{ unit.Abbreviation }})
                            </option>
                          </select>
                        </div>

                        <div class="row">
                          <label class="col-4" style="font-size: 11px"
                            >Refills:
                          </label>
                          <input
                            type="number"
                            class="
                              form-control form-control-sm
                              mb-1
                              col-8
                              form-control-xs
                            "
                            min="0"
                            max="90"
                            v-model="med.refills"
                            required
                          />
                        </div>

                        <div class="row">
                          <label class="col-4" style="font-size: 11px"
                            >Supply Days:
                          </label>
                          <input
                            type="number"
                            class="
                              form-control form-control-sm
                              mb-1
                              col-8
                              form-control-xs
                            "
                            min="0"
                            max="999"
                            v-model="med.supply_days"
                            required
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div
                            class="
                              remember-me-col
                              d-flex
                              justify-content-between
                              mt-3
                            "
                          >
                            <span style="font-size: 11px"
                              ><strong
                                >Allow Substitutions from the Pharmacy</strong
                              ></span
                            >
                            <label class="custom_check">
                              <input
                                v-model="allowSubstitutions"
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-sm btn-info btn-block mb-2">
                Confirm & Proceed <i class="fa fa-arrow-right"></i>
              </button>
              <router-link to="/patient/index">
                <button class="btn btn-sm btn-outline-danger btn-block mb-4">
                  <i class="fa fa-close"></i> Cancel
                </button>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
export default {
  mixins: [commonMixin],
  props: {
    selectedMedicines: Array,
  },
  data() {
    return {
      desiredQuantity: "",

      allowSubstitutions: true,
      units: "",
    };
  },
  async mounted() {
    this.showLoader(true);
    const res = await this.getDispenseUnits();
    this.units = res
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    quanityCorrection(selectedMedicines, quantity, medIndex) {
      let selectedMedicine = selectedMedicines[medIndex];
      // If all zeros in decimal, change the quantity to whole number
      if (quantity % 1 === 0) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity = Math.round(quantity);
      }

      // Populate zero in first place if input is .123
      if (String(quantity).startsWith("0.")) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity = quantity;
      }

      if (String(quantity).length > 10 && String(quantity).indexOf(".") >= 0) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity =
          String(quantity).substr(0, String(quantity).indexOf(".")) +
          String(quantity).substr(String(quantity).indexOf("."), 6);
      }

      // if (quantity > 5000) selectedMedicine.quantity = 5000;
    },
    confirmProceed() {
      if (this.reasonForMedication === "") {
        alert("Reason for medication is strictly required");
        return;
      }

      this.$emit(
        "select",
        JSON.stringify({
          selectedMedicines: this.selectedMedicines,
          allowSubstitutions: this.allowSubstitutions,
        })
      );
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 400px;
  width: 450px;
}

@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}
</style>
