<template>
  <div>
    <div class="main-wrapper">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card rounded">
                <div class="card-header">
                  <h4>Transaction Details</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-5">Transaction ID</div>
                    <div class="col-7">{{ txnDetails.txn_id }}</div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-5">Request ID</div>
                    <div class="col-7">{{ txnDetails.prescription_id }}</div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-5">Amount Paid</div>
                    <div class="col-7">${{ parseFloat(txnDetails.amount) + processingAmount }} USD</div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-5">Status</div>
                    <div class="col-7">{{ txnDetails.status }}</div>
                  </div>
                  <hr />
                  <div class="row" v-if="txnDetails.provider">
                    <div class="col-5">Provider Name</div>
                    <div class="col-7">
                      {{ txnDetails.provider.first_name }}
                      {{ txnDetails.provider.last_name }}
                    </div>
                  </div>
                  <hr v-if="txnDetails.provider" />
                  <div class="row">
                    <div class="col-5">Payment Method</div>
                    <div class="col-7">{{ txnDetails.payment_method }}</div>
                  </div>
                  <hr />
                  <div class="row" v-if="txnDetails.provider">
                    <div class="col-5">
                      PayPal Email <br />
                      (Provider)
                    </div>
                    <div class="col-7">
                      {{ txnDetails.provider.paypal_email }}
                    </div>
                  </div>
                  <hr v-if="txnDetails.provider" />
                  <div class="row">
                    <div class="col-5">Date/Time</div>
                    <div class="col-7">
                      {{ new Date(txnDetails.created_at).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <router-link to="/patient/payments">
                <button class="btn bg-primary-light btn-sm btn-block">
                  <i class="fa fa-arrow-left"></i> Back to payments
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: {
    txnId: String,
  },
  data () {
    return {
      processingAmount: 0,
      txnDetails: {
        provider: {},
      },
    };
  },
  methods: {
    ...mapActions(["showLoader"]),
  },
  async mounted () {
    try {
      this.showLoader(true);
      const res = await axios.get(
        `${config.BASE_URL}${config.HTTP_API.patientGetTransactionDetails}/${this.txnId}`
      );

      this.txnDetails = res.data;
      this.showLoader(false);
    } catch (e) {
      this.showLoader(false);
      Swal.fire(
        "Ooops!",
        "Error occured while fetching the details. Please try again later.",
        "error"
      ).then(() => {
        this.$router.push("/patient/payments");
      });
    }
  },
};
</script>