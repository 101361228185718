<template>
  <!-- Clinic and Specialities -->
  <section class="section section-specialities">
    <div class="container-fluid">
      <div class="section-header text-center">
        <h2>Top Searched Specialities</h2>
        <!-- <p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8">
          <!-- Slider -->
          <div class="specialities-slider slider">

            <!-- Slider Item -->
            <div class="speicality-item text-center">
              <div class="speicality-img">
                <img
                  src="../assets/img/specialities/specialities-01.png"
                  class="img-fluid"
                  alt="Speciality"
                >
                <span><i
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i></span>
              </div>
              <p>Urology</p>
            </div>
            <!-- /Slider Item -->

            <!-- Slider Item -->
            <div class="speicality-item text-center">
              <div class="speicality-img">
                <img
                  src="../assets/img/specialities/specialities-02.png"
                  class="img-fluid"
                  alt="Speciality"
                >
                <span><i
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i></span>
              </div>
              <p>Neurology</p>
            </div>
            <!-- /Slider Item -->

            <!-- Slider Item -->
            <div class="speicality-item text-center">
              <div class="speicality-img">
                <img
                  src="../assets/img/specialities/specialities-03.png"
                  class="img-fluid"
                  alt="Speciality"
                >
                <span><i
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i></span>
              </div>
              <p>Orthopedic</p>
            </div>
            <!-- /Slider Item -->

            <!-- Slider Item -->
            <div class="speicality-item text-center">
              <div class="speicality-img">
                <img
                  src="../assets/img/specialities/specialities-04.png"
                  class="img-fluid"
                  alt="Speciality"
                >
                <span><i
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i></span>
              </div>
              <p>Cardiologist</p>
            </div>
            <!-- /Slider Item -->

            <!-- Slider Item -->
            <div class="speicality-item text-center">
              <div class="speicality-img">
                <img
                  src="../assets/img/specialities/specialities-05.png"
                  class="img-fluid"
                  alt="Speciality"
                >
                <span><i
                    class="fa fa-circle"
                    aria-hidden="true"
                  ></i></span>
              </div>
              <p>Dentist</p>
            </div>
            <!-- /Slider Item -->

          </div>
          <!-- /Slider -->

        </div>
      </div>
    </div>
  </section>
  <!-- Clinic and Specialities -->
</template>
<script>
const images = require.context(
  "../assets/img/specialities",
  false,
  /\.png$|\.jpg$/
);
export default {
  mounted() {
    // Slick Slider

    if ($(".specialities-slider").length > 0) {
      $(".specialities-slider").slick({
        dots: false,
        autoplay: false,
        infinite: false,
        variableWidth: true,
        prevArrow: false,
        nextArrow: false
      });
    }
  },
  methods: {
    loadImg(imgPath) {
      return images("./" + imgPath).default;
    }
  }
};
</script>