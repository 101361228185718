<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <!-- Breadcrumb -->
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 col-12">
            <h2 class="breadcrumb-title">
              {{ $t("termsAndConditions.title") }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-5">
            <div class="terms-content">
              <div class="terms-text">
                <h3>{{ $t("termsAndConditions.termsOfUse.title") }}</h3>
                <p style="font-size: 12px">
                  {{ $t("termsAndConditions.termsOfUse.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h4>{{ $t("termsAndConditions.services.title") }}</h4>
                <p style="font-size: 12px">
                  {{ $t("termsAndConditions.services.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h3>{{ $t("termsAndConditions.license.title") }}</h3>
                <p style="font-size: 12px">
                  {{ $t("termsAndConditions.license.description") }}
                </p>
              </div>
              <div class="terms-text">
                <h3>{{ $t("termsAndConditions.restrictions.title") }}</h3>
                <p style="font-size: 12px">
                  {{ $t("termsAndConditions.restrictions.heading") }}
                </p>
                <ul>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point1") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point2") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point3") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point4") }}
                  </li>
                  <li>{{ $t("termsAndConditions.restrictions.point5") }}</li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point6") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point7") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point8") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point9") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point10") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point11") }}
                  </li>
                  <li>
                    {{ $t("termsAndConditions.restrictions.point12") }}
                  </li>
                </ul>
              </div>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.restrictions.description1") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.restrictions.description2") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.restrictions.description3") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.accountRestrictions.title") }}
              </h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.accountRestrictions.heading") }}
              </p>
              <ul>
                <li>
                  {{ $t("termsAndConditions.accountRestrictions.point1") }}
                </li>
                <li>
                  {{ $t("termsAndConditions.accountRestrictions.point2") }}
                </li>
                <li>
                  {{ $t("termsAndConditions.accountRestrictions.point3") }}
                </li>
                <li>
                  {{ $t("termsAndConditions.accountRestrictions.point4") }}
                </li>
              </ul>
            </div>
            <p style="font-size: 12px">
              {{ $t("termsAndConditions.accountRestrictions.description") }}
            </p>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.termAndTermination.title") }}
              </h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.termAndTermination.description") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>{{ $t("termsAndConditions.thirdPartyWebsites.title") }}</h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.thirdPartyWebsites.description1") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.thirdPartyWebsites.description2") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{
                  $t("termsAndConditions.cancellationOrChangeOfRequest.title")
                }}
              </h4>
              <p style="font-size: 12px">
                {{
                  $t(
                    "termsAndConditions.cancellationOrChangeOfRequest.description"
                  )
                }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.ownership.title") }}
              </h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.ownership.description1") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.ownership.description2") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>{{ $t("termsAndConditions.changesAndOperation.title") }}</h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.changesAndOperation.description") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>{{ $t("termsAndConditions.disclaimers.title") }}</h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.disclaimers.description1") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.disclaimers.description2") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.disclaimers.description3") }}
              </p>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.disclaimers.description4") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>{{ $t("termsAndConditions.indemnification.title") }}</h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.indemnification.description") }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.limitationOfLiability.title") }}
              </h4>
              <p style="font-size: 12px">
                {{
                  $t("termsAndConditions.limitationOfLiability.description1")
                }}
              </p>
              <p style="font-size: 12px">
                {{
                  $t("termsAndConditions.limitationOfLiability.description2")
                }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.businessAssociateAgreement.title") }}
              </h4>
              <p style="font-size: 12px">
                {{
                  $t(
                    "termsAndConditions.businessAssociateAgreement.description"
                  )
                }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.disputesArbitrationAndVenue.title") }}
              </h4>
              <p style="font-size: 12px">
                {{
                  $t(
                    "termsAndConditions.disputesArbitrationAndVenue.description1"
                  )
                }}
              </p>
              <p style="font-size: 12px">
                {{
                  $t(
                    "termsAndConditions.disputesArbitrationAndVenue.description2"
                  )
                }}
              </p>
              <p style="font-size: 12px">
                {{
                  $t(
                    "termsAndConditions.disputesArbitrationAndVenue.description3"
                  )
                }}
              </p>
              <p style="font-size: 12px">
                {{
                  $t(
                    "termsAndConditions.disputesArbitrationAndVenue.description4"
                  )
                }}
              </p>
            </div>
            <div class="terms-text">
              <h4>
                {{ $t("termsAndConditions.entireAgreementAmendment.title") }}
              </h4>
              <p style="font-size: 12px">
                {{
                  $t("termsAndConditions.entireAgreementAmendment.description")
                }}
              </p>
            </div>
            <div class="terms-text mb-5">
              <h4>
                {{ $t("termsAndConditions.general.title") }}
              </h4>
              <p style="font-size: 12px">
                {{ $t("termsAndConditions.general.description") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}
</style>
