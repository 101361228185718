<template>
  <div>
    <div class="main-wrapper">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <ul class="nav nav-pills nav-fill nav-custom">
                <li class="nav-item">
                  <a
                    id="free-btn"
                    :class="
                      `nav-link ${tabId === 'rxrequests' ? 'active' : ''}`
                    "
                    href="javascript:void();"
                    data-toggle="pill"
                    data-target="#rxrequests"
                    >{{ $t("prescription.pendingRequest") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="refill-btn"
                    :class="`nav-link ${tabId === 'rxrefills' ? 'active' : ''}`"
                    href="javascript:void();"
                    data-toggle="pill"
                    data-target="#rxrefills"
                    >{{ $t("prescription.approvedRequest") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <!-- SHOW RX REQUESTS -->
                <div
                  :class="
                    `tab-pane fade ${
                      tabId === 'rxrequests' ? 'show active' : ''
                    }`
                  "
                  id="rxrequests"
                  role="tabpanel"
                  aria-labelledby="rxrequests-tab"
                >
                  <div
                    v-if="pendingPrescriptions.length > 0"
                    class="list-holder"
                  >
                    <div
                      class="card shadow-sm bg-white rounded mb-3"
                      v-for="(prescription, i) in pendingPrescriptions"
                      :key="i"
                    >
                      <div class="card-body">
                        <div class="row">
                          <!-- ID and Date -->
                          <div class="col-7">
                            {{ $t("prescription.requestId") }}:
                            <strong>{{ prescription.id }}</strong>
                            <p class="mb-0 mt-2">
                              {{ $t("prescription.date") }}:
                              {{
                                new Date(prescription.created_at).toDateString()
                              }}
                            </p>
                          </div>

                          <!-- Status -->
                          <div class="col-5">
                            <p style="font-size: 10px" class="mb-0 mt-2">
                              <span
                                v-if="
                                  prescription.status === 'Accepted' &&
                                    prescription.is_payment_completed
                                "
                                :class="getStatusColor(prescription.status)"
                              >
                                <strong style="font-size: 10px">{{
                                  $t("prescription.approvalPending")
                                }}</strong>
                              </span>
                              <span
                                v-else
                                :class="getStatusColor(prescription.status)"
                              >
                                <strong style="font-size: 10px">{{
                                  pharmacyStatus(prescription.status)
                                }}</strong>
                              </span>
                            </p>
                          </div>
                        </div>
                        <hr class="mt-2" />
                        <!-- Buttons -->
                        <div class="row pt-0">
                          <div class="col-12">
                            <router-link
                              v-if="
                                prescription.status === 'Accepted' &&
                                  prescription.is_payment_completed == 0
                              "
                              :to="`/patient/billing/${prescription.id}`"
                            >
                              <a
                                href="javascript:void();"
                                class="btn btn-sm bg-primary-light btn-block mb-2"
                              >
                                <i class="fa fa-dollar mr-1"></i>
                                {{ $t("prescription.confirmPay") }}
                              </a>
                            </router-link>
                          </div>
                          <div
                            v-if="
                              prescription.status !== 'Cancelled' &&
                                prescription.status !== 'Completed'
                            "
                            class="col-6 pr-2"
                          >
                            <button
                              @click="cancelRequest(prescription.id)"
                              class="btn btn-sm bg-danger-light btn-block"
                              :disabled="
                                prescription.is_payment_completed == true ||
                                  prescription.status === 'Cancelled'
                              "
                            >
                              <i class="fa fa-times mr-1"></i>
                              {{ $t("prescription.cancelBtn") }}
                            </button>
                          </div>
                          <div
                            :class="
                              prescription.status === 'Cancelled' ||
                              prescription.status === 'Completed'
                                ? 'col-12 pl-2'
                                : 'col-6 pl-2'
                            "
                          >
                            <router-link
                              v-if="
                                !prescription.is_payment_completed &&
                                  prescription.status !== 'Cancelled'
                              "
                              :to="`/patient/billing/${prescription.id}`"
                            >
                              <a
                                href="javascript:void();"
                                class="btn btn-sm bg-primary-light mr-2 btn-block"
                              >
                                {{ $t("prescription.payFees") }}
                              </a>
                            </router-link>
                            <router-link
                              v-else
                              :to="`/patient/prescription/${prescription.id}`"
                            >
                              <a
                                href="javascript:void();"
                                class="btn btn-sm bg-info-light btn-block"
                              >
                                <i class="fa fa-file-prescription mr-1"></i>
                                {{ $t("prescription.viewDetails") }}
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>{{ $t("prescription.noPendingRequests") }}</h5>
                    <router-link to="/patient/new-prescription">
                      <button class="btn btn-sm btn-info mt-2">
                        {{ $t("prescription.createNewRequest") }}
                      </button>
                    </router-link>
                  </div>
                </div>

                <!-- SHOW REFILL REQUESTS -->
                <div
                  :class="
                    `tab-pane fade ${
                      tabId === 'rxrefills' ? 'show active' : ''
                    }`
                  "
                  id="rxrefills"
                  role="tabpanel"
                  aria-labelledby="rxrefills-tab"
                >
                  <div
                    v-if="approvedPrescriptions.length > 0"
                    class="list-holder"
                  >
                    <div
                      class="card shadow-sm bg-white rounded mb-3"
                      v-for="(prescription, i) in approvedPrescriptions"
                      :key="i"
                    >
                      <div class="card-body">
                        <div class="row">
                          <!-- ID and Date -->
                          <div class="col-7">
                            {{ $t("prescription.requestId") }}:
                            <strong>{{ prescription.id }}</strong>
                            <p class="mb-0 mt-2">
                              {{ $t("prescription.date") }}:
                              {{
                                new Date(prescription.created_at).toDateString()
                              }}
                            </p>
                          </div>

                          <!-- Status -->
                          <div class="col-5">
                            <p style="font-size: 10px" class="mb-0 mt-2">
                              <span
                                v-if="
                                  prescription.status === 'Accepted' &&
                                    prescription.is_payment_completed
                                "
                                :class="getStatusColor(prescription.status)"
                              >
                                <strong style="font-size: 10px">
                                  {{
                                    $t("prescription.approvalPending")
                                  }}</strong
                                >
                              </span>
                              <span
                                v-else
                                :class="getStatusColor(prescription.status)"
                              >
                                <strong style="font-size: 10px">{{
                                  pharmacyStatus(prescription.status)
                                }}</strong>
                              </span>
                            </p>
                          </div>
                        </div>
                        <hr class="mt-2" />
                        <!-- Button -->
                        <div class="row">
                          <div class="col-12">
                            <router-link
                              v-if="
                                prescription.status === 'Accepted' &&
                                  prescription.is_payment_completed == 0
                              "
                              :to="`/patient/billing/${prescription.id}`"
                            >
                              <a
                                href="javascript:void();"
                                class="btn btn-sm bg-primary-light btn-block mb-2"
                              >
                                <i class="fa fa-dollar mr-1"></i>
                                {{ $t("prescription.confirmPay") }}
                              </a>
                            </router-link>
                          </div>
                          <div
                            v-if="
                              prescription.status !== 'Cancelled' &&
                                prescription.status !== 'Completed'
                            "
                            class="col-6 pr-2"
                          >
                            <button
                              @click="cancelRequest(prescription.id)"
                              class="btn btn-sm bg-danger-light btn-block"
                              :disabled="
                                prescription.is_payment_completed === true ||
                                  prescription.status === 'Cancelled'
                              "
                            >
                              <i class="fa fa-times mr-1"></i>
                              {{ $t("prescription.cancelBtn") }}
                            </button>
                          </div>
                          <div
                            :class="
                              prescription.status === 'Cancelled' ||
                              prescription.status === 'Completed'
                                ? 'col-12 pl-2'
                                : 'col-6 pl-2'
                            "
                          >
                            <router-link
                              v-if="
                                !prescription.is_payment_completed &&
                                  prescription.status !== 'Cancelled'
                              "
                              :to="`/patient/billing/${prescription.id}`"
                            >
                              <a
                                href="javascript:void();"
                                class="btn btn-sm bg-primary-light mr-2 btn-block"
                              >
                                {{ $t("prescription.payFees") }}
                              </a>
                            </router-link>
                            <router-link
                              v-else
                              :to="`/patient/prescription/${prescription.id}`"
                            >
                              <a
                                href="javascript:void();"
                                class="btn btn-sm bg-info-light btn-block"
                              >
                                <i class="fa fa-file-prescription mr-1"></i>
                                {{ $t("prescription.viewDetails") }}
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>{{ $t("prescription.nothingHereYet") }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import PrescriptionDetails from "./PrescriptionDetails.vue";
import axios from "axios";
import { BASE_URL, HTTP_API } from "../../constants/config";

export default {
  props: {
    tabId: String,
  },
  components: {
    PrescriptionDetails: PrescriptionDetails,
  },
  data: function() {
    return {
      interval: null,
      tabState: "rxrequests",
      xDown: null,
      yDown: null,
    };
  },
  async mounted() {
    if (this.interval) clearInterval(this.interval);

    try {
      document.addEventListener("touchstart", this.handleTouchStart, false);
      document.addEventListener("touchmove", this.handleTouchMove, false);
    } catch (e) {
      console.error(e);
    }

    this.$socket.emit("GET:PATIENT_PRESCRIPTIONS", {
      userId: this.$store.getters.user.id,
    });

    this.interval = setInterval(() => {
      this.$socket.emit("GET:PATIENT_PRESCRIPTIONS", {
        userId: this.$store.getters.user.id,
      });
    }, 5000);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
  computed: {
    prescriptions() {
      return this.$store.getters.prescriptions;
    },
    pendingPrescriptions() {
      return this.prescriptions && this.prescriptions.length > 0
        ? this.prescriptions.filter(
            (t) =>
              t.status === "In Progress" ||
              t.status === "Accepted" ||
              t.status === "PharmacyVerified" ||
              t.status === "Sent"
          )
        : [];
    },
    approvedPrescriptions() {
      return this.prescriptions && this.prescriptions.length > 0
        ? this.prescriptions.filter((t) => t.status === "Completed")
        : [];
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    pharmacyStatus(status) {
      switch (status) {
        case "Sent":
          return "Sent To Pharmacy";

        case "PharmacyVerified":
          return "Pharmacy Verified";
      }

      return status;
    },
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      try {
        if (!this.xDown || !this.yDown) {
          return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          /*most significant*/
          if (xDiff > 0) {
            /* right to left swipe */
            switch (this.tabState) {
              case "rxrequests":
                this.tabState = "rxrefills";
                $("#refill-btn").click();
                $("#rxrequests").removeClass("show active");
                break;

              case "rxrefills":
                this.tabState = "rxrefills";
                $("#refill-btn").click();
                $("#rxrequests").removeClass("show active");
                break;
            }
          } else {
            /* left to right swipe */
            switch (this.tabState) {
              case "rxrequests":
                this.tabState = "rxrequests";
                $("#free-btn").click();
                $("#rxrefills").removeClass("show active");
                break;

              case "rxrefills":
                this.tabState = "rxrequests";
                $("#free-btn").click();
                $("#rxrefills").removeClass("show active");
                break;
            }
          }
        }

        /* reset values */
        this.xDown = null;
        this.yDown = null;
      } catch (e) {
        alert(e);
      }
    },
    cancelRequest(prescriptionId) {
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the request? You might not get any refund, if you have already paid the provider fees.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1db9aa",
        cancelButtonColor: "#e63c3c",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.showLoader(true);
          axios
            .post(`${BASE_URL}${HTTP_API.patientCancelPrescription}`, {
              id: prescriptionId,
            })
            .then(() => {
              this.showLoader(false);
              Swal.fire({
                icon: "success",
                title: "Request has been cancelled",
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .catch((e) => {
              this.showLoader(false);
              Swal.fire({
                icon: "error",
                title: e.response.data.message,
                showConfirmButton: false,
                timer: 2000,
              });
            });
        }
      });
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting":
          return "btn btn-sm bg-warning-light btn-block bubble";

        case "In Progress":
          return "btn btn-sm bg-primary-light btn-block bubble";

        case "Completed":
          return "btn btn-sm bg-success-light btn-block bubble";

        case "Sent":
        case "PharmacyVerified":
        case "Accepted":
          return "btn btn-sm bg-info-light btn-block bubble";

        case "Cancelled":
          return "btn btn-sm bg-danger-light btn-block bubble";

        default:
          return "btn btn-sm bg-primary-light btn-block bubble";
      }
    },
  },
};
</script>
<style scoped>
.card-btn {
  cursor: pointer;
}

.card-btn:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.nav-custom {
  background-color: #ebebeb;
  border-radius: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #2284d5;
  border-radius: 20px 20px 20px 20px;
}

.list-holder {
  height: calc(100vh - 250px);
  overflow: auto;
}

.bubble {
  border-radius: 30px;
}
</style>
