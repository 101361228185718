<template>
  <div class="card">
    <form @submit.prevent="rejectSubmit">
      <div class="card-body p-2">
        <div class="row">
          <div class="col-12">
            <h4>Refill ID: {{ prescriptionDetails.id }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h6>
              Are you sure you want to deny the refill request for the
              prescription?
            </h6>
            <hr />

            <!-- <div class="form-group text-left">
              <label>Reason for denial of refill request</label>
              <textarea
                name="cancellation-input"
                v-model="rejectionReason"
                class="form-control"
                required
              ></textarea>
            </div> -->

            <!-- <hr /> -->

            <h6>Important Notes</h6>
            <ul>
              <li>
                <small
                  >Denying refill request is permenant and cannot be
                  reversed.</small
                >
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <button type="submit" class="btn bg-danger-light mr-2 btn-block">
              <i class="fas fa-times"></i> Deny Refill Request
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import commonMixin from "../../mixins/common";

export default {
  props: {
    prescriptionDetails: Object,
  },
  data() {
    return {
      rejectionReason: "",
    };
  },
  methods: {
    rejectSubmit() {
      this.$emit(
        "submit",
        JSON.stringify({
          id: this.prescriptionDetails.id,
          refillId: this.prescriptionDetails.id,
          reason: this.rejectionReason,
        })
      );
    },
  },
  mixins: [commonMixin],
};
</script>