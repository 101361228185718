<template>
  <div class="account-page account-page-h100">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content bg-white mt-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <!-- Account Content -->
              <div class="account-content text-center">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12">
                    <img
                      src="../assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Stepahead Register"
                      width="250"
                    />
                  </div>
                  <div class="col-12 mt-3">
                    <div class="login-header text-center">
                      <img
                        src="../assets/img/logo.png"
                        class="img-fluid mb-5"
                        alt="stepaheadhealth"
                        width="250"
                      />
                      <h2>Forgot Password?</h2>
                      <p class="small text-muted text-center">
                        Enter your email to get a password reset link
                      </p>
                    </div>

                    <!-- Forgot Password Form -->
                    <form @submit.prevent="sendVerificationLink">
                      <div :class="`form-group form-focus ${email ? 'focused' : ''}`">
                        <input
                          v-model="email"
                          type="email"
                          class="form-control floating"
                          required
                        />
                        <label class="focus-label">Registered Email</label>
                      </div>
                      <button
                        class="btn btn-primary btn-block btn-lg login-btn"
                        type="submit"
                      >
                        Send Password Reset Link
                      </button>
                      <div class="text-center mt-3">
                        <router-link class="forgot-link mt-3" to="/login"
                          ><i class="fa fa-arrow-left mr-2"></i>Back to
                          login</router-link
                        >
                      </div>
                    </form>
                    <!-- /Forgot Password Form -->
                  </div>
                </div>
              </div>
              <!-- /Account Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    ...mapActions(["sendForgotPasswordLink", "showLoader"]),
    async sendVerificationLink() {
      try {
        this.showLoader(true);
        await this.sendForgotPasswordLink({ email: this.email });
        this.email = "";
        Swal.fire(
          "Reset Link Sent",
          "Please check your mail box for reset link",
          "success"
        ).then(() => {
          this.$router.push("/login");
        });
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>
<style scoped>
.account-page-h100 {
  height: 100vh;
  overflow: hidden;
}
</style>