<template>
  <div class="account-page main-wrapper-adjust-profile">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="mb-3">Great! Let's Get Started</h2>
                      <h4 class="mb-3">Please complete your profile</h4>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="javascript:void();" class="active">1</a>
                        </li>
                        <li><a href="javascript:void();">2</a></li>
                        <li><a href="javascript:void();">3</a></li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                        <li><a href="javascript:void();">9</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="profile-pic-col">
                        <div class="form-group text-left">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstname"
                            v-model="firstName"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="form-group text-left">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastname"
                            v-model="lastName"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="form-group text-left">
                          <label>Date of Birth (MM-DD-YYYY)</label>
                          <Datepicker
                            :lowerLimit="dateLowerLimit"
                            :upperLimit="dateUpperLimit"
                            inputFormat="MM-dd-yyyy"
                            class="form-control"
                            v-model="dob"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <hr />
                        <button
                          type="submit"
                          class="
                            btn btn-primary btn-block btn-lg
                            login-btn
                            step1_submit
                          "
                        >
                          Continue <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2024. StepAhead Health Inc.</span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
        <!-- /Page Content -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Datepicker from "vue3-datepicker";
import commonMixin from "../../mixins/common";

export default {
  components: {
    Datepicker,
  },

  mixins: [commonMixin],

  data() {
    return {
      firstName: "",
      lastName: "",
      dob: new Date(2003, 11, 31),
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(new Date().getFullYear() - 19, 11, 31),
    };
  },

  async mounted() {
    this.showLoader(true);
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.dob = new Date(this.$store.getters.user.dob);
    this.showLoader(false);
  },

  methods: {
    ...mapActions(["saveUserDetails", "showLoader", "showAlert"]),

    async saveStep() {
      try {
        this.showLoader(true);
        const formated = this.formatDate(this.dob);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          dob: formated,
          first_name: this.firstName,
          last_name: this.lastName,
          current_registration_step: 2,
        });

        this.$router.push("/provider/step-2");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        this.showAlert({
          message: e.response.data.message,
          type: "danger",
          time: 4000,
        });
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
