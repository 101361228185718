<template>
  <div>
    <div class="main-wrapper main-wrapper-adjust-profile">
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-6">
              <h2 class="breadcrumb-title">Finances</h2>
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-sm btn-info mr-1" @click="toggleModal('download-statement-modal')">
                <i class="fas fa-file-pdf"></i>
              </button>
              <router-link :to="`/provider/withdraw/${overviewObj.availableWithdrawal}`">
                <button class="btn btn-sm btn-primary">
                  <i class="fas fa-dollar-sign mr-1"></i> Withdraw
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <!-- <div class="row">
            <div class="col-6">
              <button
                class="btn btn-sm bg-danger-light mr-2"
                @click="toggleModal('download-statement-modal')"
              >
                <i class="fas fa-file-pdf mr-1"></i> Request Financial Statement
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-sm bg-success-light"
                @click="toggleModal('request-payout-modal')"
              >
                <i class="fas fa-dollar-sign mr-1"></i> Request Withdrawal
              </button>
            </div>
          </div> -->
          <!-- Earnings summary -->
          <div class="row">
            <div class="col-4 m-0 p-1">
              <div class="account-card bg-purple-light ml-1">
                <span>${{
                  overviewObj.totalGrossIncome
                  ? overviewObj.totalGrossIncome
                  : 0
                }}
                  <small> USD</small></span>
                <small>Total Income</small>
              </div>
            </div>
            <div class="col-4 m-0 p-1">
              <div class="account-card bg-danger-light">
                <span>${{
                  overviewObj.totalDeductions
                  ? overviewObj.totalDeductions
                  : 0
                }}
                  <small> USD</small></span>
                <small>Total Deduction</small>
              </div>
            </div>
            <div class="col-4 m-0 p-1">
              <div class="account-card bg-info-light mr-2">
                <span>${{
                  overviewObj.availableWithdrawal
                  ? overviewObj.availableWithdrawal
                  : 0
                }}
                  <small> USD</small></span>
                <small>Available</small>
              </div>
            </div>
            <div class="col-4 m-0 p-1">
              <div class="account-card bg-success-light ml-1">
                <span>${{
                  overviewObj.totalNetIncome ? overviewObj.totalNetIncome : 0
                }}
                  <small> USD</small></span>
                <small>Total Net Income</small>
              </div>
            </div>
            <div class="col-4 m-0 p-1">
              <div class="account-card bg-warning-light">
                <span>${{
                  overviewObj.totalRefunded ? overviewObj.totalRefunded : 0
                }}
                  <small> USD</small></span>
                <small>Total Refunded</small>
              </div>
            </div>
            <div class="col-4 m-0 p-1">
              <div class="account-card bg-success-light mr-2">
                <span>${{
                  overviewObj.totalWithdrawn ? overviewObj.totalWithdrawn : 0
                }}
                  <small> USD</small></span>
                <small>Total Withdrawn</small>
              </div>
            </div>
          </div>

          <!-- Transactions -->
          <div class="row mt-2">
            <div class="col-12">
              <ul class="nav nav-pills nav-fill nav-custom">
                <li class="nav-item">
                  <a :class="`nav-link active`" href="javascript:void();" data-toggle="pill" data-target="#latest">Last
                    10</a>
                </li>
                <li class="nav-item">
                  <a :class="`nav-link`" href="javascript:void();" data-toggle="pill" data-target="#refunds">Refunds</a>
                </li>
                <li class="nav-item">
                  <a :class="`nav-link`" href="javascript:void();" data-toggle="pill"
                    data-target="#withdrawals">Withdrawals</a>
                </li>
              </ul>
              <div class="tab-content">
                <!-- LAST 10 TRANSACTIONS -->
                <div :class="`tab-pane fade show active`" id="latest" role="tabpanel" aria-labelledby="latest-tab">
                  <div v-if="last10Transactions.length > 0" class="list-holder">
                    <div class="card shadow-sm bg-white rounded mb-3" v-for="(txn, i) in last10Transactions" :key="i">
                      <div class="card-body">
                        <div class="row" style="font-size: 11px">
                          <div class="col-6 m-0 p-1">
                            Request ID:
                            <strong>{{ txn.prescription_id }}</strong>
                            <p class="mb-0 mt-2">
                              Txn ID:
                              <strong>{{ txn.trans_id }}</strong>
                            </p>
                          </div>
                          <div class="col-6 m-0 p-1">
                            Amount: <strong>${{ txn.total_amount }}</strong>
                            {{ txn.currency }}
                            <p class="mb-0 mt-2">
                              Date:
                              {{ new Date(txn.created_at).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>No Transactions</h5>
                  </div>
                </div>

                <!-- REFUNDS -->
                <div :class="`tab-pane fade`" id="refunds" role="tabpanel" aria-labelledby="refunds-tab">
                  <div v-if="last10Refunds.length > 0" class="list-holder">
                    <div class="card shadow-sm bg-white rounded mb-3" v-for="(txn, i) in last10Refunds" :key="i">
                      <div class="card-body">
                        <div class="row" style="font-size: 11px">
                          <div class="col-6 m-0 p-1">
                            Request ID:
                            <strong>{{ txn.prescription_id }}</strong>
                            <p class="mb-0 mt-2">
                              Txn ID:
                              <strong>{{ txn.trans_id }}</strong>
                            </p>
                          </div>
                          <div class="col-6 m-0 p-1">
                            Amount: <strong>${{ txn.total_amount }}</strong>
                            {{ txn.currency }}
                            <p class="mb-0 mt-2">
                              Date:
                              {{ new Date(txn.created_at).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>No Refunds</h5>
                  </div>
                </div>

                <!-- WITHDRAWALS -->
                <div :class="`tab-pane fade`" id="withdrawals" role="tabpanel" aria-labelledby="withdrawals-tab">
                  <div v-if="last10Withdrawals.length > 0" class="list-holder">
                    <div class="card shadow-sm bg-white rounded mb-3" v-for="(txn, i) in last10Withdrawals" :key="i">
                      <div class="card-body">
                        <div class="row" style="font-size: 11px">
                          <div class="col-6 m-0 p-1">
                            Withdrawal ID:
                            <strong>{{ txn.id }}</strong>
                            <!-- <p class="mb-0 mt-2">
                              Email:
                              <strong> {{ txn.paypal_email }}</strong>
                            </p> -->
                            <p class="mb-0 mt-2">
                              Date:
                              {{ new Date(txn.created_at).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-6 m-0 p-1">
                            Status:
                            <span :class="getWithdrawStatusClass(txn.status)">{{
                              getWithdrawStatus(txn.status)
                            }}</span>
                            <p class="mb-0 mt-2">
                              Amount:
                              <strong>${{ txn.withdraw_amount }} USD</strong>
                            </p>
                          </div>
                          <div class="col-12 m-0 p-1">
                            <button class="btn btn-sm bg-danger-light btn-block" @click="confirmCancelWithdrawal(txn.id)"
                              v-if="txn.status !== 'CANCELLED'">
                              Cancel Withdraw
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>No Withdrawals</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body pt-0">
                  <nav class="user-tabs mb-4">
                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#recent-txns"
                          data-toggle="tab"
                          >Last 10 Transactions</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#refund-reqs"
                          data-toggle="tab"
                          >Refund Requests</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#withdrawal-reqs"
                          data-toggle="tab"
                          >Withdrawal Requests</a
                        >
                      </li>
                    </ul>
                  </nav>

                  <div class="tab-content pt-0">
                    <div id="recent-txns" class="tab-pane fade show active">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table
                              v-if="last10Transactions.length > 0"
                              class="table table-hover table-center mb-0"
                            >
                              <thead>
                                <tr>
                                  <th>Request ID</th>
                                  <th>Txn Reference ID</th>
                                  <th>Date | Time</th>
                                  <th>Payer Name</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(txn, i) in last10Transactions"
                                  :key="i"
                                >
                                  <td>
                                    {{ txn.prescription_id }}
                                  </td>
                                  <td>
                                    {{ txn.trans_id }}
                                  </td>
                                  <td>
                                    {{
                                      new Date(txn.created_at).toLocaleString()
                                    }}
                                  </td>
                                  <td>
                                    {{ txn.payer_name }}
                                  </td>
                                  <td>
                                    ${{ txn.total_amount }}
                                    {{ txn.currency }}
                                  </td>
                                  <td>
                                    <span
                                      :class="getPaymentStatusClass(txn.status)"
                                      >{{ getPaymentStatus(txn.status) }}</span
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="p-5 text-center" v-else>
                              <p>No Transactions Found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="refund-reqs">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table
                              class="table table-hover table-center mb-0"
                              v-if="last10Refunds.length > 0"
                            >
                              <thead>
                                <tr>
                                  <th>Ref. ID</th>
                                  <th>PayPal Email</th>
                                  <th>Created Date</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(txn, i) in last10Refunds" :key="i">
                                  <td>
                                    {{ txn.id }}
                                  </td>
                                  <td>
                                    {{ txn.paypal_email }}
                                  </td>
                                  <td>
                                    {{
                                      new Date(txn.created_at).toLocaleString()
                                    }}
                                  </td>
                                  <td>${{ txn.withdraw_amount }} USD</td>
                                  <td>
                                    <span
                                      :class="
                                        getWithdrawStatusClass(txn.status)
                                      "
                                      >{{ getWithdrawStatus(txn.status) }}</span
                                    >
                                  </td>
                                  <td>
                                    <button
                                      v-if="txn.status !== 'CANCELLED'"
                                      class="
                                        btn btn-sm
                                        bg-danger-light
                                        btn-block
                                      "
                                    >
                                      Cancel Withdraw
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="p-5 text-center" v-else>
                              <p>No Refund Requests</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="withdrawal-reqs">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table
                              v-if="last10Withdrawals.length > 0"
                              class="table table-hover table-center mb-0"
                            >
                              <thead>
                                <tr>
                                  <th>Ref. ID</th>
                                  <th>PayPal Email</th>
                                  <th>Created Date</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(txn, i) in last10Withdrawals"
                                  :key="i"
                                >
                                  <td>
                                    {{ txn.id }}
                                  </td>
                                  <td>
                                    {{ txn.paypal_email }}
                                  </td>
                                  <td>
                                    {{
                                      new Date(txn.created_at).toLocaleString()
                                    }}
                                  </td>
                                  <td>${{ txn.withdraw_amount }} USD</td>
                                  <td>
                                    <span
                                      :class="
                                        getWithdrawStatusClass(txn.status)
                                      "
                                      >{{ getWithdrawStatus(txn.status) }}</span
                                    >
                                  </td>
                                  <td>
                                    <button
                                      class="
                                        btn btn-sm
                                        bg-danger-light
                                        btn-block
                                      "
                                      @click="confirmCancelWithdrawal(txn.id)"
                                      :disabled="txn.status === 'CANCELLED'"
                                    >
                                      Cancel Withdraw
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="p-5 text-center" v-else>
                              <p>No Withdrawal Requests</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  -->
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Download statement modal -->
      <div class="modal fade" id="download-statement-modal" style="display: none">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <DownloadStatement></DownloadStatement>
            </div>
          </div>
        </div>
      </div>

      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import RequestPayout from "./finances/RequestPayout.vue";
import DownloadStatement from "./finances/DownloadStatement.vue";

export default {
  components: {
    RequestPayout,
    DownloadStatement,
  },
  data () {
    return {
      overviewObj: {
        totalGrossIncome: 0,
        totalNetIncome: 0,
        totalDeductions: 0,
        availableWithdrawal: 0,
        totalWithdrawn: 0,
        totalRefunded: 0,
      },
      last10Transactions: [],
      last10Withdrawals: [],
      last10Refunds: [],
    };
  },
  async mounted () {
    await this.getFinancialSummary();
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    toggleModal (modalName) {
      $("#" + modalName).modal("toggle");
    },

    async confirmCancelWithdrawal (withdrawalId) {
      try {
        Swal.fire({
          title: "Are you sure?",
          icon: "question",
          html: `Don't worry! You can still create a new withdrawal request.`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          cancelButtonText: "No",
          cancelButtonAriaLabel: "No",
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.showLoader(true);
              const res = await axios.post(
                `${config.BASE_URL}${config.HTTP_API.providerCancelWithdrawRequest}/${this.user.id}`,
                {
                  withdrawalId: withdrawalId,
                }
              );

              Swal.fire("Withdrawal Request Cancelled", "", "success").then(
                async () => {
                  await this.getFinancialSummary();
                }
              );
            }

            this.showLoader(false);
          })
          .catch((e) => {
            this.showLoader(false);
          });
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    getHistoricalStatement () { },

    async getFinancialSummary () {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${config.BASE_URL}${config.HTTP_API.providerGetFinanceOverview}${this.user.id}`
        );
        this.overviewObj = { ...this.overviewObj, ...res.data };
        this.last10Transactions = res.data.last10Txns;
        this.last10Withdrawals = res.data.last10Withdrawals;
        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    getPaymentStatus (status) {
      if (status === "COMPLETED") return "Completed";
      return "Pending";
    },
    getPaymentStatusClass (status) {
      if (status === "COMPLETED") return "bg-success-light p-2";
      return "bg-danger-light p-2";
    },

    getWithdrawStatus (status) {
      if (status === "ACTIVE") return "In Process";
      if (status === "CANCELLED") return "Cancelled";
      return "Pending";
    },
    getWithdrawStatusClass (status) {
      if (status === "ACTIVE") return "text-info p-2";
      if (status === "CANCELLED") return "text-danger p-2";
      return "text-danger p-2";
    },
  },
};
</script>
<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.nav-custom {
  background-color: #ebebeb;
  border-radius: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #2284d5;
  border-radius: 20px 20px 20px 20px;
}

.list-holder {
  height: calc(100vh - 250px);
  overflow: auto;
}
</style>