<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <div class="accordion" id="provider-license-accordion">
      <div class="card">
        <div class="card-header" id="state-licenses">
          <h2 class="mb-0">
            <button
              class="btn btn-link btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#state-license-collapse"
              aria-expanded="true"
              aria-controls="state-license-collapse"
            >
              <i class="fas fa-user mr-1"></i> State Licenses
            </button>
          </h2>
        </div>

        <div
          id="state-license-collapse"
          class="collapse show"
          aria-labelledby="state-licenses"
          data-parent="#provider-license-accordion"
        >
          <div class="card-body">
            <form @submit.prevent="saveStep">
              <div class="form-group">
                <div class="row">
                  <div class="col-3">
                    <div class="form-group text-left">
                      <label>Primary</label>
                      <input
                        type="text"
                        name="address2"
                        class="form-control form-control-xs"
                        v-model="primaryState"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="form-group text-left">
                      <label>Primary State License Number</label>
                      <input
                        type="text"
                        name="state-license-number"
                        class="form-control form-control-xs"
                        placeholder="Max 35 Chars"
                        max="35"
                        v-model="stateLicenseNumber"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-for="(st, i) in additionalStates" :key="i">
                  <div class="col-3">
                    <div class="form-group text-left">
                      <label>State {{ i + 1 }}</label>
                      <input
                        type="text"
                        name="address2"
                        class="form-control form-control-xs"
                        :value="st.state_code"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="form-group text-left">
                      <label>State License Number {{ i + 1 }}</label>
                      <input
                        type="text"
                        name="state-license-number"
                        class="form-control form-control-xs"
                        placeholder="Max 35 Chars"
                        v-model="st.state_license_number"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-1">
                    <a
                      href="javascript:void()"
                      @click="removeState(i)"
                      class="btn bg-danger-light btn-sm mt-4 mr-3"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </div>

                <form @submit.prevent="addNewState">
                  <div class="row" v-show="showNewStateForm">
                    <div class="col-7">
                      <div class="form-group">
                        <select
                          class="form-control select"
                          tabindex="-1"
                          aria-hidden="true"
                          v-model="newState"
                          required
                        >
                          <option
                            v-for="(state, i) in statesData"
                            :key="i"
                            :value="state.state_code"
                          >
                            {{ state.state_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5">
                      <button
                        :disabled="newState === ''"
                        type="submit"
                        data-dismiss="modal"
                        class="btn btn-primary btn-block"
                      >
                        Add State
                      </button>
                    </div>
                  </div>
                </form>
                <div class="text-center">
                  <a
                    href="javascript:void();"
                    class="btn btn-info btn-sm btn-block"
                    v-show="!showNewStateForm"
                    @click="showNewStateForm = true"
                  >
                    <i class="fa fa-plus mr-2"></i> Add New License State</a
                  >
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary btn-block mt-2">
                    <i class="fa fa-save mr-2"></i> Update State Licenses
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="license-information">
          <h2 class="mb-0">
            <button
              class="btn btn-link btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#license-info-collapse"
              aria-expanded="true"
              aria-controls="license-info-collapse"
            >
              <i class="fas fa-user mr-1"></i> License Information
            </button>
          </h2>
        </div>

        <div
          id="license-info-collapse"
          class="collapse"
          aria-labelledby="license-information"
          data-parent="#provider-license-accordion"
        >
          <div class="card-body">
            <div class="row form-row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>NPI ID</label>
                  <input
                    type="text"
                    class="form-control form-control-xs"
                    v-model="$store.getters.user.npi"
                    required
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>License No.</label>
                  <input
                    type="text"
                    class="form-control form-control-xs"
                    v-model="$store.getters.user.license"
                    required
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Description</label>
                  <input
                    type="text"
                    class="form-control form-control-xs"
                    v-model="$store.getters.user.description"
                    required
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Enumeration Type</label>
                  <input
                    type="text"
                    class="form-control form-control-xs"
                    v-model="$store.getters.user.enumeration_type"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Credential</label>
                  <input
                    type="text"
                    class="form-control form-control-xs"
                    v-model="$store.getters.user.credential"
                    required
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Certificate Date</label>
                  <input
                    type="text"
                    class="form-control form-control-xs"
                    :value="
                      new Date(
                        $store.getters.user.enumeration_date
                      ).toLocaleDateString()
                    "
                    required
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../../mixins/common";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      primaryState: "",
      stateLicenseNumber: "",
      newState: "",
      statesData: [],
      additionalStates: [],
      showNewStateForm: false,
    };
  },

  async mounted() {
    this.showLoader(true);

    this.primaryState = this.$store.getters.user.state;

    for (let state of this.$store.getters.user.additional_states) {
      if (state.type === "primary")
        this.stateLicenseNumber = state.state_license_number;
    }

    this.additionalStates = this.$store.getters.user.additional_states.filter(
      (a) => a.type !== "primary"
    );

    this.statesData = await this.getStateList();

    this.statesData.splice(
      this.statesData.map((i) => i.iso2).indexOf(this.primaryState),
      1
    );
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader"]),

    addNewState() {
      this.additionalStates.push({
        state_code: this.newState,
        state_license_number: "",
      });
      this.newState = "";
      this.showNewStateForm = false;
    },

    removeState(index) {
      this.additionalStates.splice(index, 1);
    },

    async saveStep() {
      try {
        this.showLoader(true);
        let step = 6;
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          additional_states: [
            ...this.additionalStates,
            {
              state_code: this.primaryState,
              state_license_number: this.stateLicenseNumber,
              type: "primary",
            },
          ],
          current_registration_step: step,
        });

        Swal.fire({
          icon: "success",
          title: "States Updated!",
          text: ``,
          showConfirmButton: false,
          timer: 2000,
        });

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: "Ooops!",
          text: e.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });

        console.error(e);
      }
    },
  },
};
</script>
<style>
.delete-btn {
  cursor: pointer;
  margin: 38px 0px 0px -10px;
}

.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>
