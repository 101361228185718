<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card mb-5">
              <div class="card-body">
                <form @submit.prevent="saveProfileDetails">
                  <div class="row form-row">
                    <div class="col-12">
                      <div class="form-group text-center">
                        <div class="change-avatar">
                          <div class="change-photo-btn w-100">
                            <span> {{ $t("profileSetting.button1") }}</span>
                            <input
                              type="file"
                              class="upload"
                              id="profile_image"
                            />
                          </div>
                        </div>
                        <small class="form-text text-muted">{{
                          $t("profileSetting.description1")
                        }}</small>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>
                          {{ $t("profileSetting.firstNameLabel") }}</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          v-model="firstName"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label> {{ $t("profileSetting.lastNameLabel") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          v-model="lastName"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>
                          {{ $t("profileSetting.dateOfBirthLabel") }}
                          <small>(mm/dd/yyyy)</small></label
                        >
                        <Datepicker
                          :lowerLimit="dateLowerLimit"
                          :upperLimit="dateUpperLimit"
                          inputFormat="MM-dd-yyyy"
                          class="form-control form-control-xs"
                          v-model="dob"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("profileSetting.genderLabel") }}</label>
                        <select
                          v-model="gender"
                          class="form-control form-control-xs select"
                          required
                        >
                          <option value="Male">
                            {{ $t("profileSetting.male") }}</option
                          >
                          <option value="Female">{{
                            $t("profileSetting.female")
                          }}</option>
                          <option value="Other">{{
                            $t("profileSetting.preferNotToAnswer")
                          }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label> {{ $t("profileSetting.weightLabel") }}</label>
                        <input
                          type="number"
                          min="0"
                          max="800"
                          class="form-control form-control-xs"
                          name="weight"
                          v-model="weight"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label> {{ $t("profileSetting.heightLabel") }}</label>
                        <input
                          type="number"
                          min="0"
                          max="500"
                          v-model="height"
                          class="form-control form-control-xs"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label> {{ $t("profileSetting.emailIdLabel") }}</label>
                        <input
                          type="email"
                          class="form-control form-control-xs"
                          v-model="$store.getters.user.email"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label> {{ $t("profileSetting.mobileLabel") }}</label>
                        <input
                          type="text"
                          v-model="$store.getters.user.primary_phone"
                          class="form-control form-control-xs"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label> {{ $t("profileSetting.address1Label") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          v-model="addressLine1"
                          maxlength="34"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>
                          {{ $t("profileSetting.address2lLabel") }}</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          v-model="addressLine2"
                          maxlength="34"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("profileSetting.stateLabel") }}</label>
                        <select
                          class="form-control form-control-xs select"
                          v-model="state"
                          required
                          @change="fetchCities()"
                        >
                          <option
                            v-for="(state, i) in statesData"
                            :key="i"
                            :value="state.iso2"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("profileSetting.cityLabel") }}</label>
                        <select
                          class="form-control form-control-xs select"
                          v-model="city"
                          required
                          tabindex="-1"
                          aria-hidden="true"
                        >
                          <option
                            v-for="(c, i) in citiesData"
                            :key="i"
                            :value="c.name"
                          >
                            {{ c.name }}
                          </option>
                        </select>
                        <!-- <input
                          class="form-control form-control-xs select"
                          tabindex="-1"
                          aria-hidden="true"
                          v-model="city"
                          required
                        /> -->
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("profileSetting.postalLabel") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          v-model="zipCode"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("profileSetting.countryLabel") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          value="United States"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="submit-section">
                    <button
                      type="submit"
                      class="btn btn-sm btn-primary submit-btn btn-block"
                    >
                      {{ $t("profileSetting.button2") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";
import Datepicker from "vue3-datepicker";
import Swal from "sweetalert2";
export default {
  mixins: [common],
  data() {
    return {
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(),

      firstName: "",
      lastName: "",
      gender: "",
      dob: new Date(),
      height: "",
      weight: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      citiesData: [],
      photo: null,
    };
  },
  components: {
    Datepicker,
  },

  async mounted() {
    this.showLoader(true);
    this.statesData = await this.getStateList();

    // Personal details
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.gender = this.$store.getters.user.gender;
    this.dob = new Date(this.$store.getters.user.dob);
    this.height = this.$store.getters.user.height;
    this.weight = this.$store.getters.user.weight;

    // Address parsing
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;

    this.profileImage = this.$store.getters.user.profile_image_url;
    this.state = this.$store.getters.user.state;

    if (this.state !== "") await this.fetchCities(this.state);
    this.city = this.$store.getters.user.city;

    let self = this;
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = async function(e) {
          await self.savePhoto();
          self.profileImage = self.$store.getters.profile_image_url;
          // self.$router.go();
        };

        reader.readAsDataURL(input.files[0]);
        self.photo = input.files[0];
      }
    }

    $("#profile_image").change(function() {
      readURL(this);
    });

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "saveUserProfilePhoto", "showLoader"]),
    async fetchCities() {
      this.showLoader(true);
      this.citiesData = await this.getCityList(this.state);
      this.showLoader(false);
    },

    async savePhoto() {
      try {
        this.showLoader(true);
        let formData = new FormData();
        formData.append("photo", this.photo);
        formData.append("id", this.$store.getters.user.id);
        formData.append("currentRegistrationStep", 1);
        await this.saveUserProfilePhoto(formData);

        Swal.fire({
          icon: "success",
          title: `Superb!`,
          text: `Profile picture updated`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },

    async saveProfileDetails() {
      try {
        this.showLoader(true);
        const formated = this.formatDate(this.dob);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          first_name: this.firstName,
          last_name: this.lastName,
          dob: formated,
          gender: this.gender,
          weight: this.weight,
          height: this.height,
          address1: this.addressLine1,
          address2: this.addressLine2,
          state: this.state,
          city: this.city,
          zip_code: this.zipCode,
        });

        Swal.fire({
          icon: "success",
          title: `Great! `,
          text: `Profile updated`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },
  },
};
</script>
<style>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>
