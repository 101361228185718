<template>
  <div class="content success-page-cont">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <!-- Success Card -->
          <div class="card success-card">
            <div class="card-body">
              <div class="success-cont">
                <i class="fas fa-check"></i>
                <h3>Prescription Request Created!</h3>
                <p>
                  Your prescription request with number
                  <strong>#{{ prescriptionNumber }}</strong> has been created
                  successfully and will be assigned to the nearest medical
                  provider.
                </p>
                <router-link
                  to="/patient/prescriptions/rxrequests"
                  class="btn btn-primary view-inv-btn"
                  >Go to Prescriptions</router-link
                >
                <p class="mt-3">
                  <small
                    >You will be redirected to the dashboard in
                    {{ redirectTime }} seconds.</small
                  ><br />
                  <small
                    ><a href="/patient/index"
                      >Click here to redirect immediately</a
                    ></small
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- /Success Card -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionNumber: String,
  },
  data() {
    return {
      redirectTime: 10,
    };
  },
  mounted() {
    const int = setInterval(() => {
      if (this.redirectTime === 0) {
        clearInterval(int);
        this.$router.push("/patient/index");
        return;
      }

      this.redirectTime -= 1;
    }, 1000);
  },
};
</script>