<template>
  <div class="account-page account-page-h100">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content bg-white mt-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <!-- Login Content -->
              <div class="account-content text-center">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12">
                    <img
                      src="../assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Stepahead Register"
                      width="250"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="login-header text-center mt-3">
                      <img
                        src="../assets/img/logo.png"
                        class="img-fluid mb-5"
                        alt="stepaheadhealth"
                        width="250"
                      />
                      <h3 class="text-muted">
                        {{ $t("loginPage.title") }}
                      </h3>
                    </div>
                    <form @submit.prevent="login">
                      <div class="row">
                        <div class="col-12">
                          <div
                            :class="
                              `form-group form-focus ${email ? 'focused' : ''}`
                            "
                          >
                            <input
                              type="email"
                              class="form-control floating"
                              v-model="email"
                              required
                            />
                            <label class="focus-label">{{
                              $t("loginPage.email")
                            }}</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div
                            :class="
                              `form-group form-focus ${
                                password ? 'focused' : ''
                              }`
                            "
                          >
                            <input
                              v-if="showPassword"
                              type="text"
                              v-model="password"
                              class="form-control floating"
                              required
                            />
                            <input
                              v-else
                              type="password"
                              v-model="password"
                              class="form-control floating"
                              required
                            />
                            <label class="focus-label">
                              {{ $t("loginPage.password") }}</label
                            >
                            <span class="p-viewer2">
                              <i
                                class="fa"
                                :class="{
                                  'fa-eye-slash': showPassword,
                                  'fa-eye': !showPassword,
                                }"
                                aria-hidden="true"
                                @click="toggleShow"
                              >
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        class="btn btn-primary btn-block btn-lg login-btn"
                      >
                        <i class="fa fa-lock mr-2"> </i>
                        {{ $t("loginPage.button") }}
                      </button>
                    </form>
                    <!-- <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">or</span>
                    </div> -->
                    <!-- <div class="row form-row social-login">
                      <div class="col-6">
                        <button
                          disabled
                          class="btn btn-facebook btn-block"
                        ><i class="fab fa-facebook-f mr-1"></i> Facebook</button>
                      </div>
                      <div class="col-6">
                        <button
                          disabled
                          class="btn btn-google btn-block"
                        ><i class="fab fa-google mr-1"></i> Google</button>
                      </div>
                    </div> -->

                    <!-- /Register Form -->
                    <div class="text-center mt-5 dont-have">
                      <div class="row">
                        <div class="col-6 float-left">
                          <router-link class="forgot-link" to="/signup">
                            {{ $t("loginPage.createAnAccount") }}</router-link
                          >
                        </div>

                        <div class="col-6 float-right">
                          <router-link
                            class="forgot-link"
                            to="/forgot-password"
                          >
                            {{ $t("loginPage.forgotPassword") }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mt-3 inline-flex"
                      v-for="(entry, i) in languages"
                      :key="`Lang${i}`"
                    >
                      <a
                        :class="
                          currentLanguage === entry.language
                            ? 'active mr-3'
                            : 'mr-3'
                        "
                        href="javascript:void();"
                        @click="
                          setLanguage(entry.language, entry.flag, entry.title)
                        "
                      >
                        <img :src="entry.flag" width="12" /> {{ entry.title }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Login Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: "",
      languages: [
        {
          flag: "/img/flags/united-states.svg",
          language: "en",
          title: "English",
        },
        {
          flag: "/img/flags/spain.svg",
          language: "es",
          title: "Español",
        },
      ],
      currentLanguage: "en",
      currentText: "English",
      currentFlag: "/img/flags/united-states.svg",
      currentValue: "en",
    };
  },

  mounted() {
    this.$store.dispatch("showLoader", true);

    const currentLang = this.languages.find(
      (x) => x.language === this.$i18n.locale
    );
    this.currentText = currentLang.title;
    this.currentFlag = currentLang.flag;

    ReactNativeWebView.postMessage(
      JSON.stringify({ command: "GET_DEVICE_INFO" })
    );
    if (this.$store.getters.user) {
      this.routeUser();
    } else {
      this.$store.dispatch("showLoader", false);
    }
  },

  methods: {
    ...mapActions(["Login"]),

    setLanguage(locale, country, flag) {
      this.currentLanguage = locale;
      this.currentText = country;
      this.currentFlag = flag;
      this.$i18n.locale = locale;
    },

    routeUser() {
      switch (this.$store.getters.user.role) {
        case "patient":
          let routePath1 = `/patient/step-${this.$store.getters.user.current_registration_step}`;
          if (
            !this.$route.path.includes("/patient/step-") &&
            !this.$store.getters.user.is_registration_complete
          ) {
            this.$router.push(routePath1);
          } else {
            this.$router.push("/patient/index");
          }
          break;

        case "provider":
          let routePath2 = `/provider/step-${this.$store.getters.user.current_registration_step}`;
          if (
            !this.$route.path.includes("/provider/step-") &&
            !this.$store.getters.user.is_registration_complete
          ) {
            this.$router.push(routePath2);
          } else {
            this.$router.push("/provider/index");
          }
          break;
      }
    },

    async login() {
      try {
        this.$store.dispatch("showLoader", true);
        const body = {
          email: this.email,
          password: this.password,
        };

        // Login API
        await this.Login(body);

        setTimeout(() => {
          this.$store.dispatch("showLoader", false);
        }, 1000);

        this.routeUser();
        this.email = "";
        this.password = "";

        // Store device information
        if (this.$store.getters.device) {
          await this.$store.dispatch("validateLoginDevice", {
            userId: this.$store.getters.user.id,
            isDevice: this.$store.getters.device.isDevice,
            brand: this.$store.getters.device.brand,
            deviceName: this.$store.getters.device.deviceName,
            os: this.$store.getters.device.os,
            osVersion: this.$store.getters.device.osVersion,
            manufacturer: this.$store.getters.device.manufacturer,
            deviceToken: this.$store.getters.device.deviceToken,
            expoToken: this.$store.getters.device.expoToken,
          });
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.$store.dispatch("showLoader", false);
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style>
.account-page-h100 {
  height: 100vh;
  overflow: hidden;
}

.p-viewer2 {
  float: right;
  margin-top: -35px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
