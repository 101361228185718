<template>
  <div class="main-wrapper">
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4>{{ $t("deactivateAccount.title") }}</h4>
              </div>
              <div class="card-body">
                <h5>{{ $t("deactivateAccount.description1") }}</h5>
                <h6>
                  {{ $t("deactivateAccount.description2") }}
                </h6>
                <hr />
                <ol style="font-size: 10px" class="m-2 p-0">
                  <li class="mb-2">
                    {{ $t("deactivateAccount.point1") }}
                  </li>
                  <li class="mb-2">
                    {{ $t("deactivateAccount.point2") }}
                  </li>
                  <li class="mb-2">
                    {{ $t("deactivateAccount.point3") }}
                  </li>
                  <li class="mb-2">
                    {{ $t("deactivateAccount.point4") }}
                  </li>
                </ol>
                <hr />
                <form @submit.prevent="deactivateAccount">
                  <p class="font-size: 10px">
                    {{ $t("deactivateAccount.description3") }}
                  </p>
                  <div class="form-group text-left">
                    <input
                      type="password"
                      name="password"
                      v-model="password"
                      class="form-control form-control-sm"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn bg-danger-light btn-sm btn-block"
                  >
                    {{ $t("deactivateAccount.button") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      password: "",
    };
  },
  methods: {
    ...mapActions(["deactivatePatientAccount", "showLoader"]),
    async deactivateAccount() {
      try {
        this.showLoader(true);

        await this.deactivatePatientAccount({
          id: this.$store.getters.user.id,
          password: this.password,
        });

        Swal.fire(
          "Account Deactivated",
          "Your account has been deactivated. Hoping to see you soon!",
          "success"
        ).then(async () => {
          await this.$store.dispatch("logOut", {
            id: this.$store.getters.user.id,
            deviceToken: this.$store.getters.device
              ? this.$store.getters.device.deviceToken
              : "",
          });

          this.$router.push("/login");
        });

        this.password = "";
        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.password = "";
        this.showLoader(false);
      }
    },
  },
};
</script>
