<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <breadcrumb40></breadcrumb40>

    <section class="section home-tile-section">
      <div class="container-fluid">
        <div class="section-header text-center">
          <h2>Refill a Prescription</h2>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div
              class="bd-example"
              data-example-id=""
            >
              <div
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingOne"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>Can I refill my prescription online?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    aria-expanded="false"
                    style=""
                  >
                    <div class="card-block">
                      Yes, you can legally refill a prescription online after a virtual medical consultation
                      with an online doctor. At StepAhead Health, our licensed doctors can prescribe online
                      prescription refills for all non-controlled substances for up to 90 days.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingTwo"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>How do I get a refill on my prescription?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      You can get a refill for your prescription by talking to a doctor. You can either go
                      in to see your primary care physician, or book an online appointment with any
                      StepAhead Health doctor to get your refill even faster.
                      If your StepAhead Health doctor determines that your prescription refill
                      is medically necessary, then your prescription will be sent electronically to a
                      local pharmacy for pick up.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>How can I refill my prescription if I have run out of refills?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      If the pharmacy has told you that you have run out of refills on your prescription,
                      but you still need your medication, it is time to see a doctor.
                      The doctor will make sure you should still be taking the medication and can write
                      you a new prescription if you qualify and if needed. This can all be done online
                      through a virtual medical consultation at StepAhead Health.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>Where can I get an emergency prescription refill?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      Online doctors are the perfect way to get your emergency prescription refill prescription
                      refilled in an emergency. At StepAhead Health, we often have same day appointments available so
                      our doctors can see you right away. The average appointment lasts just 15 minutes and our
                      doctors can send your emergency prescription refill to the pharmacy of your choice. That
                      said, if you’re having a medical emergency, go to the hospital or call 911.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>Can you get a prescription refill at a different pharmacy?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      If you need to get your prescription refilled, but are not near your typical pharmacy,
                      that is no problem. Just select a different pharmacy while creating a prescription
                      that works for you and they will send your prescription there. StepAhead Health can send online
                      prescription refills to pharmacies in all 50 U.S. states.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>How can I refill my prescription without seeing a doctor?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      If you need a refill prescription, you may be able to speak with an online doctor to
                      request a prescription refill. This could help you save time without having to travel
                      to see a doctor in person. To speak with an online doctor at StepAhead Health about requesting
                      a prescription refill, you would first make an online appointment. During your virtual
                      consultation, the doctor will review your symptoms and medical history to determine
                      if you need a refill prescription. If the doctor writes you a prescription,
                      the prescription refill would then be sent to a pharmacy for you to pick up.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>A Note About Online Prescription Refills</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      Medications are prescribed at the sole discretion of a StepAhead Health physician.
                      Online prescription refills for non-controlled substances can only be written
                      after a medical evaluation. Scheduling an appointment does not guarantee that
                      a medication can or will be prescribed.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>
<style scoped>
.card-block {
  padding: 20px;
}
</style>
