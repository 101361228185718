<template>
  <div class="main-wrapper">
    <div class="content pt-1">
      <div class="container-fluid">
        <!-- Statistics -->
        <div class="row">
          <div class="col-12" v-if="$store.getters.user">
            <h4 class="mt-3">
              {{ $t("index.hi") }}, {{ $store.getters.user.first_name }}!
            </h4>
            <span class="text-italic"> {{ $t("index.description") }}</span>
          </div>
          <div class="col-12">
            <div class="row mt-1 pt-1">
              <div class="col-6 pr-1">
                <router-link to="/patient/prescriptions/rxrequests">
                  <div class="stati bg-card-1">
                    <i class="fas fa-file-prescription"></i>
                    <div>
                      <b>{{ inProgressPrescriptions.length }}</b>
                      <span class="text-light">
                        {{ $t("index.inProgress") }}</span
                      >
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-6 pl-1">
                <router-link to="/patient/prescriptions/rxrefills">
                  <div class="stati bg-card-2">
                    <i class="fas fa-prescription-bottle"></i>
                    <div>
                      <b>{{ approvedPrescriptions.length }}</b>
                      <span class="text-light">
                        {{ $t("index.approved") }}</span
                      >
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- CREATE PRESCRIPTION BUTTONS -->
        <!-- <hr /> -->
        <div class="row mt-2">
          <div class="col-6 pr-1">
            <div class="card card-btn btn-card-left">
              <router-link to="/patient/new-prescription">
                <div class="card-body text-center ml-0 mr-0">
                  <div class="mb-3">
                    <img
                      src="../../assets/icons/prescription.png"
                      alt=""
                      width="55"
                    />
                  </div>
                  <small> {{ $t("index.createFreeText") }}</small>
                  <h6>{{ $t("index.prescriptionRequest") }}</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-6 pl-1">
            <div class="card card-btn btn-card-right">
              <router-link to="/patient/new-refill">
                <div class="card-body text-center">
                  <div class="mb-3">
                    <img
                      src="../../assets/icons/refill.png"
                      alt=""
                      width="55"
                    />
                  </div>
                  <small>{{ $t("index.alreadyPrescribed") }}</small>
                  <h6>{{ $t("index.requestForRefill") }}</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <!-- ACCOUNT AND RX BUTTONS -->
        <div class="row mt-2">
          <div class="col-6 pr-1">
            <div class="card card-btn btn-card-left">
              <router-link to="/patient/prescriptions/rxrequests">
                <div class="card-body text-center ml-0 mr-0">
                  <div class="mb-3">
                    <img
                      src="../../assets/icons/requests.png"
                      alt=""
                      width="55"
                    />
                  </div>
                  <h6>{{ $t("index.myPrescriptions") }}</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-6 pl-1">
            <div class="card card-btn btn-card-right">
              <router-link to="/patient/account">
                <div class="card-body text-center">
                  <div class="mb-3">
                    <img
                      src="../../assets/icons/profile.png"
                      alt=""
                      width="55"
                    />
                  </div>
                  <h6>{{ $t("index.accountSettings") }}</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card announcements">
              <div class="card-body">
                <h6 style="color: #272b41" class="mb-3">
                  <i class="fas fa-bell mr-2"></i
                  >{{ $t("index.newsAnnouncements") }}
                </h6>
                <ul style="font-size: 12px">
                  <li class="mb-3">
                    <strong>{{ $t("index.date1") }}</strong
                    >: {{ $t("index.description1") }}
                  </li>
                  <li class="mb-3">
                    <strong>{{ $t("index.date2") }}</strong
                    >: {{ $t("index.description2") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PrescriptionDetails from "./PrescriptionDetails.vue";
const images = require.context(
  "../../assets/img/doctors",
  false,
  /\.png$|\.jpg$/
);
export default {
  components: {
    PrescriptionDetails: PrescriptionDetails,
  },
  data: function() {
    return {
      interval: null,
    };
  },
  async mounted() {
    if (this.interval) clearInterval(this.interval);

    if (this.$store.getters.user) {
      this.$socket.emit("REQUEST", {
        command: "GET:PRESCRIPTIONS",
        userId: this.$store.getters.user.id,
      });
    }

    this.interval = setInterval(() => {
      if (this.$store.getters.user) {
        this.$socket.emit("REQUEST", {
          command: "GET:PRESCRIPTIONS",
          userId: this.$store.getters.user.id,
        });
      }
    }, 5000);

    this.$store.dispatch("showLoader", false);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
  computed: {
    prescriptions() {
      return this.$store.getters.prescriptions;
    },
    inProgressPrescriptions() {
      return this.prescriptions && this.prescriptions.length > 0
        ? this.prescriptions.filter(
            (t) => t.status === "Accepted" || t.status === "In Progress"
          )
        : [];
    },
    approvedPrescriptions() {
      return this.prescriptions && this.prescriptions.length > 0
        ? this.prescriptions.filter((t) => t.status === "Completed")
        : [];
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    getStatusColor(status) {
      switch (status) {
        case "Awaiting":
          return "btn btn-sm bg-warning-light btn-block";

        case "In Progress":
          return "btn btn-sm bg-primary-light btn-block";

        case "Completed":
          return "btn btn-sm bg-success-light btn-block";

        case "Accepted":
          return "btn btn-sm bg-info-light btn-block";

        case "Cancelled":
          return "btn btn-sm bg-danger-light btn-block";

        default:
          return "btn btn-sm bg-primary-light btn-block";
      }
    },
  },
};
</script>
<style scoped>
.stati {
  background: #fff;
  height: 6em;
  padding: 1em;
  margin: 1em 0;
  -webkit-transition: margin 0.5s ease, box-shadow 0.5s ease;
  /* Safari */
  transition: margin 0.5s ease, box-shadow 0.5s ease;
  border-radius: 10px;
  /* -moz-box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8);
  -webkit-box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8);
  box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8); */
}

.stati i {
  font-size: 3.5em;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}

.stati div span {
  font-size: 14px;
  width: 100%;
  color: rgba(255, 255, 255, 0.8) !important;
  display: block;
}

.stati.left div {
  float: left;
  text-align: left;
}

.stati.bg-card-1 {
  background: rgb(15 215 241);
  color: white;
}

.stati.bg-card-2 {
  background: rgb(18 112 193);
  color: white;
}

.btn-card-left {
  -webkit-border-radius: 10px;
  border: none;
  border-radius: 10px;
  background: #f8f9fa;
  -webkit-box-shadow: 6px 6px 18px #e9eaeb, -6px -6px 18px #ffffff;
  box-shadow: 6px 6px 18px #e9eaeb, -6px -6px 18px #ffffff;
}

.btn-card-right {
  -webkit-border-radius: 10px;
  border: none;
  border-radius: 10px;
  background: #f8f9fa;
  -webkit-box-shadow: 6px 6px 18px #e9eaeb, 6px 6px 18px #ffffff;
  box-shadow: 6px 6px 18px #e9eaeb, 6px 6px 18px #ffffff;
}

.btn-card-left:hover,
.btn-card-right:hover {
  background: #fff;
  animation: press 0.1s 1 linear;
}

.announcements {
  border-radius: 10px;
}

@keyframes press {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.92);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: rotate(5deg) translate(20px, -50px);
  }

  to {
    transform: scale(0.9) rotate(10deg) translate(50px, -80px);
    opacity: 0;
  }
}
</style>
