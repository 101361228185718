<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0 m-0">
          <div class="banner-wrapper">
            <div class="mb-3 text-center">
              <h4>Review Prescription Request</h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-2" style="font-size: 11px">
                  <h5>Patient Information</h5>
                  <hr />
                  <p>
                    Name: {{ patientInfo.firstName }} {{ patientInfo.lastName }}
                  </p>
                  <p>Email: {{ patientInfo.email }}</p>
                  <p>Mobile: {{ patientInfo.mobileNumber }}</p>
                  <p>DOB: {{ patientInfo.dob }}</p>
                  <p v-if="patientInfo.address">
                    Address: {{ patientInfo.address.addressLine1 }},
                    {{ patientInfo.address.addressLine2 }},
                    {{ patientInfo.address.city }},
                    {{ patientInfo.address.state }} -
                    {{ patientInfo.address.zipCode }}
                  </p>
                </div>
                <div class="col-12 mb-2" style="font-size: 11px">
                  <hr />
                  <h5>Patient Observations</h5>
                  <hr />
                  <p>
                    Current Medications: {{ patientInfo.currentMedications }}
                  </p>
                  <p>Known Allergies: {{ patientInfo.knownAllergies }}</p>
                  <p>
                    Reason for Medication: {{ patientInfo.reasonForMedication }}
                  </p>
                </div>
                <div class="col-12 mb-2" style="font-size: 11px">
                  <hr />
                  <h5>Requested Refill Medicines</h5>
                  <hr />
                  <ul>
                    <li v-for="(medicine, i) in medicineDetails" :key="i">
                      {{ medicine.name }}
                      <span style="font-size: 11px"
                        >{{ medicine.quantity }}
                        {{ medicine.unit ? medicine.unit.Plural : "" }}</span
                      >
                    </li>
                  </ul>
                  <p>
                    Substitutions:
                    <strong>{{
                      allowSubstitutions ? "Allowed" : "Not Allowed"
                    }}</strong>
                  </p>
                </div>
                <div class="col-12 mb-2" style="font-size: 11px">
                  <hr />
                  <h5>Pharmacy Details</h5>
                  <hr />
                  <p>Store Number: {{ pharmacyDetails.pharmacyId }}</p>
                  <p>Store Name: {{ pharmacyDetails.pharmacyName }}</p>
                  <p>Phone: {{ pharmacyDetails.phone }}</p>
                  <p v-if="pharmacyDetails.address">
                    Address: {{ pharmacyDetails.address.addressLine1 }},
                    {{ pharmacyDetails.address.addressLine2 }},
                    {{ pharmacyDetails.address.city }},
                    {{ pharmacyDetails.address.state }} -
                    {{ pharmacyDetails.address.zipCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card text-justify">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <p style="font-size: 12px">
                    After you submit your request, a licensed medical provider
                    in your area will review your information. You will be
                    notified by an email and SMS when that provider has reviewed
                    your request and, if appropriate, made care recommendations.
                  </p>
                </div>
                <div class="col-12 col-md-6">
                  <p style="font-size: 12px">
                    By clicking the "Confirm and Send Request" button below, you
                    indicate that you, as the patient or legal guardian of the
                    patient, agree to the Terms of Service and Consent to
                    Telehealth.
                  </p>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-12">
                  <button
                    @click="submitRequest"
                    class="btn btn-info btn-block mb-2"
                  >
                    Confirm & Send Request <i class="fa fa-arrow-right"></i>
                  </button>
                </div>
                <div class="col-12">
                  <router-link to="/patient/index">
                    <button
                      class="btn btn-sm btn-outline-danger btn-block mb-4"
                    >
                      <i class="fa fa-close"></i> Cancel
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    pharmacyDetails: Object,
    medicineDetails: Object,
    patientInfo: Object,
    dosageInfo: Object,
    allowSubstitutions: Boolean,
  },
  methods: {
    ...mapActions(["showLoader"]),
    submitRequest() {
      let providerId = null;

      if (this.$store.getters.user.assignedProviders) {
        const obj = this.$store.getters.user.assignedProviders.find(
          (e) => e.state === this.pharmacyDetails.state
        );

        if (obj) providerId = obj.providerId;
      }

      this.$emit(
        "select",
        JSON.stringify({
          id: this.$store.getters.user.id,
          patientId: this.$store.getters.user.patientId,
          pharmacy: {
            pharmacy_id: this.pharmacyDetails.pharmacyId,
            pharmacy_name: this.pharmacyDetails.pharmacyName,
            address1: this.pharmacyDetails.address.addressLine1,
            address2: this.pharmacyDetails.address.addressLine2,
            phone: this.pharmacyDetails.phone,
            city: this.pharmacyDetails.address.city,
            state: this.pharmacyDetails.address.state,
            zip_code: this.pharmacyDetails.address.zipCode,
          },
          medicines: this.medicineDetails,
          dosageInfo: {
            currentMedications: this.patientInfo.currentMedications,
            knownAllergies: this.patientInfo.knownAllergies,
            reasonForMedication: this.patientInfo.reasonForMedication,
          },
          allowSubstitutions: this.allowSubstitutions,
          providerId: providerId,
        })
      );
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 400px;
  width: 450px;
}
</style>
