<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row" v-if="pharmacyResults.length === 0">
        <div class="col-12 p-2 m-0">
          <div class="banner-wrapper-pharmacy">
            <div class="banner-header text-center">
              <h4>
                {{ $t("changePharmacy.changePharmacySearch.title") }}
              </h4>
            </div>

            <!-- Search -->
            <div class="mt-3">
              <!-- PHARMACY NAME SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>
                    {{
                      $t(
                        "changePharmacy.changePharmacySearch.pharmacyNameLabel"
                      )
                    }}</small
                  >
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      placeholder="Ex: Shollenberger Pharmacy"
                      v-model="searchName"
                    />
                  </div>
                </div>
              </div>

              <!-- CITY SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>{{
                    $t("changePharmacy.changePharmacySearch.cityLabel")
                  }}</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      placeholder="Ex: Atlanta, New York"
                      v-model="searchCity"
                    />
                  </div>
                </div>
              </div>

              <!-- STATE SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>{{
                    $t("changePharmacy.changePharmacySearch.stateCodeLabel")
                  }}</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      placeholder="Ex: GA, NY"
                      v-model="searchState"
                    />
                  </div>
                </div>
              </div>

              <!-- ZIP CODE SEARCH -->
              <div class="row">
                <div class="col-12">
                  <small>{{
                    $t("changePharmacy.changePharmacySearch.zipLabel")
                  }}</small>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <input
                      type="number"
                      class="form-control form-control-xs"
                      placeholder="Ex: 60601, 85001"
                      v-model="searchZipCode"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12" v-show="specialtyFilter">
                <small>{{
                  $t("changePharmacy.changePharmacySearch.selectSpeciality")
                }}</small>
              </div>
              <div class="col-12" v-show="specialtyFilter">
                <div class="form-group mb-3">
                  <select
                    v-model="searchSpecialty"
                    class="form-control form-control-xs"
                  >
                    <option value="2048" selected>{{
                      $t("changePharmacy.changePharmacySearch.epcs")
                    }}</option>
                    <option value="64">{{
                      $t("changePharmacy.changePharmacySearch.hourPharmacy")
                    }}</option>
                    <option value="32">{{
                      $t("changePharmacy.changePharmacySearch.longTermCare")
                    }}</option>
                    <option value="1">{{
                      $t("changePharmacy.changePharmacySearch.mailOrder")
                    }}</option>
                    <option value="8">{{
                      $t("changePharmacy.changePharmacySearch.retail")
                    }}</option>
                    <option value="16">{{
                      $t(
                        "changePharmacy.changePharmacySearch.specialityPharmacy"
                      )
                    }}</option>
                  </select>
                  <span class="form-text text-muted">{{
                    $t("changePharmacy.changePharmacySearch.exLabel")
                  }}</span>
                </div>
              </div>
              <div class="col-12 p-4 text-right">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="specialtyFilter"
                  />
                  <label class="form-check-label mt-1" for="flexCheckDefault">
                    {{
                      $t(
                        "changePharmacy.changePharmacySearch.filterBySpeciality"
                      )
                    }}
                  </label>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-12">
                <button
                  @click="startSearching"
                  class="btn btn-primary mt-0 btn-block"
                >
                  <i class="fas fa-search"></i>
                  <span>
                    {{ $t("changePharmacy.changePharmacySearch.title") }}</span
                  >
                </button>
              </div>
              <div class="col-12">
                <button
                  @click="$emit('cancel')"
                  class="btn btn-sm btn-secondary mt-2 btn-block"
                >
                  <i class="fas fa-close"></i>
                  <span>
                    {{ $t("changePharmacy.changePharmacySearch.btn2") }}</span
                  >
                </button>
              </div>
            </div>
            <!-- /Search -->
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-12">
            <div class="banner-header">
              <h5>
                Great! We've Found
                {{ filteredPharmacyResults.length }} Pharmacies Near You
              </h5>
            </div>
          </div>
          <div class="col-12">
            <button
              @click="restartSearch"
              class="btn btn-sm btn-outline-info btn-block"
            >
              Search Again
            </button>
          </div>
        </div>
        <hr />
        <!-- Filters -->
        <div class="row" style="font-size: 10px">
          <div class="col-12">
            <p class="mb-2"><strong>Filter by:</strong></p>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="specialtyFilter"
                v-model="specialtyCheckFilter"
              />
              <label class="form-check-label" for="specialtyFilter"
                >Specialty</label
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="mailOrderFilter"
                v-model="mailOrderFilter"
              />
              <label class="form-check-label" for="mailOrderFilter"
                >Mail Order</label
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="retailFilter"
                v-model="retailFilter"
              />
              <label class="form-check-label" for="retailFilter">Retail</label>
            </div>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="epcsFilter"
                v-model="epcsFilter"
              />
              <label class="form-check-label" for="epcsFilter">EPCS</label>
            </div>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="24HourFilter"
                v-model="twentyFourHourFilter"
              />
              <label class="form-check-label" for="24HourFilter">24 Hour</label>
            </div>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="longTermFilter"
                v-model="longTermFilter"
              />
              <label class="form-check-label" for="longTermFilter"
                >Long Term Care</label
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 p-0 m-0">
            <div
              class="card"
              v-for="(pharmacy, index) in filteredPharmacyResults"
              :key="index"
            >
              <div class="card-body">
                <div class="row">
                  <!-- Name address -->
                  <div class="col-12">
                    <h4 class="mb-2">
                      {{ pharmacy.StoreName }} - {{ pharmacy.PharmacyId }}
                    </h4>
                    <div
                      class="rating mb-2 mapgridrating1"
                      style="font-size: 10px"
                    >
                      <span class="d-inline-block average-rating">{{
                        getStoreAddress(pharmacy)
                      }}</span>
                    </div>
                    <div class="clinic-details">
                      <div class="clini-infos">
                        <p class="doc-location mb-1">
                          <i class="fas fa-phone-volume"></i>
                          Phone: {{ pharmacy.PrimaryPhone }}
                        </p>
                        <p class="doc-location mb-1">
                          <i class="fas fa-fax"></i> Fax:
                          {{ pharmacy.PrimaryFax }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Types -->
                  <div class="col-12" style="font-size: 10px">
                    <h6>Supported Types:</h6>
                    <span
                      v-if="!pharmacy.PharmacySpecialties"
                      class="badge badge-dark mr-2"
                      >NONE</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                          pharmacy.PharmacySpecialties.indexOf('Retail') >= 0
                      "
                      class="badge badge-info mr-2"
                      >RETAIL</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                          pharmacy.PharmacySpecialties.indexOf('Mail Order') >=
                            0
                      "
                      class="badge badge-info mr-2"
                      >MAIL ORDER</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                          pharmacy.PharmacySpecialties.indexOf('EPCS') >= 0
                      "
                      class="badge badge-info mr-2"
                      >EPCS</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                          pharmacy.PharmacySpecialties.indexOf(
                            'Specialty Pharmacy'
                          ) >= 0
                      "
                      class="badge badge-info mr-2"
                      >SPECIALTY</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                          pharmacy.PharmacySpecialties.indexOf(
                            'Long-Term Care Pharmacy'
                          ) >= 0
                      "
                      class="badge badge-info mr-2"
                      >LONG-TERM CARE</span
                    >
                  </div>

                  <!-- BUttons -->
                  <div class="col-12">
                    <div class="clinic-booking">
                      <a
                        href="javascript:void();"
                        class="apt-btn mt-3 mb-2 btn-sm"
                        @click="selectPharmacy(pharmacy)"
                        >Select Pharmacy</a
                      >
                      <a
                        :href="
                          `https://www.google.com/maps/@${pharmacy.Latitude},${pharmacy.Longitude},15z`
                        "
                        target="blank"
                        class="view-pro-btn"
                        >See on Google Maps</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import Swal from "sweetalert2";
const images = require.context(
  "../../assets/img/stores/",
  false,
  /\.png$|\.jpg$/
);

export default {
  mixins: [commonMixin],
  data() {
    return {
      searchName: "",
      searchCity: "",
      searchState: "",
      searchZipCode: "",
      searchSpecialty: "",
      specialtyFilter: false,
      pharmacyResults: [],
      filteredPharmacyResults: [],
      searchOption: "name",
      specialtyCheckFilter: true,
      epcsFilter: true,
      mailOrderFilter: true,
      twentyFourHourFilter: true,
      longTermFilter: true,
      retailFilter: true,
    };
  },
  watch: {
    specialtyFilter: function() {
      if (!this.specialtyFilter) this.searchSpecialty = "";
    },
    specialtyCheckFilter: function() {
      this.filterData();
    },
    epcsFilter: function() {
      this.filterData();
    },
    mailOrderFilter: function() {
      this.filterData();
    },
    twentyFourHourFilter: function() {
      this.filterData();
    },
    longTermFilter: function() {
      this.filterData();
    },
    retailFilter: function() {
      this.filterData();
    },
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    filterData() {
      const retails = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties && p.PharmacySpecialties.indexOf("Retail") > -1
      );
      const specialty = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Specialty Pharmacy") > -1
      );
      const mailOrders = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Mail Order") > -1
      );
      const epcs = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties && p.PharmacySpecialties.indexOf("EPCS") > -1
      );
      const longTerm = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Long-Term Care Pharmacy") > -1
      );

      let filtered = [];
      if (this.specialtyCheckFilter) filtered = filtered.concat(specialty);
      if (this.retailFilter) filtered = filtered.concat(retails);
      if (this.mailOrderFilter) filtered = filtered.concat(mailOrders);
      if (this.epcsFilter) filtered = filtered.concat(epcs);
      if (this.longTermFilter) filtered = filtered.concat(longTerm);

      filtered = filtered.concat(
        this.pharmacyResults.filter((p) => !p.PharmacySpecialties)
      );

      this.filteredPharmacyResults = filtered.filter(
        (item, pos) => filtered.indexOf(item) === pos
      );
    },
    restartSearch() {
      this.searchZipCode = "";
      this.searchCity = "";
      this.pharmacyResults = [];
      this.filteredPharmacyResults = [];
    },
    async startSearching() {
      try {
        if (
          String(this.searchZipCode).trim().length === 0 &&
          String(this.searchCity).trim().length === 0 &&
          String(this.searchState).trim().length === 0 &&
          String(this.searchName).trim().length === 0 &&
          String(this.searchSpecialty).trim().length === 0
        ) {
          Swal.fire(
            "Oops!",
            "Please enter a name, city, state, zip or specialty to find the pharmacies",
            "error"
          );
          return;
        }

        this.showLoader(true);

        let query = "?";
        if (String(this.searchName).trim().length > 0)
          query += `name=${this.searchName}&`;
        if (String(this.searchCity).trim().length > 0)
          query += `city=${this.searchCity}&`;
        if (String(this.searchState).trim().length > 0)
          query += `state=${this.searchState}&`;
        if (String(this.searchZipCode).trim().length > 0)
          query += `zip=${this.searchZipCode}&`;
        if (String(this.searchSpecialty).trim().length > 0)
          query += `specialty[0]=${this.searchSpecialty}&`;

        query = query.slice(0, -1);

        this.pharmacyResults = await this.searchPharmacy(query);

        if (this.pharmacyResults.length === 0) {
          Swal.fire(
            "No Pharmacies Found",
            "We did not find any pharmacies in your search criteria.",
            "warning"
          );
        }

        this.filteredPharmacyResults = this.pharmacyResults;
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    selectPharmacy(pharmacyDetails) {
      this.$emit(
        "select",
        JSON.stringify({
          pharmacyId: pharmacyDetails.PharmacyId,
          pharmacyName: pharmacyDetails.StoreName,
          address: {
            addressLine1: pharmacyDetails.Address1,
            addressLine2: pharmacyDetails.Address2,
            city: pharmacyDetails.City,
            state: pharmacyDetails.State,
            zipCode: pharmacyDetails.ZipCode,
          },
          phone: pharmacyDetails.PrimaryPhone,
        })
      );
    },
    getStoreAddress(addressObject) {
      return `${addressObject.Address1}, ${addressObject.Address2}, ${addressObject.City}, ${addressObject.State} - ${addressObject.ZipCode}`;
    },

    getImage(storeBrand) {
      switch (String(storeBrand).toLowerCase()) {
        case "walgreens":
          return images("./walgreens.jpg").default;

        default:
          return images("./notavailable.png").default;
      }
    },
  },
};
</script>
<style scoped>
.banner-wrapper-pharmacy {
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
}

.section-search {
  min-height: 570px;
}

.pharmacy-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pharmacy-search-box .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  padding-left: 35px;
}

.pharmacy-search-box .search-location {
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
  margin-right: 12px;
  position: relative;
  width: 280px;
}

.pharmacy-search-box .search-filter {
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  margin-right: 12px;
  position: relative;
  width: 180px;
}

.pharmacy-search-box .search-info {
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
  margin-right: 12px;
  position: relative;
  width: 400px;
}

.search-info .form-control {
  background: #fff url(../../assets/img/search.png) no-repeat 10px center;
}

.search-filter .form-control {
  background: #fff url(../../assets/img/search.png) no-repeat 10px center;
}

.pharmacy-search-box .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
}

.pharmacy-search-box .search-btn span {
  display: none;
  font-weight: 500;
}

.pharmacy-search-box .form-text {
  color: #757575;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .pharmacy-search-box .search-info {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }

  .pharmacy-search-box .search-filter {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }
}
</style>
