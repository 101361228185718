<template>
  <footer
    v-show="$store.getters.user && $store.getters.user.is_registration_complete"
    class="footer sticky-footer"
  >
    <div class="row text-center m-0 p-0">
      <div class="col-3 m-0 p-0 btn">
        <router-link :to="`/provider/index`">
          <i :class="`fas fa-home menu-btns ${homeTextColor}`"></i>
          <small :class="`menu-txt ${homeTextColor}`">Home</small>
        </router-link>
      </div>
      <div class="col-3 m-0 p-0 btn">
        <router-link :to="`/provider/prescriptions`">
          <i
            :class="`fas fa-file-prescription menu-btns ${prescriptionsTextColor}`"
          ></i>
          <small :class="`menu-txt ${prescriptionsTextColor}`">Requests</small>
        </router-link>
      </div>
      <!-- <div class="col-3 m-0 p-0 btn">
        <router-link :to="`/provider/appointments`">
          <i :class="`fas fa-calendar menu-btns ${appointmentsTextColor}`"></i>
          <small :class="`menu-txt ${appointmentsTextColor}`"
            >Appointments</small
          >
        </router-link>
      </div>
      <div class="col-3 m-0 p-0 btn">
        <router-link :to="`/provider/patients`">
          <i :class="`fas fa-user menu-btns ${patientsTextColor}`"></i>
          <small :class="`menu-txt ${patientsTextColor}`">Patients</small>
        </router-link>
      </div> -->
      <div class="col-3 m-0 p-0 btn">
        <router-link :to="`/provider/finances`">
          <i :class="`fas fa-receipt menu-btns ${paymentsTextColor}`"></i>
          <small :class="`menu-txt ${paymentsTextColor}`">Finances</small>
        </router-link>
      </div>
      <div class="col-3 m-0 p-0 btn">
        <router-link to="/provider/settings">
          <i :class="`fas fa-user-cog menu-btns ${profileTextColor}`"></i>
          <small :class="`menu-txt ${profileTextColor}`">Account</small>
        </router-link>
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
export default {
  data() {
    return {
      homeTextColor: "",
      prescriptionsTextColor: "",
      appointmentsTextColor: "",
      patientsTextColor: "",
      paymentsTextColor: "",
      profileTextColor: "",
    };
  },

  computed: {
    device() {
      return this.$store.getters["gis/deviceType"] === "TABLET"
        ? "tablet"
        : "phone";
    },
    currentRouteName() {
      return this.$route.name;
    },
  },

  mounted() {
    this.setActiveLinkClass();
  },

  watch: {
    currentRouteName: function () {
      this.setActiveLinkClass();
    },
  },

  methods: {
    setActiveLinkClass() {
      this.prescriptionsTextColor = "";
      this.appointmentsTextColor = "";
      this.patientsTextColor = "";
      this.paymentsTextColor = "";
      this.profileTextColor = "";
      this.homeTextColor = "";

      switch (this.currentRouteName) {
        case "provider-index":
          this.homeTextColor = "text-light";
          break;
        case "provider-appointments":
          this.appointmentsTextColor = "text-light";
          break;
        case "provider-patients":
          this.patientsTextColor = "text-light";
          break;
        case "provider-account-settings":
          this.profileTextColor = "text-light";
          break;
        case "provider-finances":
          this.paymentsTextColor = "text-light";
          break;
        case "provider-prescriptions":
          this.prescriptionsTextColor = "text-light";
          break;
      }
    },
  },
};
</script>
<style>
.menu-btns {
  color: rgb(189, 189, 189);
  font-size: 18px;
  margin: 12px 0px 0px 0px;
  display: block;
}

.menu-txt {
  font-size: 8px;
  color: rgb(189, 189, 189);
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 9999;
}

.create-btn {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 3px solid #2e6a9b;
  position: fixed;
  /* bottom: 10px; */
  /* left: 50%; */
  /* margin-bottom: 10px; */
  /* font-size: 40px; */
  color: #2e6a9b;
  background-color: white;
}

.create-btn-icon {
  font-size: 40px;
}

.create-btn:hover {
  animation: ripple 0.5s;
}

.btn:hover {
  animation: ripple 0.5s;
}

/* Add animation */
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10);
  }
}

.modal-backdrop {
  z-index: -1;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>