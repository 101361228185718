<template>
  <div class="account-page account-page-h100">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content bg-white mt-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <!-- Login Content -->
              <div class="account-content text-center">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12">
                    <img
                      src="../assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Stepahead Register"
                      width="250"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="login-header text-center mt-3">
                      <img
                        src="../assets/img/logo.png"
                        class="img-fluid mb-5"
                        alt="stepaheadhealth"
                        width="250"
                      />
                      <h3 class="mb-3">Account Registration</h3>
                      <h5 class="text-muted">Who are you?</h5>
                    </div>
                    <div class="row form-row social-login mt-4">
                      <div class="col-12">
                        <router-link to="/patient/register">
                          <button class="btn btn-primary btn-block">
                            <i class="fa fa-user mr-2"></i> I'm a New Patient
                          </button>
                        </router-link>
                      </div>
                    </div>
                    <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">or</span>
                    </div>
                    <div class="row form-row social-login">
                      <div class="col-12">
                        <router-link to="/provider/register">
                          <button class="btn btn-info btn-block">
                            <i class="fa fa-plus mr-2"></i> I'm a Provider
                          </button>
                        </router-link>
                      </div>
                    </div>

                    <!-- /Register Form -->
                    <div class="text-center mt-5 dont-have">
                      <div class="row">
                        <div class="col-6 float-left">
                          <router-link class="forgot-link" to="/login"
                            >Back to login</router-link
                          >
                        </div>

                        <div class="col-6 float-right">
                          <router-link class="forgot-link" to="/forgot-password"
                            >Forgot password?</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Login Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    if (this.$store.getters.user) {
      switch (this.$store.getters.user.role) {
        case "patient":
          if (!this.$store.getters.user.isRegistrationComplete) {
            this.$router.push(
              `/patient/step-${this.$store.getters.user.current_registration_step}`
            );
          } else this.$router.push("/patient/index");
          break;
        case "doctor":
          this.$router.push("/provider/index");
          break;
      }
    }
  },
};
</script>