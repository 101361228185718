<template>
  <div class="chat-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="chat-window">
                <!-- Chat Right -->
                <div class="chat-cont-right">
                  <div class="chat-header">
                    <div class="media">
                      <div class="media-img-wrap">
                        <div class="avatar">
                          <img :src="providerImage" alt="User Image" class="avatar-img rounded-circle" />
                        </div>
                      </div>
                      <div class="user-name">
                        <h4>{{ providerName }}</h4>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="chat-options">
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#voice_call"
                      >
                        <i class="material-icons">local_phone</i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#video_call"
                      >
                        <i class="material-icons">videocam</i>
                      </a>
                      <a href="javascript:void(0)">
                        <i class="material-icons">more_vert</i>
                      </a>
                    </div> -->
                  <div class="chat-body">
                    <div id="chatbox" class="chat-scroll">
                      <ul class="list-unstyled">
                        <div v-for="(msgObj, i) in chatMessages" :key="i">
                          <li :class="`mb-2 media ${msgObj.user_id === this.$store.getters.user.id
                            ? 'sent'
                            : 'received'
                            }`">
                            <div class="avatar" v-if="msgObj.user_id !== this.$store.getters.user.id
                            ">
                              <img :src="providerImage" :alt="providerName" class="avatar-img rounded-circle" />
                            </div>
                            <div class="media-body">
                              <div class="msg-box">
                                <div>
                                  <p>{{ msgObj.message }}</p>
                                  <ul class="chat-msg-info">
                                    <li>
                                      <div class="chat-time">
                                        <span style="font-size: 10px">{{
                            new Date(
                              msgObj.created_at
                            ).toLocaleString()
                          }}</span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>

                        <!-- <li class="media received">
                          <div class="avatar">
                            <img
                              src="../../assets/img/doctors/doctor-thumb-02.jpg"
                              alt="User Image"
                              class="avatar-img rounded-circle"
                            />
                          </div>
                          <div class="media-body">
                            <div class="msg-box">
                              <div>
                                <p>I'm just looking around.</p>
                                <p>
                                  Will you tell me something about yourself?
                                </p>
                                <ul class="chat-msg-info">
                                  <li>
                                    <div class="chat-time">
                                      <span>8:35 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="msg-box">
                              <div>
                                <p>Are you there? That time!</p>
                                <ul class="chat-msg-info">
                                  <li>
                                    <div class="chat-time">
                                      <span>8:40 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="msg-box">
                              <div>
                                <div class="chat-msg-attachments">
                                  <div class="chat-attachment">
                                    <img
                                      src="../../assets/img/img-02.jpg"
                                      alt="Attachment"
                                    />
                                    <div class="chat-attach-caption">
                                      placeholder.jpg
                                    </div>
                                    <a href="" class="chat-attach-download">
                                      <i class="fas fa-download"></i>
                                    </a>
                                  </div>
                                  <div class="chat-attachment">
                                    <img
                                      src="../../assets/img/img-03.jpg"
                                      alt="Attachment"
                                    />
                                    <div class="chat-attach-caption">
                                      placeholder.jpg
                                    </div>
                                    <a href="" class="chat-attach-download">
                                      <i class="fas fa-download"></i>
                                    </a>
                                  </div>
                                </div>
                                <ul class="chat-msg-info">
                                  <li>
                                    <div class="chat-time">
                                      <span>8:41 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <form @submit.prevent="sendMessage" v-if="chatEnabled">
                      <div class="input-group">
                        <!-- <div class="input-group-prepend">
                        <div class="btn-file btn">
                          <i class="fa fa-paperclip"></i>
                          <input type="file" />
                        </div>
                      </div> -->
                        <input type="text" class="input-msg-send form-control" placeholder="Write a message"
                          v-model="message" required />
                        <div class="input-group-append">
                          <button type="submit" class="btn msg-send-btn">
                            <i class="fab fa-telegram-plane"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="text-center" v-else>
                      <h5 class="mt-2">The chat has been closed</h5>
                    </div>
                  </div>
                </div>
                <!-- /Chat Right -->
              </div>
            </div>
          </div>
          <!-- /Row -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
export default {
  props: {
    prescriptionId: String,
    providerName: String,
    providerImage: String,
    status: String,
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    chatMessages() {
      return this.$store.getters.chatMessages;
    },
    chatEnabled() {
      return this.status === "Completed" || this.status === "Cancelled"
        ? false
        : true;
    },
  },
  watch: {
    chatMessages: function (newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        var elem = document.getElementById("chatbox");
        elem.scrollTop = elem.scrollHeight;
        const message = newVal[newVal.length - 1]
        if (message && message.user_id !== this.$store.getters.user.id) {
          Swal.fire({
            position: "top-end",
            title: "New message alert!",
            html: 'provider message' + " : " + message.message,
            icon: 'info',
            showConfirmButton: false,
            timer: 5000,
            customClass: {
              popup: 'my-custom-popup-class',
              title: 'my-custom-title-class',
              content: 'my-custom-content-class'
            }
          });
        }
      }
    },
  },
  methods: {
    sendMessage() {
      this.$socket.emit("REQUEST", {
        command: "POST:WRITE_MESSAGE",
        userId: this.$store.getters.user.id,
        prescriptionId: this.prescriptionId,
        message: this.message,
      });

      this.message = "";
    },
  },
};
</script>
<style scoped>
.chat-window .chat-scroll {
  max-height: 100vh;
  height: calc(100vh - 450px);
}
</style>