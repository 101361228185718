<template>
  <div class="content success-page-cont">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <!-- Success Card -->
          <div class="card success-card">
            <div class="card-body">
              <div class="success-cont">
                <i class="fas fa-check"></i>
                <h3>Payment Successful</h3>
                <p>
                  Your prescription request with ID:
                  <strong>{{ prescriptionNumber }}</strong> will <br />soon be
                  reviewed by your medical provider and the pharmacy.
                </p>
                <router-link to="/patient/index" class="btn btn-primary view-inv-btn">Return to Dashboard</router-link>
                <p class="mt-3">
                  <small>You will be redirected to the dashboard in
                    {{ redirectTime }} seconds.</small><br />
                  <small><a href="/patient/index">Click here to redirect immediately</a></small>
                </p>
              </div>
            </div>
          </div>
          <!-- /Success Card -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionNumber: String,
  },
  data () {
    return {
      redirectTime: 5,
    };
  },
  mounted () {
    const int = setInterval(() => {
      if (this.redirectTime === 0) {
        clearInterval(int);
        this.$router.push("/patient/index");
        return;
      }

      this.redirectTime -= 1;
    }, 1000);
  },
};
</script>