<template>
  <div>
    <div class="main-wrapper main-wrapper-adjust-profile">
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-pills nav-fill nav-custom ml-1 mr-1 mt-2">
            <li class="nav-item">
              <a
                :class="`nav-link show active`"
                href="javascript:void();"
                id="inprogress-btn"
                data-toggle="pill"
                data-target="#inprogress"
                >In Progress
                <span class="badge badge-info">{{
                  acceptedPrescriptions.length
                }}</span></a
              >
            </li>
            <li class="nav-item">
              <a
                :class="`nav-link`"
                href="javascript:void();"
                data-toggle="pill"
                id="completed-btn"
                data-target="#completed"
                >Completed
                <span class="badge badge-info">{{
                  completedPrescriptions.length
                }}</span></a
              >
            </li>
            <li class="nav-item">
              <a
                :class="`nav-link`"
                href="javascript:void();"
                data-toggle="pill"
                id="rejected-btn"
                data-target="#rejected"
                >Cancelled
                <span class="badge badge-info">{{
                  rejectedPrescriptions.length
                }}</span></a
              >
            </li>
          </ul>
          <div class="tab-content mt-0">
            <!-- SHOW IN PROGRESS REQUESTS -->
            <div
              :class="`tab-pane fade show active`"
              id="inprogress"
              role="tabpanel"
              aria-labelledby="inprogress-tab"
            >
              <div v-if="acceptedPrescriptions.length > 0" class="list-holder">
                <div
                  class="card shadow-sm bg-white rounded m-2 mb-2"
                  v-for="(prescription, i) in acceptedPrescriptions"
                  :key="i"
                >
                  <router-link :to="`/provider/request/${prescription.id}`">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5">
                          Request ID:
                          <strong>{{ prescription.id }}</strong>
                          <p class="mb-0 mt-2">
                            <span
                              v-if="
                                prescription.status === 'Accepted' &&
                                  prescription.is_payment_completed
                              "
                              :class="'w-100'"
                            >
                              <strong class="text-warning"
                                >Approval Pending</strong
                              >
                            </span>
                            <span v-else>
                              <strong
                                :class="getStatusTextColor(prescription.status)"
                                >{{
                                  pharmacyStatus(prescription.status)
                                }}</strong
                              >
                            </span>
                          </p>
                        </div>
                        <div class="col-7">
                          Patient:
                          {{
                            getTrimmedName(
                              `${prescription.patient.first_name} ${prescription.patient.last_name}`
                            )
                          }}
                          <p class="mb-0 mt-2">
                            Date:
                            {{
                              new Date(prescription.created_at).toDateString()
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div v-else class="p-5 text-center">
                <h5>Nothing Yet!</h5>
              </div>
            </div>

            <!-- SHOW COMPLETED REQUESTS -->
            <div
              :class="`tab-pane fade`"
              id="completed"
              role="tabpanel"
              aria-labelledby="completed-tab"
            >
              <div v-if="completedPrescriptions.length > 0" class="list-holder">
                <div
                  class="card shadow-sm bg-white rounded m-1"
                  v-for="(prescription, i) in completedPrescriptions"
                  :key="i"
                >
                  <router-link :to="`/provider/request/${prescription.id}`">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5">
                          Request ID:
                          <strong>{{ prescription.id }}</strong>
                          <p class="mb-0 mt-2">
                            <span>
                              <strong class="text-success">{{
                                prescription.status
                              }}</strong>
                            </span>
                          </p>
                        </div>
                        <div class="col-7">
                          Patient:
                          {{
                            getTrimmedName(
                              `${prescription.patient.first_name} ${prescription.patient.last_name}`
                            )
                          }}
                          <p class="mb-0 mt-2">
                            Date:
                            {{
                              new Date(prescription.created_at).toDateString()
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div v-else class="p-5 text-center">
                <h5>Nothing Yet!</h5>
              </div>
            </div>

            <!-- SHOW REJECTED REQUESTS -->
            <div
              :class="`tab-pane fade`"
              id="rejected"
              role="tabpanel"
              aria-labelledby="rejected-tab"
            >
              <div v-if="rejectedPrescriptions.length > 0" class="list-holder">
                <div
                  class="card shadow-sm bg-white rounded m-1"
                  v-for="(prescription, i) in rejectedPrescriptions"
                  :key="i"
                >
                  <router-link :to="`/provider/request/${prescription.id}`">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5">
                          Request ID:
                          <strong>{{ prescription.id }}</strong>
                          <p class="mb-0 mt-2">
                            <span>
                              <strong class="text-danger">{{
                                prescription.status === "Cancelled"
                                  ? "Cancelled"
                                  : pharmacyStatus(prescription.status)
                              }}</strong>
                            </span>
                          </p>
                        </div>
                        <div class="col-7">
                          Patient:
                          {{
                            getTrimmedName(
                              `${prescription.patient.first_name} ${prescription.patient.last_name}`
                            )
                          }}
                          <p class="mb-0 mt-2">
                            Date:
                            {{
                              new Date(prescription.created_at).toDateString()
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div v-else class="p-5 text-center">
                <h5>Nothing Yet!</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Content -->
      <!-- <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="card card-table mb-0">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-hover table-center mb-0">
                          <thead>
                            <tr>
                              <th>Request ID</th>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Provider</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(prescription, i) in prescriptions"
                              :key="i"
                            >
                              <td>
                                <strong>{{ prescription.id }}</strong>
                              </td>
                              <td>
                                {{
                                  new Date(
                                    prescription.created_at
                                  ).toLocaleString()
                                }}
                              </td>
                              <td>
                                <span v-if="prescription.type === 'refill'">
                                  {{ normalizeString("Refill") }}
                                </span>
                                <span v-else>
                                  {{ normalizeString("Free Text") }}
                                </span>
                              </td>
                              <td>
                                <h2
                                  class="table-avatar"
                                  v-if="
                                    (prescription.patient &&
                                      prescription.status === 'In Progress') ||
                                    prescription.status === 'Accepted' ||
                                    prescription.status === 'Completed' ||
                                    prescription.status === 'Cancelled'
                                  "
                                >
                                  <a
                                    v-if="prescription.patient"
                                    href="javascript:void();"
                                    class="avatar avatar-sm mr-2"
                                  >
                                    <img
                                      class="avatar-img rounded-circle"
                                      :src="
                                        prescription.patient.profile_image_url
                                      "
                                      alt="User Image"
                                    />
                                  </a>
                                  <a href="javascript:void();"
                                    >{{ prescription.patient.first_name }}
                                    {{ prescription.patient.last_name }}
                                    <span>{{
                                      prescription.patient.description
                                    }}</span>
                                  </a>
                                </h2>
                                <h2 class="table-avatar" v-else>
                                  <a href="javascript:void();"
                                    >Not Available
                                  </a>
                                </h2>
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  :class="getStatusColor(prescription.status)"
                                >
                                  {{ prescription.status }}
                                </a>
                              </td>
                              <td class="text-right">
                                <div class="table-action">
                                  <a
                                    href="#prescription-details-modal"
                                    data-toggle="modal"
                                    class="btn btn-sm bg-info-light"
                                    @click="
                                      $router.push(
                                        `/provider/request/${prescription.id}`
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> View Details
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      tabState: "inprogress",
      xDown: null,
      yDown: null,
    };
  },
  computed: {
    prescriptions() {
      return this.$store.getters.prescriptions
        ? this.$store.getters.prescriptions
        : [];
    },
    acceptedPrescriptions() {
      return this.prescriptions.filter(
        (i) =>
          i.status === "Accepted" ||
          i.status === "PharmacyVerified" ||
          i.status === "Sent"
      );
    },
    completedPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "Completed");
    },
    rejectedPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "Cancelled");
    },
  },
  mounted() {
    try {
      document.addEventListener("touchstart", this.handleTouchStart, false);
      document.addEventListener("touchmove", this.handleTouchMove, false);
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions(["showLoader"]),
    pharmacyStatus(status) {
      switch (status) {
        case "Sent":
          return "Sent To Pharmacy";

        case "PharmacyVerified":
          return "Pharmacy Verified";
      }

      return status;
    },
    getStatusTextColor(status) {
      // console.log(status);
      switch (status) {
        case "Pending":
          return "text-secondary";

        case "In Progress":
          return "text-warning";

        case "Sent":
        case "PharmacyVerified":
        case "Completed":
        case "Accepted":
          return "text-success";

        case "Cancelled":
          return "text-danger";

        default:
          return "text-warning";
      }
    },
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      try {
        if (!this.xDown || !this.yDown) {
          return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          /*most significant*/
          if (xDiff > 0) {
            /* right to left swipe */
            switch (this.tabState) {
              case "inprogress":
                this.tabState = "completed";
                $("#completed-btn").click();
                $("#inprogress").removeClass("show active");
                $("#rejected").removeClass("show active");
                break;

              case "completed":
                this.tabState = "rejected";
                $("#rejected-btn").click();
                $("#inprogress").removeClass("show active");
                $("#completed").removeClass("show active");
                break;

              case "rejected":
                this.tabState = "rejected";
                $("#completed").removeClass("show active");
                $("#inprogress").removeClass("show active");
                break;
            }
          } else {
            /* left to right swipe */
            switch (this.tabState) {
              case "inprogress":
                this.tabState = "inprogress";
                $("#completed").removeClass("show active");
                $("#rejected").removeClass("show active");
                break;

              case "completed":
                this.tabState = "inprogress";
                $("#inprogress-btn").click();
                $("#completed").removeClass("show active");
                $("#rejected").removeClass("show active");
                break;

              case "rejected":
                this.tabState = "completed";
                $("#completed-btn").click();
                $("#inprogress").removeClass("show active");
                $("#rejected").removeClass("show active");
                break;
            }
          }
        }

        /* reset values */
        this.xDown = null;
        this.yDown = null;
      } catch (e) {
        alert(e);
      }
    },
    normalizeString(str) {
      return (
        String(str)
          .charAt(0)
          .toUpperCase() +
        String(str)
          .slice(1)
          .toLowerCase()
      );
    },
    getTrimmedName(str) {
      if (String(str).length > 20) return String(str).substring(0, 20) + "...";
      else return str;
    },
    getStatusColor(status) {
      switch (status) {
        case "Pending":
          return "btn btn-sm bg-warning-light mr-1";

        case "Assigned":
          return "btn btn-sm bg-primary-light mr-1";

        case "Completed":
          return "btn btn-sm bg-success-light mr-1";

        case "Accepted":
          return "btn btn-sm bg-primary-light mr-1";

        case "Cancelled":
          return "btn btn-sm bg-danger-light mr-1";

        default:
          return "btn btn-sm bg-primary-light mr-1";
      }
    },
  },
};
</script>
<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.nav-custom {
  background-color: #ebebeb;
  border-radius: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #2284d5;
  border-radius: 20px 20px 20px 20px;
}

.list-holder {
  height: calc(100vh - 250px);
  overflow: auto;
}
</style>
