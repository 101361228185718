<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <div class="card">
      <div class="card-header">
        <h4>Change Password</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <!-- Change Password Form -->
            <form @submit.prevent="changePassword">
              <div class="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  class="form-control form-control-xs"
                  v-model="oldPassword"
                  required
                />
              </div>
              <div class="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  class="form-control form-control-xs"
                  v-model="newPassword"
                  required
                />
              </div>
              <div class="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  class="form-control form-control-xs"
                  v-model="repeatPassword"
                  required
                />
              </div>
              <hr />
              <div class="submit-section">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary submit-btn btn-block"
                >
                  <i class="fas fa-key mr-2"></i> Change Password
                </button>
              </div>
            </form>
            <!-- /Change Password Form -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    };
  },
  methods: {
    ...mapActions(["changeUserPassword", "showAlert", "showLoader"]),
    async changePassword() {
      try {
        if (this.newPassword !== this.repeatPassword) {
          Swal.fire({
            icon: "warning",
            title: `Ooops! Passwords do not match`,
            showConfirmButton: false,
            timer: 2000,
          });
          return;
        }

        this.showLoader(true);

        await this.changeUserPassword({
          id: this.$store.getters.user.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          role: this.$store.getters.user.role,
        });

        Swal.fire({
          icon: "success",
          title: `Great!`,
          text: `Password Changed!`,
          showConfirmButton: false,
          timer: 2000,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";

        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";
        this.showLoader(false);
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>