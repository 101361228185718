<template>
  <div>
    <div id="loader-wrapper">
      <div class="loader reverse-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    loaderState: function () {
      return this.$store.getters.loader;
    },
  },
  watch: {
    loaderState() {
      !this.loaderState ? this.hide() : this.show();
    },
  },
  mounted() {
    this.hide();
  },
  methods: {
    show() {
      $("#loader-wrapper").delay(2).fadeIn("slow");
      $("body").delay(10).css({ overflow: "invisible" });
    },
    hide() {
      $("#loader-wrapper").delay(2).fadeOut("slow");
      $("body").delay(10).css({ overflow: "visible" });
    },
  },
};
</script>

<style scoped>
.reverse-spinner {
  position: relative;
  height: 80px;
  width: 80px;
  border: 4px solid transparent;
  border-top-color: #1976d2;
  border-left-color: #1976d2;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.reverse-spinner::before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  border: 4px solid transparent;
  border-top-color: #03a9f4;
  border-left-color: #03a9f4;
  border-radius: 50%;
  -webkit-animation: spinBack 1s linear infinite;
  animation: spinBack 1s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -47%);
  z-index: 999992;
}

#loader-wrapper {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999991;
  opacity: 1;
}
</style>