<template>
  <div class="main-wrapper">
    <section class="facility-section">
      <div class="container">
        <div class="row">
          <div class="col-12 m-auto">
            <div class="section-header text-center">
              <h2>{{ $t("contactUs.title") }}</h2>
              <p class="sub-title">
                {{ $t("contactUs.description") }}
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="visit-doctor">
              <div class="inner-details mr-2">
                <div class="count">
                  <h1><i class="fas fa-phone"></i></h1>
                </div>
                <div class="info" @click="callPhone">
                  <h1>{{ $t("contactUs.customerServiceTitle") }}</h1>
                  <p>+1-(678)-820-2221</p>
                  <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="visit-doctor">
              <div class="inner-details mr-2">
                <div class="count">
                  <h1><i class="fas fa-phone"></i></h1>
                </div>
                <div class="info" @click="callPhone">
                  <h1>{{ $t("contactUs.tollFreeNumTitle") }}</h1>
                  <p>+1-(855)-725-7629</p>
                  <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="visit-doctor">
              <div class="inner-details pharmacy">
                <div class="count">
                  <h1><i class="fas fa-envelope"></i></h1>
                </div>
                <div class="info">
                  <a href="mailto:support@stepaheadhealth.com">
                    <h1>{{ $t("contactUs.supportEmailTitle") }}</h1>
                    <p>support@stepaheadhealth.com</p>
                  </a>
                  <!-- <router-link to="/patient/booking">Book Now <i class="fas fa-long-arrow-alt-right"></i> </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>

<script>
export default {
  methods: {
    callPhone() {
      if (ReactNativeWebView) {
        // Send connection message
        ReactNativeWebView.postMessage(
          JSON.stringify({ command: "CALL", data: "+1-678-820-2221" })
        );
      }
    },
  },
};
</script>

<style scoped>
.visit-doctor {
  height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
</style>
