<template>
  <div class="card">
    <form @submit.prevent="rejectSubmit">
      <div class="card-body p-2">
        <div class="row mb-3">
          <div class="col-10 mt-2">
            <h4>Request ID: {{ prescriptionDetails.id }}</h4>
          </div>
          <div class="col-2">
            <a
              href="javascript:void()"
              @click="$emit('close')"
              class="btn btn-sm bg-info-light btn-block"
            >
              <i class="fa fa-close"></i>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h5>Are you sure you want to cancel the prescription request?</h5>
            <hr />

            <div class="form-group text-left">
              <label>Reason*</label>
              <textarea
                name="cancellation-input"
                v-model="rejectionReason"
                class="form-control"
                required
              ></textarea>
            </div>

            <hr />

            <h6>Important Notes</h6>
            <ul>
              <li>
                <small
                  >Rejecting the request will result in permanent rejection. It
                  cannot be reversed.</small
                >
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <button type="submit" class="btn bg-danger-light mr-2 btn-block">
              <i class="fas fa-times mr-2"></i> Confirm Cancel Request
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionDetails: Object,
  },
  data() {
    return {
      rejectionReason: "",
    };
  },
  methods: {
    rejectSubmit() {
      this.$emit(
        "cancel",
        JSON.stringify({
          id: this.prescriptionDetails.id,
          reason: this.rejectionReason,
        })
      );
    },
  },
};
</script>
