<template>
  <div class="main-wrapper main-wrapper-adjust">
    <div class="content">
      <div class="container">
        <div class="card">
          <div class="card-body" v-if="!isSearchInProgress">
            <form @submit.prevent="submitRequest">
              <h3 class="card-title text-center m-2">
                {{ $t("newPrescription.title") }}
              </h3>
              <hr />
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="name-input" class="form-label"
                      ><strong
                        >{{ $t("newPrescription.fullNameLabel") }}*</strong
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      id="name-input"
                      v-model="newPrescription.name"
                      placeholder="Enter your full name"
                      required
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6">
                  <div class="mb-3">
                    <label for="mobile-input" class="form-label"
                      ><strong
                        >{{ $t("newPrescription.phoneNoLabel") }}*</strong
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control form-control-xs"
                      id="mobile-input"
                      v-model="newPrescription.mobileNumber"
                      placeholder="Enter your mobile number"
                      required
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="height-input" class="form-label"
                      ><strong
                        >{{ $t("newPrescription.heightLabel") }}*</strong
                      ></label
                    >
                    <input
                      type="number"
                      class="form-control form-control-xs"
                      id="height-input"
                      v-model="newPrescription.height"
                      required
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="weight-input" class="form-label"
                      ><strong
                        >{{ $t("newPrescription.weightLabel") }}*</strong
                      ></label
                    >
                    <input
                      type="number"
                      class="form-control form-control-xs"
                      id="weight-input"
                      v-model="newPrescription.weight"
                      required
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12" v-if="pharmacy && pharmacy.pharmacyName">
                  <div class="mb-3">
                    <label for="pharmacy-input" class="form-label"
                      ><strong
                        >{{
                          $t("newPrescription.pharmacyDetailsLabel")
                        }}*</strong
                      ></label
                    >
                    <h6>
                      {{ $t("newPrescription.nameLabel") }}:
                      {{ pharmacy.pharmacyName }}
                    </h6>
                    <small
                      >{{ $t("newPrescription.phoneLabel") }}:
                      {{ pharmacy.phone }}</small
                    >
                    <br />
                    <small
                      >{{ $t("newPrescription.addressLabel") }}:
                      {{ getStoreAddress(pharmacy.address) }}</small
                    >
                  </div>
                </div>
                <div class="col-12" v-else>
                  <div class="mb-3">
                    <label for="pharmacy-input" class="form-label"
                      ><strong>Pharmacy Details*</strong></label
                    >
                    <p>
                      <small>No Pharmacy Selected</small>
                    </p>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-secondary btn-block"
                      @click="searchPharmacy"
                    >
                      <i class="fas fa-clinic-medical mr-2"></i> Select Pharmacy
                    </button>
                  </div>
                </div>
                <div class="col-12" v-if="pharmacy && pharmacy.pharmacyName">
                  <div class="mb-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-secondary btn-block"
                      @click="searchPharmacy"
                    >
                      <i class="fas fa-clinic-medical mr-2"></i
                      >{{ $t("newPrescription.changebtn") }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="symptoms-input" class="form-label"
                      ><strong
                        >{{
                          $t("newPrescription.healthConditionLabel")
                        }}*</strong
                      ></label
                    >
                    <textarea
                      class="form-control form-control-xs"
                      id="symptoms-input"
                      rows="3"
                      v-model="newPrescription.healthSymptoms"
                      :placeholder="
                        $t('newPrescription.healthConditionPlaceholder')
                      "
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-xl-6">
                  <div class="mb-3">
                    <label for="medical-past-input" class="form-label">{{
                      $t("newPrescription.pastMedicalConditionLabel")
                    }}</label>
                    <textarea
                      class="form-control form-control-xs"
                      id="medical-past-input"
                      rows="3"
                      v-model="newPrescription.medicalPast"
                      :placeholder="
                        $t('newPrescription.pastMedicalConditionPlaceholder')
                      "
                    ></textarea>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6">
                  <div class="mb-3">
                    <label for="allergies-input" class="form-label">{{
                      $t("newPrescription.allergiesLabel")
                    }}</label>
                    <textarea
                      class="form-control form-control-xs"
                      id="allergies-input"
                      v-model="newPrescription.allergies"
                      :placeholder="$t('newPrescription.allergiesPlaceholder')"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-2 mb-4">
                <div class="col-12">
                  <div class="text-center">
                    <button type="submit" class="btn btn-info btn-block">
                      <i class="fa fa-send mr-2"></i>
                      {{ $t("newPrescription.submitRequestbtn") }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body p-0 mt-3" v-else>
            <ChangePharmacySearch
              @select="selectPharmacy"
              @cancel="cancelSearch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import config, { HTTP_API } from "../../constants/config";
import axios from "axios";
import Swal from "sweetalert2";
import ChangePharmacySearch from "./ChangePharmacySearch.vue";

export default {
  components: {
    ChangePharmacySearch,
  },
  data() {
    return {
      pharmacy: null,
      isSearchInProgress: false,
      newPrescription: {
        allergies: "",
        medicalPast: "",
      },
    };
  },
  mounted() {
    this.newPrescription.name = `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`;
    this.newPrescription.mobileNumber = `${this.$store.getters.user.primary_phone}`;
    this.newPrescription.height = `${String(this.$store.getters.user.height)}`;
    this.newPrescription.weight = `${String(this.$store.getters.user.weight)}`;
    this.pharmacy = this.$store.getters.user.pharmacy;
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    async submitRequest() {
      try {
        if (!this.pharmacy || !this.pharmacy.pharmacyName) {
          Swal.fire("Select Pharmacy", "Please select the pharmacy", "error");
          return;
        }

        this.showLoader(true);

        const res = await axios.post(
          config.BASE_URL + HTTP_API.patientCreateFreePrescription,
          {
            id: this.$store.getters.user.id,
            patientId: this.$store.getters.user.patientId,
            pharmacy: this.pharmacy,
            ...this.newPrescription,
          }
        );

        Swal.fire({
          icon: "success",
          title: `Rx Request Created!`,
          text: `Redirecting to request payments...`,
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          this.$router.push(`/patient/billing/${res.data.prescriptionNumber}`);
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },
    getStoreAddress(addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },
    searchPharmacy() {
      this.isSearchInProgress = true;
    },
    selectPharmacy(data) {
      this.pharmacy = JSON.parse(data);
      this.isSearchInProgress = false;
    },
    cancelSearch() {
      this.isSearchInProgress = false;
    },
  },
};
</script>
<style scoped>
.main-wrapper-adjust {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}

.section-search {
  min-height: 600px;
  padding: 40px 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>
