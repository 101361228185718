<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <!-- Hero section -->
    <section class="section aboutus-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="mt-4">
              {{ $t("aboutUs.title") }}
            </h3>
            <div class="left mt-4 mb-5">
              <p class="text-justify">{{ $t("aboutUs.description1") }}<br /></p>
              <p class="text-justify">
                {{ $t("aboutUs.description2") }}
              </p>
              <p class="text-justify">
                {{ $t("aboutUs.description3") }}
              </p>
              <p class="text-justify">
                {{ $t("aboutUs.description4") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="right">
              <img
                src="../assets/img/aboutus.PNG"
                alt="About us"
                style="border-radius: 50%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Process section -->
    <process id="process"></process>

    <!-- Terms Section-->
    <section class="doctor-divison mb-5">
      <div class="d-flex mb-5">
        <div class="doc-background">
          <h3>{{ $t("aboutUs.termsCondition.title") }}</h3>
          <p>
            {{ $t("aboutUs.termsCondition.description") }}
          </p>
          <router-link
            to="/term-condition"
            class="doc-bok-btn"
            style="width: 100%"
            >{{ $t("aboutUs.termsCondition.button") }}</router-link
          >
        </div>
        <div class="pat-background mt-3 mb-5">
          <h3>{{ $t("aboutUs.privacyPolicy.title") }}</h3>
          <p>
            {{ $t("aboutUs.privacyPolicy.description") }}
          </p>
          <router-link
            to="/privacy-policy"
            class="doc-bok-btn"
            style="width: 100%; color: black"
            >{{ $t("aboutUs.privacyPolicy.button") }}</router-link
          >
        </div>
      </div>
    </section>
    <!-- /Terms Section-->

    <layout-footer class="footer"></layout-footer>
  </div>
</template>

<style scoped>
h3 {
  margin-top: 20px;
}
.col-half-offset {
  margin-left: 4.166666667%;
}

@media only screen and (max-width: 600px) {
  img {
    display: none;
  }

  .webView {
    display: none;
  }

  .mobileView {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}

.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}
</style>
