<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h4>Download Financial Statement</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>Coming Soon!</h6>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
