<template>
  <div>
    <div class="main-wrapper">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <ul class="nav nav-pills nav-fill nav-custom">
                <li class="nav-item">
                  <a
                    id="pending-btn"
                    class="nav-link active"
                    href="javascript:void();"
                    data-toggle="pill"
                    data-target="#pending-payments"
                    >{{ $t("payments.pending") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="completed-btn"
                    class="nav-link"
                    href="javascript:void();"
                    data-toggle="pill"
                    data-target="#completed-payments"
                    >{{ $t("payments.completed") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <!-- SHOW PENDING PAYMENTS -->
                <div
                  class="tab-pane fade show active list-holder"
                  id="pending-payments"
                  role="tabpanel"
                  aria-labelledby="pending-payments-tab"
                >
                  <div v-if="pendingPayments.length > 0">
                    <div
                      class="card shadow-sm bg-white rounded mb-3"
                      v-for="(txn, i) in pendingPayments"
                      :key="i"
                    >
                      <div class="card-header bg-transparent row">
                        <div class="col-7">
                          <p class="mb-2">
                            {{ $t("payments.requestId") }}:
                            <strong>{{ txn.prescription_id }}</strong>
                          </p>
                          <p class="mb-2">
                            {{ $t("payments.amount") }}:
                            <strong>${{ txn.amount }} USD</strong>
                          </p>
                        </div>
                        <div class="col-5">
                          <p style="font-size: 10px" class="mb-0 mt-2">
                            <span :class="getStatusColor(txn.status)">
                              <strong style="font-size: 10px">{{
                                txn.status
                              }}</strong>
                            </span>
                          </p>
                        </div>
                        <div
                          class="col-12"
                          v-if="
                            txn.prescription_status === 'Accepted' &&
                              txn.is_payment_completed == 0
                          "
                        >
                          <router-link
                            :to="`/patient/billing/${txn.prescription_id}`"
                          >
                            <a
                              href="javascript:void();"
                              class="btn btn-sm bg-primary-light btn-block mt-2"
                            >
                              {{ $t("payments.confirm&payFees") }}
                            </a>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>{{ $t("payments.noTransactionsFound") }}</h5>
                  </div>
                </div>

                <!-- SHOW COMPLETED PAYMENT -->
                <div
                  class="tab-pane fade list-holder"
                  id="completed-payments"
                  role="tabpanel"
                  aria-labelledby="completed-payments-tab"
                >
                  <div v-if="completedPayments.length > 0">
                    <div
                      class="card shadow-sm bg-white rounded mb-3"
                      v-for="(txn, i) in completedPayments"
                      :key="i"
                    >
                      <router-link :to="`/patient/txn/${txn.txn_id}`">
                        <div class="card-header bg-transparent row">
                          <div class="col-12">
                            {{ $t("payments.transactionId") }}:
                            <strong>{{ txn.txn_id }}</strong>
                          </div>
                          <div class="col-7">
                            <p class="mb-1 mt-1">
                              {{ $t("payments.requestId") }}:
                              <strong>{{ txn.prescription_id }}</strong>
                            </p>
                            <p class="mb-1">
                              {{ $t("payments.date") }}:
                              {{ new Date(txn.created_at).toDateString() }}
                            </p>
                          </div>
                          <div class="col-5">
                            <p style="font-size: 10px" class="mb-0 mt-2">
                              <span :class="getStatusColor(txn.status)">
                                <strong style="font-size: 10px">{{
                                  txn.status
                                }}</strong>
                              </span>
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>{{ $t("payments.noTransactionsFound") }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    tabId: String,
  },
  data: function() {
    return {
      interval: null,
      tabState: "pending-payments",
      xDown: null,
      yDown: null,
    };
  },
  async mounted() {
    try {
      if (this.interval) clearInterval(this.interval);

      this.$socket.emit("REQUEST", {
        command: "GET:PATIENT_PAYMENTS",
        userId: this.$store.getters.user.id,
      });

      this.interval = setInterval(() => {
        this.$socket.emit("REQUEST", {
          command: "GET:PATIENT_PAYMENTS",
          userId: this.$store.getters.user.id,
        });
      }, 5000);

      try {
        document.addEventListener("touchstart", this.handleTouchStart, false);
        document.addEventListener("touchmove", this.handleTouchMove, false);
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
  computed: {
    payments() {
      return this.$store.getters.payments;
    },
    pendingPayments() {
      return this.payments && this.payments.length > 0
        ? this.payments.filter((t) => t.status === "Pending").reverse()
        : [];
    },
    completedPayments() {
      return this.payments && this.payments.length > 0
        ? this.payments
            .filter((t) => String(t.status).toLowerCase() === "completed")
            .reverse()
        : [];
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      try {
        if (!this.xDown || !this.yDown) {
          return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          /*most significant*/
          if (xDiff > 0) {
            /* right to left swipe */
            switch (this.tabState) {
              case "pending-payments":
                this.tabState = "completed-payments";
                $("#completed-btn").click();
                $("#pending-payments").removeClass("show active");
                break;

              case "completed-payments":
                this.tabState = "completed-payments";
                $("#completed-btn").click();
                $("#pending-payments").removeClass("show active");
                break;
            }
          } else {
            /* left to right swipe */
            switch (this.tabState) {
              case "pending-payments":
                this.tabState = "pending-payments";
                $("#pending-btn").click();
                $("#completed-payments").removeClass("show active");
                break;

              case "completed-payments":
                this.tabState = "pending-payments";
                $("#pending-btn").click();
                $("#completed-payments").removeClass("show active");
                break;
            }
          }
        }

        /* reset values */
        this.xDown = null;
        this.yDown = null;
      } catch (e) {
        alert(e);
      }
    },
    getStatusColor(status) {
      switch (String(status).toLowerCase()) {
        case "Pending":
          return "btn btn-sm bg-warning-light btn-block";

        case "completed":
          return "btn btn-sm bg-success-light btn-block";

        case "Failed":
          return "btn btn-sm bg-danger-light btn-block";

        default:
          return "btn btn-sm bg-primary-light btn-block";
      }
    },
  },
};
</script>
<style scoped>
.card-btn {
  cursor: pointer;
}

.card-btn:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.nav-custom {
  background-color: #ebebeb;
  border-radius: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #2284d5;
  border-radius: 20px 20px 20px 20px;
}

.list-holder {
  height: calc(100vh - 250px);
  overflow: auto;
  overflow-x: hidden !important;
}
</style>
