<template>
  <div class="card">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-12">
          <h4>Rx Request ID: {{ prescriptionDetails.id }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h6>Are you sure you want to approve the refill request?</h6>
          <hr />

          <h6>Important Notes</h6>
          <ul>
            <li>
              <small>You cannot revoke the prescription once approved.</small>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <button
            @click="$emit('submit', prescriptionDetails.id)"
            class="btn bg-success-light mr-2 btn-block"
          >
            <i class="fas fa-check"></i> Approve Refill Request
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionDetails: Object,
  },
};
</script>