<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <breadcrumb40></breadcrumb40>

    <section class="section home-tile-section">
      <div class="container-fluid">
        <div class="section-header text-center">
          <h2>Pay a Bill</h2>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div
              class="bd-example"
              data-example-id=""
            >
              <div
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingOne"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>When will I be charged for my prescription?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    aria-expanded="false"
                    style=""
                  >
                    <div class="card-block">
                      StepAhead Health charges based on the process.<br><br>

                      <strong>What is the Process?</strong>

                      <ol>
                        <li>
                          When you create a new prescription and submit it, we charge a minimum security fee for
                          confirmation of the prescription. Once you pay the service fees, your prescription will be
                          assigned to our top available providers.
                        </li>
                        <li>
                          On assignment, provider will check your prescription and give an acceptance.
                        </li>
                        <li>
                          On acceptance from provider, you will have to pay the provider fees. You can see the status of
                          your prescription after you login to the dashboard.
                        </li>
                        <li>
                          Once the payment is completed, the provider will approve your prescription.
                        </li>
                      </ol>

                      You can contact us if you have questions about creating a new prescription and payments.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingTwo"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>What payment methods can I use?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      We provide a PayPal integrated payment system. <br> <br>You can pay using:

                      <ol>
                        <li>Credit Card</li>
                        <li>Debit Card</li>
                        <li>PayPal Cash</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="card-header"
                    role="tab"
                    id="headingThree"
                  >
                    <div class="mb-0">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        class="collapsed"
                      >
                        <i
                          class="fa fa-file-text-o"
                          aria-hidden="true"
                        ></i>
                        <h3>When will I get my order?</h3>
                      </a>
                      <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    aria-expanded="false"
                  >
                    <div class="card-block">
                      Delivery times depend on the type of order. Here are some general timeframes for
                      getting your medication, once your order is placed:<br><br>

                      <ul>
                        <li>First-time order: 8 to 11 days</li>
                        <li>Refills ordered online: 2 to 4 days</li>
                        <li>Refills ordered by mail: 6 to 9 days</li>
                        <li>Renewals ordered online (after we receive your doctor's approval): 5 to 8 days</li>
                        <li>Renewals ordered by mail: 7 to 11 days</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>
<style scoped>
.card-block {
  padding: 20px;
}
</style>
