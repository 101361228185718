<template>
  <div class="main-wrapper main-wrapper-adjust-profile">
    <div class="row">
      <div class="col-12">
        <div class="card mb-5">
          <div class="card-header">
            <h4>Profile Settings</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="saveProfileDetails">
              <div class="row form-row">
                <div class="col-12">
                  <div class="form-group text-center">
                    <div class="change-avatar">
                      <div class="change-photo-btn w-100">
                        <span> Change Photo</span>
                        <input type="file" class="upload" id="profile_image" />
                      </div>
                    </div>
                    <small class="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control form-control-xs" v-model="firstName" required />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" class="form-control form-control-xs" v-model="lastName" required />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>About Me (Max 500 Characters)
                      <span class="text-muted" style="font-size: 10px">({{ String(aboutMe).length }} of
                        500)</span></label>
                    <textarea type="text" style="font-size: 12px" class="form-control"
                      placeholder="Tell us something about yourself that you would like to showcase. Remember, your patient's can see this :)"
                      v-model="aboutMe" minlength="0" maxlength="500" rows="6" required />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>D.O.B <small>(MM/DD/YYYY)</small></label>
                    <Datepicker :lowerLimit="dateLowerLimit" :upperLimit="dateUpperLimit" inputFormat="MM-dd-yyyy"
                      class="form-control form-control-xs" v-model="dob" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Gender</label>
                    <select v-model="gender" class="form-control form-control-xs select" required>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Prefer not to answer</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Email ID</label>
                    <input type="email" class="form-control form-control-xs" v-model="$store.getters.user.email"
                      disabled />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Mobile</label>
                    <input type="text" v-model="$store.getters.user.primary_phone" class="form-control form-control-xs"
                      disabled />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Address Line 1</label>
                    <input type="text" class="form-control form-control-xs" v-model="addressLine1" required
                      maxlength="34" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Address Line 2</label>
                    <input type="text" class="form-control form-control-xs" v-model="addressLine2" maxlength="34" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>State</label>
                    <select class="form-control form-control-xs select" v-model="state" required @change="fetchCities()">
                      <option v-for="(state, i) in statesData" :key="i" :value="state.iso2">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>City</label>
                    <select class="form-control form-control-xs select" v-model="city" required tabindex="-1"
                      aria-hidden="true">
                      <option v-for="(c, i) in citiesData" :key="i" :value="c.name">
                        {{ c.name }}
                      </option>
                    </select>
                    <!-- <input
                          class="form-control form-control-xs select"
                          tabindex="-1"
                          aria-hidden="true"
                          v-model="city"
                          required
                        /> -->
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label>Postal Code</label>
                    <input type="text" class="form-control form-control-xs" v-model="zipCode" required />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Country</label>
                    <input type="text" class="form-control form-control-xs" value="United States" disabled />
                  </div>
                </div>
              </div>
              <hr />
              <div class="submit-section">
                <button type="submit" class="btn btn-sm btn-primary submit-btn btn-block">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import common from "../../../mixins/common";
import Swal from "sweetalert2";
import Datepicker from "vue3-datepicker";

export default {
  mixins: [common],
  data () {
    return {
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(),

      firstName: "",
      lastName: "",
      aboutMe: "",
      gender: "",
      dob: new Date(),
      height: "",
      weight: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      citiesData: [],
      photo: null,
    };
  },

  components: {
    Datepicker,
  },

  async mounted () {
    this.showLoader(true);
    this.statesData = await this.getStateList();

    // Personal details
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.aboutMe = this.$store.getters.user.about_me;
    this.gender = this.$store.getters.user.gender;
    this.dob = new Date(this.$store.getters.user.dob);

    // Address parsing
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;

    this.profileImage = this.$store.getters.user.profile_image_url;
    this.state = this.$store.getters.user.profile_state;

    if (this.state !== "") await this.fetchCities(this.state);
    this.city = this.$store.getters.user.city;

    let self = this;
    function readURL (input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = async function (e) {
          await self.savePhoto();
          self.profileImage = self.$store.getters.profile_image_url;
          // self.$router.go();
        };

        reader.readAsDataURL(input.files[0]);
        self.photo = input.files[0];
      }
    }

    $("#profile_image").change(function () {
      readURL(this);
    });

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "saveUserProfilePhoto", "showLoader"]),
    async fetchCities () {
      this.showLoader(true);
      this.citiesData = await this.getCityList(this.state);
      this.showLoader(false);
    },

    async savePhoto () {
      try {
        this.showLoader(true);
        let formData = new FormData();
        formData.append("photo", this.photo);
        formData.append("id", this.$store.getters.user.id);
        await this.saveUserProfilePhoto(formData);

        Swal.fire({
          icon: "success",
          title: `Good One!`,
          text: `Profile picture updated!`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },

    async saveProfileDetails () {
      try {
        this.showLoader(true);
        const formated = this.formatDate(this.dob);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          first_name: this.firstName,
          last_name: this.lastName,
          about_me: this.aboutMe,
          dob: formated,
          gender: this.gender,
          address1: this.addressLine1,
          address2: this.addressLine2,
          state: this.state,
          city: this.city,
          zip_code: this.zipCode,
        });

        Swal.fire({
          icon: "success",
          title: `Great Job!`,
          text: `Profile updated!`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },
  },
};
</script>
<style>
.main-wrapper-adjust-profile {
  width: 100%;
  max-height: 82vh;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>