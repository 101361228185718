<template>
  <!-- Header -->
  <header class="sticky-header header header-trans">
    <nav class="navbar navbar-expand-lg header-nav">
      <div class="navbar-header mt-4">
        <a id="mobile_btn" href="javascript:void(0);" v-if="showMenuBtn">
          <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <a v-else class="back-icon" href="javascript:void();" @click="goBack"><i class="fas fa-arrow-left"></i></a>

        <router-link :to="logoLink" class="navbar-brand logo">
          <img src="../../assets/img/logo.png" class="img-fluid" alt="Logo" />
        </router-link>

        <div class="inline-flex" v-for="(entry, i) in languages" :key="`Lang${i}`">
          <a :class="currentLanguage === entry.language ? 'active me-2' : 'me-2'" href="javascript:void();"
            @click="setLanguage(entry.language, entry.flag, entry.title)">
            <img :src="entry.flag" width="12" />
            {{ String(entry.language).toUpperCase() }}
          </a>
        </div>

        <a v-if="$store.getters.user" class="logoutBtn not-ipad" href="javascript:void();" @click="logout"><i
            class="fas fa-sign-out-alt"></i>
          <small class="menu-txt">Logout</small></a>
      </div>
      <a v-if="$store.getters.user" class="logoutBtn ipad" href="javascript:void();" @click="logout"><i
          class="fas fa-sign-out-alt"></i><br />
        <small class="menu-txt">Logout</small></a>

      <!-- SIDE MENU -->
      <div class="main-menu-wrapper" v-if="user && $store.getters.user.is_registration_complete">
        <div class="menu-header" style="height: 300px">
          <div class="widget-profile pro-widget-content">
            <div class="profile-info-widget">
              <a href="javascript:void();" class="booking-doc-img">
                <img :src="profileImage" :alt="user.first_name" />
              </a>
              <div class="profile-det-info">
                <h3>
                  {{ formatLongString(user.first_name) }}
                  {{ formatLongString(user.last_name) }}
                </h3>
                <div class="patient-details">
                  <h5><i class="fas fa-birthday-cake"></i> {{ dob }}</h5>
                  <h5 class="mb-0">
                    <i class="fas fa-map-marker-alt"></i> {{ user.city }},
                    {{ user.state }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <a id="menu_close" class="menu-close-new" href="javascript:void(0);">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <ul class="main-nav">
          <li v-show="$store.getters.user">
            <a href="javascript:void();" @click="navigate(dashboardLink)">
              <i class="fas fa-home mr-2"></i>
              <span>{{ $t("header.home") }}</span>
              <!-- <i class="fas fa-home mr-2"></i> -->
            </a>
          </li>
          <li>
            <a href="javascript:void();" @click="navigate('/about')">
              <i class="fas fa-info-circle mr-2"></i>
              <span>{{ $t("header.aboutUs") }}</span>
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void();" @click="openSupportCenter">
              <i class="fas fa-hands-helping mr-2"></i>
              <span>{{ $t("header.helpCenter") }}</span></a
            >
          </li> -->
          <li>
            <a href="javascript:void();" @click="navigate('/contact')">
              <i class="fas fa-phone mr-2"></i>
              <span>{{ $t("header.contactUs") }}</span>
            </a>
          </li>
          <li>
            <a href="javascript:void();" @click="navigate('/term-condition')">
              <i class="fas fa-user-lock mr-2"></i>
              <span>{{ $t("header.termsConditions") }}</span>
            </a>
          </li>
          <li>
            <a href="javascript:void();" @click="navigate('/privacy-policy')">
              <i class="fas fa-lock mr-2"></i>
              <span>{{ $t("header.privacyPolicy") }}</span>
            </a>
          </li>
          <li>
            <a href="javascript:void();" @click="logout">
              <i class="fas fa-sign-out-alt mr-2"></i>
              <span>{{ $t("header.logOut") }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <!-- /Header -->
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
const images = require.context("../../assets/img", false, /\.png$|\.jpg$/);

export default {
  data () {
    return {
      languages: [
        {
          flag: "/img/flags/united-states.svg",
          language: "en",
          title: "English",
        },
        {
          flag: "/img/flags/spain.svg",
          language: "es",
          title: "Español",
        },
      ],
      currentLanguage: "en",
      currentText: "English",
      currentFlag: "/img/flags/united-states.svg",
      currentValue: "en",
      xDown: null,
      yDown: null,
    };
  },
  mounted () {
    const currentLang = this.languages.find(
      (x) => x.language === this.$i18n.locale
    );
    this.currentText = currentLang.title;
    this.currentFlag = currentLang.flag;

    if (
      this.$store.getters.user &&
      this.$store.getters.user.is_registration_complete
    ) {
      var $wrapper = $(".main-wrapper");
      $(".main-nav a").on("click", function (e) {
        if (
          $(this)
            .parent()
            .hasClass("has-submenu white-text")
        ) {
          e.preventDefault();
        }
        if (!$(this).hasClass("submenu")) {
          $("ul", $(this).parents("ul:first")).slideUp(350);
          $("a", $(this).parents("ul:first")).removeClass("submenu");
          $(this)
            .next("ul")
            .slideDown(350);
          $(this).addClass("submenu");
        } else if ($(this).hasClass("submenu")) {
          $(this).removeClass("submenu");
          $(this)
            .next("ul")
            .slideUp(350);
        }
      });

      $("body").append('<div class="sidebar-overlay"></div>');
      $(document).on("click", "#mobile_btn", function () {
        $wrapper.toggleClass("slide-nav");
        $(".sidebar-overlay").toggleClass("opened");
        $("html").addClass("menu-opened");
        $("#task_window").removeClass("opened");
        return false;
      });

      $(document).on("click", "#menu_close", function () {
        $("html").removeClass("menu-opened");
        $(".sidebar-overlay").removeClass("opened");
        $("main-wrapper").removeClass("slide-nav");
      });

      $(".main-nav ul li:not(.submenu) a").click(function () {
        $("html").removeClass("menu-opened");
        $(".sidebar-overlay").removeClass("opened");
      });
      //header-fixed

      if ($(".header-trans").length > 0) {
        $(document).ready(function () {
          $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > 95) {
              $(".header-trans").css("background", "#FFFFFF");
            } else {
              $(".header-trans").css("background", "transparent");
            }
          });
        });
      }
      if ($(".header-trans.custom").length > 0) {
        $(document).ready(function () {
          $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > 95) {
              $(".header-trans").css("background", "#2b6ccb");
            } else {
              $(".header-trans").css("background", "transparent");
            }
          });
        });
      }

      document.addEventListener("touchstart", this.handleTouchStart, false);
      document.addEventListener("touchmove", this.handleTouchMove, false);
    }
  },
  computed: {
    profileImage () {
      return this.user && this.user.profile_image_url
        ? this.user.profile_image_url
        : images("./blank.png").default;
    },
    showMenuBtn () {
      return (
        this.$route.name === "patient-dashboard" ||
        this.$route.name === "provider-index" ||
        this.$route.name.includes("register-provider")
      );
    },
    dashboardLink () {
      if (this.user) {
        return this.user && this.user.role === "patient"
          ? "/patient/index"
          : "/provider/index";
      } else {
        return "/login";
      }
    },

    dob () {
      const dob = this.$store.getters.user.dob.split("/");
      let month = "";
      if (dob[1] == 1) month = "Jan";
      if (dob[1] == 2) month = "Feb";
      if (dob[1] == 3) month = "Mar";
      if (dob[1] == 4) month = "Apr";
      if (dob[1] == 5) month = "May";
      if (dob[1] == 6) month = "Jun";
      if (dob[1] == 7) month = "Jul";
      if (dob[1] == 8) month = "Aug";
      if (dob[1] == 9) month = "Sep";
      if (dob[1] == 10) month = "Oct";
      if (dob[1] == 11) month = "Nov";
      if (dob[1] == 12) month = "Dec";

      const age = new Date().getFullYear() - parseInt(dob[0]);
      // return `${dob[0]} ${month} ${dob[2]}, ${age - 1} Years`;
      return `${dob[0]} ${month} ${dob[2]}`;
    },

    profileLink () {
      if (this.user) {
        return this.user && this.user.role === "patient"
          ? "/patient/profile"
          : "/provider/settings";
      } else {
        return "/login";
      }
    },

    user () {
      return this.$store.getters.user;
    },

    logoLink () {
      if (this.user) {
        switch (this.user.role) {
          case "patient":
            return "/patient/index";

          case "provider":
            return "/provider/index";

          default:
            return "/login";
        }
      }

      return "/login";
    },
  },
  methods: {
    ...mapActions(["logOut"]),
    setLanguage (locale, country, flag) {
      this.currentLanguage = locale;
      this.currentText = country;
      this.currentFlag = flag;
      this.$i18n.locale = locale;
    },
    goBack () {
      window.history.go(-1);
      return false;
    },
    navigate (routeName) {
      this.$router.push(routeName);
      $("#menu_close").click();
    },
    openSupportCenter () {
      window.open("https://stepaheadhealthinc.freshdesk.com/", "_blank");
    },
    formatLongString (str) {
      let s = String(str);
      return s.length > 10 ? s.substring(0, 10) + "..." : s;
    },
    logout () {
      Swal.fire({
        title: "Are you sure?",
        text: "",
        icon: "question",
        showDenyButton: true,
        showCancelButton: false,
        cancelButtonColor: "#757575",
        confirmButtonColor: "#09e5ab",
        denyButtonText: "Cancel",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("logOut", {
              id: this.$store.getters.user.id,
              deviceToken: this.$store.getters.device
                ? this.$store.getters.device.deviceToken
                : "",
            });

            this.$router.push("/login");
          } catch (e) {
            this.$router.push("/login");
          }
        }
      });
    },

    getTouches (evt) {
      return (
        evt.touches || evt.originalEvent.touches // browser API
      ); // jQuery
    },

    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },

    handleTouchMove (evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          $("#menu_close").click();
        } else {
          /* left swipe */
          $("#mobile_btn").click();
        }
      } else {
        if (yDiff > 0) {
          /* down swipe */
        } else {
          /* up swipe */
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    },
  },
};
</script>

<style scoped>
.logoutBtn {
  color: #2e6a9b;
  display: block;
  font-size: 25px;
  line-height: 1px;
  text-align: center;
}

.logoutBtn:hover {
  color: #10defd;
}

.main-nav li a {
  color: white !important;
}

.menu-txt {
  font-size: 8px;
  color: #2e6a9b;
}

.menu-close-new {
  font-size: 18px;
  top: 40px;
  position: absolute;
  right: 15px;
}

.back-icon i {
  color: #2e6a9b;
  display: block;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 25px;
  line-height: 5px;
  text-align: center;
}

.ipad {
  display: none;
}

.not-ipad {
  display: block;
}

/* Portrait */
@media screen and (min-width: 768px) and (min-height: 1024px) and (orientation: portrait) {
  .ipad {
    display: block;
  }

  .not-ipad {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (min-height: 768px) and (orientation: landscape) {
  .ipad {
    display: block;
  }

  .not-ipad {
    display: none;
  }
}
</style>
