<template>
  <div class="main-wrapper main-wrapper-adjust">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form>
                  <div class="pb-0 mb-0">
                    <h4 class="card-title">Billing Information</h4>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Request ID</label>
                          <input class="form-control form-control-xs" :value="prescriptionId" type="text" required
                            disabled />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>First Name</label>
                          <input class="form-control form-control-xs" v-model="firstName" type="text" required disabled />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input class="form-control form-control-xs" v-model="lastName" type="text" required disabled />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input class="form-control form-control-xs" type="email" v-model="email" required disabled />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Mobile Number</label>
                          <input class="form-control form-control-xs" type="text" v-model="mobileNumber" required
                            disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card booking-card">
              <div class="card-header">
                <h4 class="card-title">Payment Summary</h4>
              </div>
              <div class="card-body">
                <div class="booking-summary">
                  <div class="booking-item-wrap">
                    <p class="ribbon mb-4" v-if="processingAmount === 0">
                      <span class="text">
                        <strong class="bold">Hurray!</strong>
                        Flat fee offer has been applied
                      </span>
                    </p>
                    <ul class="booking-fee">
                      <li v-if="processingAmount === 0">Flat Fees <span>${{ providerAmount }} USD</span></li>
                      <li v-else>
                        Provider Fees <span>${{ providerAmount }} USD</span>
                      </li>
                    </ul>
                    <ul class="booking-fee">

                      <li :class="processingAmount === 0 ? 'strike' : ''">
                        Processing Fees <span :class="processingAmount === 0 ? 'strike' : ''">${{ processingAmount }}
                          USD</span>
                      </li>
                    </ul>
                    <div class="booking-total">
                      <ul class="booking-total-list">
                        <li>
                          <span>Total Payable</span>
                          <span class="total-cost">${{ totalAmount }} USD</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="payment-widget mb-4">
                  <h4 class="card-title">Payment Methods</h4>
                  <div class="submit-section mt-4">
                    <div id="paypal-button-container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <layout-footer></layout-footer>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, HTTP_API } from "../../constants/config";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  props: {
    prescriptionId: String
  },
  data () {
    return {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      providerAmount: 0,
      processingAmount: 0,
      totalAmount: "",
      billingDetails: {},
    };
  },
  async mounted () {
    this.showLoader(true);
    await this.validatePrescription();
    const self = this;
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.email = this.$store.getters.user.email;
    this.mobileNumber = this.$store.getters.user.primary_phone;

    paypal
      .Buttons({
        // Sets up the transaction when a payment button is clicked
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: self.totalAmount,
                },
              },
            ],
          });
        },

        // Finalize the transaction after payer approval
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (orderData) {
            var transaction = orderData.purchase_units[0].payments.captures[0];
            self.billingDetails = {
              transactionId: transaction.id,
              status: transaction.status,
              amount: transaction.amount.value,
              currency: transaction.amount.currency_code,
              payer: orderData.payer,
              payee: orderData.purchase_units[0].payee,
              shippingInfo: orderData.purchase_units[0].shipping,
            };

            self.redirect();
          });
        },
      })
      .render("#paypal-button-container");

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    async validatePrescription () {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${BASE_URL + HTTP_API.patientValidateProviderPayment}/${this.prescriptionId
          }`
        );

        this.providerAmount = parseFloat(res.data.prescriptionFee);
        this.processingAmount = parseFloat(res.data.processingFee);
        this.totalAmount = this.providerAmount + this.processingAmount

        this.showLoader(false);
      } catch (e) {
        Swal.fire("Billing Error", e.response.data.message, "error").then(
          () => {
            this.$router.push("/patient/index");
            // setTimeout(() => {
            //   this.$router.go();
            // }, 1000);
          }
        );
      }
    },
    async redirect () {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.patientConfirmProviderPayment}`,
          { ...this.billingDetails, prescriptionId: this.prescriptionId }
        );

        this.$router.push(
          `/patient/payment-success/${res.data.prescriptionNumber}`
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
      }
    },
  },
};
</script>
<style>
.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.main-wrapper-adjust {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}

.strike {
  text-decoration: line-through
}

.ribbon {
  font-size: 14px;
  position: relative;
  display: inline-block;
  margin: 1em;
  text-align: center;
}

.text {
  display: inline-block;
  padding: 0.5em 1em;
  min-width: 20em;
  line-height: 1em;
  background: #FFD72A;
  position: relative;
}

.ribbon:after,
.ribbon:before,
.text:before,
.text:after,
.bold:before {
  content: '';
  position: absolute;
  border-style: solid;
}

.ribbon:before {
  top: 0.3em;
  left: 0.2em;
  width: 100%;
  height: 100%;
  border: none;
  background: #EBECED;
  z-index: -2;
}

.text:before {
  bottom: 100%;
  left: 0;
  border-width: .5em .7em 0 0;
  border-color: transparent #FC9544 transparent transparent;
}

.text:after {
  top: 100%;
  right: 0;
  border-width: .5em 2em 0 0;
  border-color: #FC9544 transparent transparent transparent;
}

.ribbon:after,
.bold:before {
  top: 0.5em;
  right: -2em;
  border-width: 1.1em 1em 1.1em 3em;
  border-color: #FECC30 transparent #FECC30 #FECC30;
  z-index: -1;
}

.bold:before {
  border-color: #EBECED transparent #EBECED #EBECED;
  top: 0.7em;
  right: -2.3em;
}
</style>