<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0 m-0">
          <div class="banner-wrapper mr-3">
            <div class="banner-header text-center">
              <h4>Select Refill Medication</h4>
            </div>

            <!-- Search -->
            <div class="search-box mt-3">
              <div class="form-group search-info">
                <vue-select
                  class="form-control form-control-xs"
                  v-model="searchMedicine"
                  :options="medicineOptions"
                  searchable
                  clear-on-close
                  close-on-select
                  clear-on-select
                  :loading="loading"
                  @search:input="startSearching"
                  search-placeholder="Search Medicines"
                ></vue-select>
                <span class="form-text mb-4">Ex: Glipizide, Lipitor</span>
              </div>
              <button
                @click="addMedicineToList"
                class="btn btn-primary btn-sm btn-block mt-0"
              >
                <i class="fas fa-plus"></i> <span>Add to list</span>
              </button>
            </div>
            <!-- /Search -->
          </div>
        </div>
        <div class="col-12 p-0 m-0">
          <div class="card meds-list mt-3">
            <div class="card-header">
              <h5>Medicine List</h5>
            </div>
            <ul
              class="list-group list-group-flush"
              style="height: 100px; overflow: auto"
              v-if="selectedMedicines.length > 0"
            >
              <li
                class="list-group-item"
                style="font-size: 14px"
                v-for="(medicine, i) in selectedMedicines"
                :key="i"
              >
                <div class="row">
                  <div class="col-10">
                    <h6 class="mb-0">{{ medicine.name }}</h6>
                  </div>
                  <div class="col-2">
                    <button
                      @click="removeMedicineFromList(medicine)"
                      class="btn btn-sm btn-light"
                      title="Remove Medicine"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div class="text-center" v-else>
              <p class="m-5">No medicines added yet</p>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 m-0" v-show="selectedMedicines.length > 0">
          <div class="text-center">
            <button
              @click="selectMedicines"
              class="btn btn-sm btn-info btn-block mb-2"
            >
              Confirm & Proceed <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div class="col-12 p-0 m-0" v-show="selectedMedicines.length > 0">
          <div class="text-center">
            <router-link to="/patient/index">
              <button class="btn btn-sm btn-outline-danger btn-block mb-4">
                <i class="fa fa-close"></i> Cancel
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import mixin from "../../mixins/common";

export default {
  mixins: [mixin],
  data() {
    return {
      searchMedicine: "",
      medicineOptions: [],
      masterMedicineOptions: [],
      selectedMedicines: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    selectMedicines() {
      if (this.selectedMedicines.length === 0) {
        this.showAlert({
          message: "Please select medicines for prescription",
          type: "danger",
          time: 4000,
        });
        return;
      }
      this.$emit("select", JSON.stringify(this.selectedMedicines));
    },

    removeMedicineFromList(medicine) {
      this.selectedMedicines = this.selectedMedicines.filter(
        (a) => a.name != medicine.name
      );
    },

    addMedicineToList() {
      if (this.searchMedicine === "") return;

      const med = this.masterMedicineOptions.find(
        (medicine) => medicine.name === this.searchMedicine
      );

      if (med)
        this.selectedMedicines.push({
          ...med,
          medicine_name: med.name,
          quantity: 0,
          refills: 0,
          supply_days: 0,
          directions: "",
        });

      this.searchMedicine = {};
      this.medicineOptions = [];
      this.masterMedicineOptions = [];
    },

    async startSearching(input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;
          const res = await this.searchMedicineByName(input.target.value);

          this.masterMedicineOptions = res;
          if (this.masterMedicineOptions.length > 0) {
            this.medicineOptions = this.masterMedicineOptions.map(
              (a) => a.name
            );
          }
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.showAlert({
          message: e,
          type: "danger",
          time: 4000,
        });
      }
    },

    selectPharmacy(pharmacyDetails) {
      this.$emit("select", JSON.stringify(pharmacyDetails));
    },
  },
};
</script>
<style scoped>
.cont-btn {
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: 90%;
}

.meds-list {
  position: relative;
  height: 250px;
}

.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 92%;
  width: 92%;
}

.search-info .form-control {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .search-info .form-control {
    width: 110%;
  }
}
</style>
