import axios from 'axios'
import config from '../constants/config'

export default {
    methods: {

        normalizeString(str) {
            return String(str).charAt(0).toUpperCase() + String(str).slice(1).toLowerCase();
        },

        async fetchRequest(url) {
            const res = await fetch(url)
            const data = await res.json()
            return data
        },

        parseJwt(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },

        formatDate(date) {
            const dob = new Date(date);
            const year = dob.getFullYear();
            const month =
                dob.getMonth() + 1 < 10 ? `0${dob.getMonth() + 1}` : dob.getMonth() + 1;
            const day = dob.getDate() < 10 ? `0${dob.getDate()}` : dob.getDate();
            return `${year}/${month}/${day}`;
        },

        async getStateList() {
            const res = await axios.get(`${config.BASE_URL}/common/address/getState/US`)
            console.log(res)
            return res.data.sort((a, b) => a.state_name.localeCompare(b.state_name))
        },
        async getCityList(state) {
            console.log(state)
            const res = await axios.get(`${config.BASE_URL}/common/address/getCity/${state}`)
            return res.data
        },
        async getAddressByPin(pincode) {
            const res = await axios.get(`${config.BASE_URL}${config.HTTP_API.addressApi}/${pincode}`)
            return res.data
        },
        async getPharmacyByZipCode(zipCode, speciality) {
            const res = await fetch(`${config.BASE_URL}${config.HTTP_API.pharmacySearch}?zip=${zipCode}&speciality=${speciality}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('user-token')
                })
            })

            return await res.json()
        },
        async getPharmacyByAddress(address, speciality) {
            const res = await fetch(`${config.BASE_URL}${config.HTTP_API.pharmacySearch}?address=${address}&speciality=${speciality}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('user-token')
                })
            })

            return await res.json()
        },
        async getPharmacyByName(name, speciality) {
            const res = await fetch(`${config.BASE_URL}${config.HTTP_API.pharmacySearch}?name=${name}&speciality=${speciality}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('user-token')
                })
            })

            return await res.json()
        },
        async searchPharmacy(query) {
            const res = await axios.post(`${config.BASE_URL}${config.HTTP_API.pharmacySearch}${query}`, {
                query: query
            })

            return res.data
        },
        async searchMedicineByName(medicine) {
            const res = await fetch(`${config.BASE_URL}${config.HTTP_API.medicineSearch}/${medicine}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('user-token')
                })
            })

            return await res.json()
        },
        async getDispenseUnits() {
            const res = await fetch(`${config.BASE_URL}${config.HTTP_API.dispenseUnit}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('user-token')
                })
            })

            return await res.json()
        }
    }
}