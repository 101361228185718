<template>
  <div class="main-wrapper">
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4>{{ $t("changePharmacy.title") }}</h4>
              </div>
            </div>
            <div class="card">
              <div class="card-body" v-if="!isSearchInProgress">
                <div class="p-2" v-if="pharmacy && pharmacy.pharmacyName">
                  <h6>{{ $t("changePharmacy.name") }}:</h6>
                  <h5>{{ pharmacy.pharmacyName }}</h5>
                  <h6 class="mt-4">{{ $t("changePharmacy.phn") }}:</h6>
                  <h5 @click="callPhone">{{ pharmacy.phone }}</h5>
                  <h6 class="mt-4">{{ $t("changePharmacy.address") }}:</h6>
                  <h5>{{ getStoreAddress(pharmacy.address) }}</h5>
                  <hr />
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary btn-block"
                    @click="isSearchInProgress = true"
                  >
                    <i class="fa fa-clinic-medical mr-2"></i
                    >{{ $t("changePharmacy.btn1") }}
                  </button>
                  <button
                    @click="saveProfileDetails"
                    type="button"
                    class="btn btn-primary btn-block"
                  >
                    <i class="fa fa-check mr-2"></i>
                    {{ $t("changePharmacy.btn2") }}
                  </button>
                </div>
                <div v-else>
                  <div class="p-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-block"
                      @click="isSearchInProgress = true"
                    >
                      Select Pharmacy
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body mt-3" v-else>
                <ChangePharmacySearch
                  @select="selectPharmacy"
                  @cancel="isSearchInProgress = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ChangePharmacySearch from "./ChangePharmacySearch.vue";
import Swal from "sweetalert2";

export default {
  components: { ChangePharmacySearch },
  data() {
    return {
      pharmacy: null,
      isSearchInProgress: false,
    };
  },
  mounted() {
    this.pharmacy = this.$store.getters.user.pharmacy;
  },
  methods: {
    ...mapActions(["showLoader", "saveUserDetails"]),
    getStoreAddress(addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },
    callPhone() {
      // Send connection message
      ReactNativeWebView.postMessage(
        JSON.stringify({ command: "CALL", data: `+1-${this.pharmacy.phone}` })
      );
    },
    selectPharmacy(data) {
      this.pharmacy = JSON.parse(data);
      this.isSearchInProgress = false;
    },
    async saveProfileDetails() {
      try {
        if (!this.pharmacy || !this.pharmacy.pharmacyName) {
          Swal.fire("Select Pharmacy", "Please select the pharmacy", "error");
          return;
        }

        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          pharmacy: this.pharmacy,
        });

        Swal.fire("Pharmacy Updated!", "", "success");

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response.data.message, "error");
        console.error(e);
      }
    },
  },
};
</script>
