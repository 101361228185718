<template>
  <!-- Home Banner -->
  <section
    class="section section-search"
    v-if="currentpath == 'index' || currentpath == 'index-page'"
  >
    <div class="container-fluid">
      <div class="banner-wrapper">
        <div class="banner-header text-center">
          <h1>
            Instant Online Prescription Refills by Experienced Virtual Doctors
          </h1>
          <p>
            Discover the best doctors, ready to help you with your prescription
            needs.
          </p>
        </div>

        <hr />
        <!-- Search -->
        <div class="search-box text-center mt-3">
          <div class="form-group search-info mb-2">
            <button
              @click="$emit('goTo', 'patient')"
              class="btn btn-info btn-block"
            >
              I'm a Patient. Let's Get Started!
            </button>
          </div>
          <div class="form-group search-location-home">
            <router-link to="/provider/register">
              <button class="btn btn-primary btn-block mb-2">
                Create Provider Account
              </button>
            </router-link>
          </div>
        </div>
        <!-- /Search -->
      </div>
    </div>
  </section>
  <!-- /Home Banner -->
</template>

<script>
export default {
  computed: {
    currentpath() {
      return this.$route.name;
    },
  },
};
</script>
