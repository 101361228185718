<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="mb-3">Residing Address</h2>
                      <h4 class="mb-3">Please complete your profile</h4>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done">1</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done">2</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-3" class="active-done">3</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-4" class="active-done">4</a>
                        </li>
                        <li>
                          <a href="/app/patient/step-5" class="active-done">5</a>
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">6</a>
                        </li>
                        <li><a href="javascript:void();">7</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="form-group">
                        <div class="form-group text-left">
                          <label>Address Line 1</label>
                          <input type="text" name="address1" class="form-control" v-model="addressLine1" maxlength="34"
                            required />
                        </div>
                        <div class="form-group text-left">
                          <label>Address Line 2 (Optional)</label>
                          <input type="text" name="address2" class="form-control" v-model="addressLine2"
                            maxlength="34" />
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>State</label>
                              <select class="form-control select" tabindex="-1" aria-hidden="true" v-model="state"
                                required @change="fetchCities()">
                                <option v-for="(state, i) in statesData" :key="i" :value="state.state_code">
                                  {{ state.state_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <label>City</label>
                            <select class="form-control select" tabindex="-1" aria-hidden="true" v-model="city"
                              required>
                              <option v-for="(city, i) in citiesData" :key="i" :value="city.city_name">
                                {{ city.city_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Zip Code</label>
                          <input type="text" name="zipcode" class="form-control" v-model="zipCode" @keyup="findAddress"
                            required />
                        </div>
                      </div>
                      <!-- <div class="mt-3">
                        <button
                          type="submit"
                          class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                        >Continue</button>
                      </div> -->
                      <div class="mt-3">
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <button type="submit" class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              ">
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/patient/step-5">
                              <a href="javascript:void()" class="
                                  btn
                                  bg-warning-light
                                  btn-block btn-sm
                                  login-btn
                                ">
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright"></div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";
import Swal from "sweetalert2";
export default {
  mixins: [common],
  data() {
    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      citiesData: [],
    };
  },
  async mounted() {
    this.showLoader(true);
    this.statesData = await this.getStateList();
    this.addressLine1 = this.$store.getters.user.address1;
    this.addressLine2 = this.$store.getters.user.address2;
    this.zipCode = this.$store.getters.user.zip_code;

    this.state = this.$store.getters.user.state;
    if (this.state !== "") await this.fetchCities(this.state);

    this.city = this.$store.getters.user.city;
    this.showLoader(false);
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-5");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    async fetchCities() {
      this.showLoader(true);
      this.citiesData = await this.getCityList(this.state);
      this.showLoader(false);
    },

    async findAddress(e) {
      if (this.zipCode.length >= 5 && this.zipCode.length <= 6) {
        const address = await this.getAddressByPin(this.zipCode);
        // Set State
        for (let each of address) {
          const st = this.statesData.find(
            (e) =>
              String(e.name).toLowerCase() ===
              String(each.long_name).toLowerCase()
          );

          if (st) {
            this.state = st.iso2;
            await this.fetchCities();
          }
        }

        // Set City
        for (let each of address) {
          if (this.state !== "") {
            if (this.citiesData.length === 0) await this.fetchCities();
            const ct = this.citiesData.find(
              (e) =>
                String(e.name).toLowerCase() ===
                String(each.long_name).toLowerCase()
            );

            if (ct) {
              this.city = ct.name;
            }
          }
        }
      }
    },

    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          address1: this.addressLine1,
          address2: this.addressLine2,
          city: this.city,
          state: this.state,
          zip_code: this.zipCode,
          current_registration_step: 7,
        });

        this.$router.push("/patient/step-7");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },
  },
};
</script>
