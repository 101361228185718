<template>
  <div class="main-wrapper">
    <!-- Home Banner -->
    <section class="section section-search" style="height: 100vh">
      <div class="container-fluid">
        <div class="banner-wrapper text-center">
          <div class="banner-header text-center">
            <h1>COMING SOON!</h1>
            <p class="mb-3">
              We'll notify you once we are ready with the feature.
            </p>

            <p class="mb-5">
              Thank you for your patience and sorry for the inconvenience.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- /Home Banner -->
  </div>
</template>

<script>
</script>