<template>
  <div>
    <div class="main-wrapper main-wrapper-adjust-refill">
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <ul
                class="
                  nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified
                "
                style="font-size: 10px"
              >
                <li id="pharmacy-li" class="nav-item">
                  <a
                    id="pharmacy-tab"
                    class="nav-link active"
                    href="#pharmacy-section"
                    data-toggle="tab"
                    >{{ $t("requestForRefill.step1") }}</a
                  >
                </li>
                <li id="medicine-li" class="nav-item disabled">
                  <a
                    id="medicine-tab"
                    class="nav-link"
                    href="#medicine-section"
                    data-toggle="tab"
                    >{{ $t("requestForRefill.step2") }}</a
                  >
                </li>
                <li id="dosage-li" class="nav-item disabled">
                  <a
                    id="dosage-tab"
                    class="nav-link"
                    href="#dosage-section"
                    data-toggle="tab"
                    >{{ $t("requestForRefill.step3") }}</a
                  >
                </li>
                <li id="patient-li" class="nav-item disabled">
                  <a
                    id="patient-tab"
                    class="nav-link"
                    href="#patient-section"
                    data-toggle="tab"
                    >{{ $t("requestForRefill.step4") }}</a
                  >
                </li>
                <li id="review-li" class="nav-item disabled">
                  <a
                    id="review-tab"
                    class="nav-link"
                    href="#review-section"
                    data-toggle="tab"
                    >{{ $t("requestForRefill.step5") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane show active" id="pharmacy-section">
                  <ChoosePharmacy @select="selectPharmacy" />
                </div>
                <div class="tab-pane" id="medicine-section">
                  <ChooseMedicines @select="selectMedicines" />
                </div>
                <div class="tab-pane" id="dosage-section">
                  <DosageInformation
                    :selectedMedicines="selectedMedicines"
                    :units="units"
                    @select="confirmDosageInfo"
                  />
                </div>
                <div class="tab-pane" id="patient-section">
                  <PatientInformation @select="confirmPatientInfo" />
                </div>
                <div class="tab-pane" id="review-section">
                  <ReviewConfirm
                    :pharmacyDetails="selectedPharmacy"
                    :medicineDetails="selectedMedicines"
                    :patientInfo="patientInformation"
                    :dosageInfo="dosageInformation"
                    :allowSubstitutions="allowSubstitutions"
                    @select="submitRequest"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- <layout-footer></layout-footer> -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ChoosePharmacy from "./ChoosePharmacy.vue";
import ChooseMedicines from "./ChooseMedicines.vue";
import PatientInformation from "./PatientInformation.vue";
import DosageInformation from "./DosageInformation.vue";
import ReviewConfirm from "./ReviewConfirm.vue";
import RequestSuccess from "./RequestSuccess.vue";
import config, { HTTP_API } from "../../constants/config";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    ChoosePharmacy: ChoosePharmacy,
    ChooseMedicines: ChooseMedicines,
    PatientInformation: PatientInformation,
    DosageInformation: DosageInformation,
    ReviewConfirm: ReviewConfirm,
    RequestSuccess: RequestSuccess,
  },
  data() {
    return {
      selectedPharmacy: {},
      selectedMedicines: [],
      patientInformation: {},
      dosageInformation: {},
      allowSubstitutions: false,
      units: [],
    };
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    selectPharmacy(store) {
      this.selectedPharmacy = JSON.parse(store);
      $("#medicine-tab").click();
      // $("#pharmacy-li").addClass("disabled");
      $("#medicine-li").removeClass("disabled");
    },
    selectMedicines(medicines) {
      this.selectedMedicines = JSON.parse(medicines);
      $("#dosage-tab").click();
      // $("#medicine-li").addClass("disabled");
      $("#dosage-li").removeClass("disabled");
    },
    confirmDosageInfo(dosageInfo) {
      if (dosageInfo && String(dosageInfo).includes("selectedMedicines")) {
        this.dosageInformation = JSON.parse(dosageInfo);
        this.selectedMedicines = this.dosageInformation.selectedMedicines;
        this.allowSubstitutions = this.dosageInformation.allowSubstitutions;
        delete this.dosageInformation.selectedMedicines;
        delete this.dosageInformation.allowSubstitutions;
        $("#patient-tab").click();
        // $("#dosage-li").addClass("disabled");
        $("#patient-li").removeClass("disabled");
      }
    },
    confirmPatientInfo(patientInfo) {
      this.patientInformation = JSON.parse(patientInfo);
      $("#review-tab").click();
      // $("#patient-li").addClass("disabled");
      $("#review-li").removeClass("disabled");
    },
    async submitRequest(details) {
      try {
        this.showLoader(true);
        let payload = JSON.parse(details);

        const res = await axios.post(
          config.BASE_URL + HTTP_API.patientCreatePrescription,
          payload
        );

        Swal.fire({
          icon: "success",
          title: `Refill Request Created!`,
          text: `Redirecting to request payments...`,
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          this.$router.push(`/patient/billing/${res.data.prescriptionNumber}`);
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
        this.showAlert({
          message:
            "Failed to request a new prescription. Please try again later.",
          type: "danger",
          time: 4000,
        });
      }
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
  padding: 40px 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}

.main-wrapper-adjust-refill {
  width: 100%;
  max-height: 82vh;
  overflow: auto;
}
</style>
