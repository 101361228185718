<template>
  <div>
    <div class="p-2">
      <div class="row">
        <div class="col-10">
          <h4 class="mt-2">Request ID: {{ prescriptionDetails.id }}</h4>
        </div>
        <div class="col-2">
          <a
            href="javascript:void()"
            @click="$emit('close')"
            class="btn btn-sm bg-info-light btn-block"
          >
            <i class="fa fa-close"></i>
          </a>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h5>RxChange Request ID: {{ selectedRxChangeId }}</h5>
        </div>
        <div class="col-12">
          <h6>
            Are you sure you want to deny the RxChange request from the
            pharmacy?
          </h6>
          <hr />

          <div class="form-group text-left">
            <label>Reasons for cancellation</label>
            <select class="form-control select" v-model="rejectionReason">
              <option
                v-for="(reason, i) in reasons"
                :key="i"
                :value="reason.value"
              >
                {{ reason.text }}
              </option>
            </select>
          </div>

          <hr />

          <h6>Important Notes</h6>
          <ul>
            <li>
              <small
                >Cancelling pharmacy RxChange request will result in permanent
                cancellation. It cannot be reversed.</small
              >
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <button
            @click="rejectSubmit"
            class="btn bg-danger-light mr-2 btn-block"
          >
            <i class="fas fa-times mr-2"></i> Confirm Cancellation
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "../../mixins/common";

export default {
  props: {
    prescriptionDetails: Object,
    selectedRxChangeId: String,
  },
  data() {
    return {
      reasons: [
        { value: "DeniedChangeInappropriate", text: "Change Inappropriate" },
        { value: "DeniedNoPriorAuthAttempt", text: "No Prior Auth Attempt" },
        { value: "DeniedNewRx", text: "Denied New RX" },
        { value: "DeniedPatientUnknown", text: "Patient Unknown" },
        { value: "DeniedPatientNotUnderCare", text: "Patient Not Under Care" },
        {
          value: "DeniedPatientNoLongerUnderPatientCare",
          text: "Patient No Longer Under Care",
        },
        { value: "DeniedNeverPrescribed", text: "Never Prescribed" },
        { value: "DeniedNeedAppointment", text: "Need Appointment" },
      ],
      rejectionReason: "DeniedChangeInappropriate",
    };
  },
  methods: {
    rejectSubmit() {
      this.$emit(
        "submit",
        JSON.stringify({
          rxChangeId: this.selectedRxChangeId,
          reason: this.rejectionReason,
        })
      );
    },
  },
  mixins: [commonMixin],
};
</script>