<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="mb-3">Personal Information</h2>
                      <h4 class="mb-3">Please complete your profile</h4>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">2</a>
                        </li>
                        <li><a href="javascript:void();">3</a></li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="select-gender-col text-center">
                        <label>Select Gender</label>
                        <div class="row">
                          <div class="col-6 pr-2">
                            <input
                              type="radio"
                              name="gender"
                              value="Male"
                              v-model="gender"
                              id="test1"
                            />
                            <label
                              for="test1"
                              @click="
                                () => {
                                  preferNotToAnswer = false;
                                }
                              "
                            >
                              <span class="gender-icon"
                                ><img
                                  src="../../assets/img/icons/male.png"
                                  alt=""
                              /></span>

                              <span>Male</span>
                            </label>
                          </div>
                          <div class="col-6 pl-2">
                            <input
                              type="radio"
                              v-model="gender"
                              name="gender"
                              value="Female"
                              id="test2"
                            />
                            <label
                              for="test2"
                              @click="
                                () => {
                                  preferNotToAnswer = false;
                                }
                              "
                            >
                              <span class="gender-icon"
                                ><img
                                  src="../../assets/img/icons/female.png"
                                  alt=""
                              /></span>

                              <span>Female</span>
                            </label>
                          </div>
                          <hr />
                          <div class="col-12 pl-2">
                            <div class="checklist-col pregnant-col">
                              <div class="remember-me-col d-flex">
                                <span class="text-left ml-3"
                                  >Prefer not to answer</span
                                >
                                <label class="custom_check">
                                  <input
                                    v-model="preferNotToAnswer"
                                    type="checkbox"
                                    @click="
                                      () => {
                                        gender = '';
                                      }
                                    "
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-12">
                            <button
                              type="submit"
                              class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              "
                            >
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/patient/step-1">
                              <a
                                href="javascript:void()"
                                class="
                                  btn
                                  bg-warning-light
                                  btn-block btn-sm
                                  login-btn
                                "
                              >
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright"></div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      gender: "Male",
      preferNotToAnswer: false,
    };
  },
  mounted() {
    // Set data if exists
    this.gender = this.$store.getters.user.gender;
    if (this.gender == "Other") this.preferNotToAnswer = true;
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-1");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          gender: this.preferNotToAnswer ? "Other" : this.gender,
          current_registration_step: 3,
        });

        this.$router.push("/patient/step-3");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },
  },
};
</script>
